import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import GeneralInformation from 'components/ProviderDashboard/Helpers/Information/GeneralInformation.js';
import Photos from 'components/ProviderDashboard/Helpers/Information/Photos.js';
import StaffInformation from 'components/ProviderDashboard/Helpers/Information/StaffInformation.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5em auto;
  width: 85%; // Container takes up 90% of its parent width
  max-width: 85em;
  box-shadow: none; // No shadow for the container
`;

const TabHeader = styled.div`
  display: flex;
  align-items: flex-end;
  width: 98.5%;
`;

const Tabs = styled.div`
  display: flex;
  margin-left: auto;
  margin-bottom: 0.16em
`;

const TabButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 1.35em;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: ${props => props.selected ? '#00C6B6' : '#696969'};
  border-radius: 10px 10px 0 0;
  transition: all 0.3s ease;
  position: relative;
  margin-right: -1px;

  // Thin, crisp shadow for a subtle 3D effect
  box-shadow: ${props => !props.selected ? '0 -3px 6px rgba(0, 0, 0, 0.1), -3px 0 6px rgba(0, 0, 0, 0.1), 3px 0 6px rgba(0, 0, 0, 0.1)' : 'none'};

  &:hover {
    color: #00C6B6;
    background-color: rgba(255, 255, 255, 0.8);
  }

  ${props => props.selected && css`
    z-index: 2;
    // Maintain the same subtle shadow as non-selected tabs
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1), -3px 0 6px rgba(0, 0, 0, 0.1), 3px 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 4px;
      background-color: #00C6B6;
    }
  `}

  // Less pronounced effect for non-selected tabs to create layering
  ${props => !props.selected && css`
    transform: translateY(1px);
    z-index: 1;
    opacity: 0.85;
  `}
`;

const MainContent = styled.div`
  padding: 0;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
  width: 97%;
  height: 95%;
  margin: 0 auto;
  overflow: auto;
`;



export default function InformationPage() {
  const [selectedType, setSelectedType] = useState('General Information');

  const renderContent = () => {
    switch (selectedType) {
      case 'General Information':
        return <GeneralInformation />;
      case 'Photos':
        return <Photos />;
      case 'Staff Information':
        return <StaffInformation />;
      default:
        return <div>Please select a category</div>;
    }
  };

  return (
    <Container>
      <TabHeader>
        <Tabs>
          <TabButton 
            onClick={() => setSelectedType('General Information')}
            selected={selectedType === 'General Information'}
          >
            Clinic Information
          </TabButton>
          <TabButton 
            onClick={() => setSelectedType('Photos')}
            selected={selectedType === 'Photos'}
          >
            Photos
          </TabButton>
          <TabButton 
            onClick={() => setSelectedType('Staff Information')}
            selected={selectedType === 'Staff Information'}
          >
            Staff Information
          </TabButton>
        </Tabs>
      </TabHeader>
      <MainContent>
        {renderContent()}
      </MainContent>
    </Container>
  );
}
