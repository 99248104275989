import React, { useState } from 'react';
import styled from 'styled-components';


// Styled components definitions
const Container = styled.div`
  padding: 1em;
`;

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0;
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 300;
  font-size: 1.3em;
  margin-bottom: 0.3em;
  margin-top: 30px;
`;

const StyledInput = styled.input`
  font-size: 1.4em;
  padding: 0.5em 0.5em;
  width: 30%;
  margin-top: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.3em;
  background-color: #00C6B6;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #009a94;
  }
`;

const theme = {
  turquoiseColor: '#AFEEEE',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333', // Darker color for actual input text
  placeholderColor: '#606060', // Medium grey for placeholder text
  lightGrey: '#ccc', // Light grey for borders
  darkGrey: '#808080', // Darker grey for focused borders
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '0.8em',
    padding: '0.1em 0.2em', // Adjust padding to closely match CustomInput
    borderColor: theme.lightGrey,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    // This ensures the height matches the CustomInput if needed
    minHeight: '1.5em',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.8em',
    backgroundColor: state.isFocused ? theme.hoverTurquoiseColor : 'white',
    color: state.isFocused ? theme.fontColor : theme.placeholderColor,
    '&:hover': { backgroundColor: theme.turquoiseColor },
  }),
};


const EditModalContent = ({ procedure, onConfirm, type, setType, typeOptions, setTypeOptions }) => {
  const [price, setPrice] = useState(procedure.price.toString());

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
  };

  const handleConfirm = () => {
    const updatedPrice = parseFloat(price);
    if (!isNaN(updatedPrice)) {
      onConfirm({ ...procedure, price: updatedPrice });
    } else {
      alert("Please enter a valid price.");
    }
  };

  return (
    <Container>
      <Title>Edit {procedure.name}</Title>
      <StyledLabel htmlFor="priceInput">Price (USD)</StyledLabel>
      <StyledInput
        id="priceInput"
        type="text"
        value={price}
        onChange={handlePriceChange}
        placeholder="Enter price in USD"
      />
      <ButtonContainer>
        <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
      </ButtonContainer>
    </Container>
  );
};

export default EditModalContent;
