import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for routing

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: #FFFFFF; /* White background for a clean look */
`;

const Title = styled.h1`
  font-size: 4rem;
  color: #333; /* Dark grey color for the text */
  margin-bottom: -20px;
`;

const Subtitle = styled.p`
  font-size: 2.2rem;
  color: #555; /* Medium grey for the subtitle */
  margin-bottom: 30px; /* Adjusted margin */
`;

// Keyframes for the dot animation, including translation and rotation
const move = keyframes`
  0%, 100% {
    transform: translateX(0) rotate(0deg);
    opacity: 0.1; /* Dots start faint */
    font-size: 20px; /* Dots start larger */
  }
  50% {
    transform: translateX(-100px) rotate(360deg); /* Dots move towards the center and rotate */
    opacity: 1; /* Dots are fully visible in the center */
    font-size: 40px; /* Dots are largest in the center */
  }
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dot = styled.div`
  width: 40px;  /* Increased size of the dots */
  height: 40px; /* Increased size of the dots */
  border-radius: 50%;
  background-color: #DDD; /* Light grey color for the dots */
  margin: 0 6px; /* Increased spacing between dots */
  animation: ${move} 3s ease-in-out infinite;

  /* Each dot has a slightly different animation delay to create a smooth wave effect */
  &:nth-child(1) {
    animation-delay: -0.4s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.2s;
  }
  &:nth-child(4) {
    animation-delay: -0.1s;
  }
  &:nth-child(5) {
    animation-delay: 0s;
  }
`;

const HomeLink = styled(Link)`
  font-size: 1.8rem;
  color: #00C6B6; /* Slightly darker grey for the link */
  text-decoration: none;
  margin-top: 2em; /* Added some space above the link */

  &:hover {
    text-decoration: underline;
  }
`;

const NotFound = () => (
  <Container>
    <Title>Oops!</Title>
    <Subtitle>The page you are looking for is not accessible or does not exist.</Subtitle>
    <Dots>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Dots>
    <HomeLink to="/">Go Back to Home</HomeLink>
  </Container>
);

export default NotFound;
