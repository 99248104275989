import React, { useState} from 'react';
import Sidebar from './Sidebar';

import RequestsPage from 'components/ProviderDashboard/ContentPages/RequestsPage.js';
import AppointmentsPage from 'components/ProviderDashboard/ContentPages/AppointmentsPage.js';
import ProceduresPage from 'components/ProviderDashboard/ContentPages/ProceduresPage.js';
import ReviewsPage from 'components/ProviderDashboard/ContentPages/ReviewsPage.js';
import InformationPage from 'components/ProviderDashboard/ContentPages/InformationPage.js';

import 'styles/ProviderDashboard/ProviderDashboard.css';

const ProviderDashboard = () => {
    const [activeItem, setActiveItem] = useState('Information');
    
    return (
        <div className="dashboardContainer">
            <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} />
            {activeItem === 'Requests' && <RequestsPage />}
            {activeItem === 'Appointments' && <AppointmentsPage />}
            {activeItem === 'Procedures' && <ProceduresPage />}
            {activeItem === 'Reviews' && <ReviewsPage />}
            {activeItem === 'Information' && <InformationPage />}
        </div>
    );
}

export default ProviderDashboard;