import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import CancelModal from './CancelModal';
import Select from 'react-select';

const theme = {
  turquoiseColor: '#AFEEEE',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333', // Darker color for actual input text
  placeholderColor: '#606060', // Medium grey for placeholder text
  lightGrey: '#ccc', // Light grey for borders
  darkGrey: '#808080', // Darker grey for focused borders
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '0.8em',
    padding: '0.1em 0.2em', // Adjust padding to closely match CustomInput
    borderColor: theme.lightGrey,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    // This ensures the height matches the CustomInput if needed
    minHeight: '1.5em',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.8em',
    backgroundColor: state.isFocused ? theme.hoverTurquoiseColor : 'white',
    color: state.isFocused ? theme.fontColor : theme.placeholderColor,
    '&:hover': { backgroundColor: theme.turquoiseColor },
  }),
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(220,220,220,0.70);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  font-size: 2em;
  color: #ccc;
  cursor: pointer;

  &:hover {
    color: darkgrey;
  }
`;

const ModalContent = styled.div`
  position: relative;
  background: white;
  border-radius: 2em;

  max-width: 70em;
  padding: 3em;
  height: 35em;
  width: 60em;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  box-shadow: 0 12px 15px rgba(0,0,0,0.1);
  overflow: auto;
`;

const StyledLabel = styled.label`
  font-size: 0.8em;  /* Adjust the value to your preference */
  font-weight: 300;
  margin-top: 0.8em;
  margin-bottom: 15px;  /* Adds some space below the label */
  display: block;  /* Ensures the label takes the full width */
`;

const Title = styled.p`
  font-weight: 300;
  font-size: 0.7em;
  margin-bottom: 2px;
`;

const Value = styled.p`
  font-weight: 400;
  font-size: 0.9em;
  margin-top: 2px;
  margin-bottom: 1em;

  &.Completed { 
    color: #1CA81B; 
  }
  &.Scheduled { 
    color: #FFB800; 
  }
  &.Cancelled { 
    color: #FF5C5C; 
  }
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5em;
`;

const InfoWrapper = styled.div`
  width: 50%;
`;

const ActionWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const StarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;


const Star = styled.span`
  font-size: 1.8em;
  color: ${props => props.isFilled ? '#FFD700' : '#ddd'};
  margin-right: 0.15em;
  &:hover,
  &:hover ~ & {
    color: #FFD700;
  }
`;

const FeedbackForm = styled.form`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

const CommentsTextarea = styled.textarea`
  padding: 0.5em;
  height: 6em;
  width: 100%;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-length: 500;
  resize: vertical;
  font-family: 'Poppins', sans-serif;
`;

// Styled component for the Submit button
const SubmitButton = styled(Button)`
  && {
    padding: 12px 30px; // Larger padding for a bigger button
    font-size: 0.8em; // Larger font size for visibility
    background-color: #00C6B6; // Turquoise color as requested

    width: 40%;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s; // Smooth transition for hover effect
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth

    &:hover {
      background-color: #008C8D; // Darker shade of turquoise for hover
    }

    &:disabled {
      background-color: #cccccc; // Consistent disabled state with your project
      color: #6c757d;
    }
  }
`;


const CancelAppointmentLink = styled.a`
  font-size: 0.82em; // Smaller font size for a subtler link
  font-weight: 400; // Lighter font weight for a subtler link
  color: #dc3545; // Red text color
  cursor: pointer;
  transition: color 0.2s, text-decoration 0.2s, transform 0.2s; // Smooth transition for hover effects
  text-decoration: none; // No underline
  margin-top: 0.9em; // Space below the submit button

  &:hover {
    color: #c82333; // Darker shade of red on hover
    text-decoration: underline; // Underline on hover for emphasis
  }
`;

const CharactersRemaining = styled.p`
  font-size: 0.8em;
  color: ${props => props.negative ? 'red' : 'grey'};
  margin-top: 0.3em;
  margin-bottom: 3em;
`;

const AppointmentModal = ({ isOpen, onRequestClose, appointment, onUpdateStatus }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [complication, setComplication] = useState('');
  const [comments, setComments] = useState('');
  const [charactersRemaining, setCharactersRemaining] = useState(2000);
  const contentRef = useRef(null);

  const complicationOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];
  

  useEffect(() => {
    if (!isOpen) {
      resetModalState();
    }
  }, [isOpen]);

  const resetModalState = () => {
    setRating(0);
    setComplication('');
    setComments('');
    setCharactersRemaining(2000);
  };

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
    setCharactersRemaining(2000 - e.target.value.length);
  };

  const handleSubmitFeedback = (e) => {
    e.preventDefault();
    console.log({ rating, complication, comments });
    onRequestClose();
  };

  const handleCancelConfirmation = (reason) => {
    if (appointment) {
      onUpdateStatus(appointment.confirmationNumber, 'Cancelled', reason);
    }
    setShowCancelModal(false);
    onRequestClose();
  };

  const StarRating = ({ maxStars = 5 }) => {
    const [hoverIndex, setHoverIndex] = useState(-1);
  
    const handleStarClick = (index) => {
      setRating(index + 1);
    };
  
    const handleStarHover = (index) => {
      setHoverIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoverIndex(-1);
    };
  
    return (
      <StarWrapper onMouseLeave={handleMouseLeave}>
        {Array(maxStars).fill(0).map((_, index) => (
          <Star 
            key={index}
            isFilled={index <= (hoverIndex !== -1 ? hoverIndex : rating - 1)}
            onMouseOver={() => handleStarHover(index)}
            onClick={() => handleStarClick(index)}
          >
            ★
          </Star>
        ))}
      </StarWrapper>
    );
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
    <ModalOverlay onClick={onRequestClose}>
      <ModalContent ref={contentRef} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onRequestClose}>X</CloseButton>
        <ContentWrapper>
          <InfoWrapper>
            <Title>Reference Number:</Title>
            <Value>{appointment?.confirmationNumber}</Value>
            <Title>Status:</Title>
            <Value className={appointment?.status}>{appointment?.status}</Value>
            {/* Display cancellation reason if appointment is cancelled */}
            {appointment?.status === 'Cancelled' && (
              <div>
                <Title>Cancellation Reason:</Title>
                <Value>{appointment.cancellationReason || 'N/A'}</Value>
              </div>
            )}
            <Title>Patient Name:</Title>
            <Value>{appointment?.name}</Value>
            <Title>Procedure:</Title>
            <Value>{appointment?.procedure}</Value>
            <Title>Date:</Title>
            <Value>{new Date(appointment?.date).toDateString()}{' '}{appointment?.time}</Value>
          </InfoWrapper>
          <ActionWrapper>
            {appointment?.status === 'Scheduled' && (
              <>
                <FeedbackForm onSubmit={handleSubmitFeedback}>
                  <StyledLabel>How well did the service go?</StyledLabel>
                  <StarRating />
                  <StyledLabel>Were there any complications?</StyledLabel>
                  <Select
                    styles={customSelectStyles}
                    value={complicationOptions.find(option => option.value === complication)}
                    onChange={(selectedOption) => setComplication(selectedOption.value)}
                    options={[
                      { value: 'Yes', label: 'Yes' },
                      { value: 'No', label: 'No' }
                    ]}
                    placeholder="Select an option"
                  />
                  <StyledLabel>Comments:</StyledLabel>
                  <CommentsTextarea
                    value={comments}
                    onChange={handleCommentsChange}
                  />
                  <CharactersRemaining negative={charactersRemaining < 0}>
                    Characters remaining: {charactersRemaining}
                  </CharactersRemaining>
                  <SubmitButton type="submit" disabled={charactersRemaining < 0}>
                    Submit
                  </SubmitButton>
                  <CancelAppointmentLink onClick={() => setShowCancelModal(true)}>
                    Cancel Appointment
                  </CancelAppointmentLink>
                </FeedbackForm>
              </>
            )}
          </ActionWrapper>
        </ContentWrapper>
      </ModalContent>
    </ModalOverlay>

    {showCancelModal && (
      <CancelModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={handleCancelConfirmation}
      />
    )}
  </>
  );
};

export default AppointmentModal;