import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end; // Aligns children (e.g., button) to the right
`;

const Title = styled.h2`
  font-size: 1.8em;
  font-weight: 400;
  margin-top: 0;
  align-self: flex-start; // Aligns the title to the start of the flex container
  margin-bottom: 30px;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.4em;
  background-color: #FF6347; // Tomato Red
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 40px;

  &:hover {
    background-color: #FF4500; // Darker Red for hover
  }
`;

// RemoveModalContent component
const RemoveModalContent = ({ procedure, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm({ id: procedure.id });
  };

  return (
    <Container>
      <Title>Remove {procedure.name}?</Title>
      <ConfirmButton onClick={handleConfirm}>
        Confirm Removal
      </ConfirmButton>
    </Container>
  );
};

export default RemoveModalContent;
