import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Pagination from 'components/DemoDashboard/Helpers/AppointmentsTable/Pagination.js';
import AppointmentModal from 'components/DemoDashboard/Helpers/AppointmentsTable/AppointmentModal';
import { generateAppointments } from 'components/DemoDashboard/Helpers/AppointmentsTable/GenerateAppointments.js';
import SearchAndFilter from 'components/DemoDashboard/Helpers/AppointmentsTable/SearchAndFilter.js';
import Table from 'components/DemoDashboard/Helpers/AppointmentsTable/Table.js';

const PageWrapper = styled.div`
  display: flex;
  padding: 1% 2% 2% 1.5%;
  width: 85%;
`;

const Title = styled.h1`
  font-weight: 300;
  margin-top: 2.5em 0em;
  font-size: 1.5em;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const Sidebar = styled.div`
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 15vw; // Fixed width for sidebar
  flex-shrink: 0; // Prevents shrinking
`;

const MainContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const FilterSection = styled.div`
  width: 100%;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const AppointmentTypes = styled.div`
  margin-bottom: 20px 0px;
  width: 100%;
`;

const TypeButton = styled.button`
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  width: 100%;
  text-align: left;
  padding: 1em 0.6em;
  font-size: 1.1em; 
  margin-bottom: 0px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  
  background-color: ${props => props.selected ? '#c7fffa' : 'transparent'}; // Lighter turquoise for selected
  color: ${props => props.selected ? '#007d73' : '#7E7E7E'}; // Darker turquoise for selected text, medium grey otherwise
  
  &:hover {
    background-color: ${props => !props.selected ? '#ededed' : null}; // Light grey for hover on non-selected
  }

  &:after {
    content: ${props => props.selected ? '"▶"' : 'none'};
    display: inline-block;
    margin-left: 20px;
    text-align: right;
    flex: 1;
  }
`;


export default function AppointmentsPage() {
  const n = 126;
  const [selectedType, setSelectedType] = useState('All Appointments');
  const [allAppointments, setAllAppointments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const rowsPerPage = 10;
  const [statusFilter, setStatusFilter] = useState('');
  const [procedureFilter, setProcedureFilter] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setAllAppointments(generateAppointments(n));
  }, []);

  const clearFilters = () => {
    setSearchTerm('');
    setStatusFilter('');
    setProcedureFilter('');
    setStartDate('');
    setEndDate('');
  };

  function convertTo24Hour(time) {
    const [hour, minute] = time.split(":");
    let h = parseInt(hour, 10);
    const isPM = time.match(/PM$/i);

    // Adjust hours if PM
    if (isPM && h < 12) h += 12;
    if (!isPM && h === 12) h = 0;

    // Convert to a number for easy comparison
    return h * 100 + parseInt(minute, 10);
  }

  useEffect(() => {
    const results = allAppointments.filter(appointment => {
      

      const today = new Date();
      const appointmentDate = new Date(appointment.date);
      
      // Filtering based on selectedType
      const isToday = today.toDateString() === appointmentDate.toDateString();
      const isFuture = appointmentDate > today;
      const isPast = appointmentDate < today;
      let matchesSelectedType = true;

      switch (selectedType) {
        case "Today's Appointments":
          matchesSelectedType = isToday;
          break;
        case 'Upcoming Appointments':
          matchesSelectedType = (isToday || isFuture) && appointment.status !== 'Cancelled' && appointment.status !== 'Completed';
          break;
        case 'Past Appointments':
          matchesSelectedType = isPast || appointment.status === 'Completed';
          break;
        default:
          matchesSelectedType = true; // All Appointments
      }
      
      const matchesSearchTerm = 
        appointment.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        appointment.confirmationNumber.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus = !statusFilter || appointment.status === statusFilter;
      const matchesProcedure = !procedureFilter || appointment.procedure === procedureFilter;
      const matchesDateRange = (!startDate && !endDate) ||
        (new Date(appointment.date) >= new Date(startDate) && new Date(appointment.date) <= new Date(endDate));

      return matchesSelectedType && matchesSearchTerm && matchesStatus && matchesProcedure && matchesDateRange;
    });

    results.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
  
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
  
      // If the dates are the same, compare the time using our helper function
      const timeA = convertTo24Hour(a.time);
      const timeB = convertTo24Hour(b.time);
  
      return timeA - timeB;
    });
    setFilteredAppointments(results);
    setCurrentPage(1);
  }, [searchTerm, allAppointments, statusFilter, procedureFilter, startDate, endDate, selectedType]); // Added selectedType as a dependency


  function formatTime(time) {
    const [hour, minute] = time.split(":");
    let newHour = parseInt(hour, 10);
    const ampm = newHour >= 12 ? "PM" : "AM";

    if (newHour === 0) newHour = 12;
    if (newHour > 12) newHour -= 12;

    return `${newHour}:${minute} ${ampm}`;
  }

  function formatDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const appointments = filteredAppointments.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const updateAppointmentStatus = (appointmentId, status, cancellationReason) => {
    setAllAppointments(allAppointments.map(appointment => {
      if (appointment.confirmationNumber === appointmentId) {
        return { ...appointment, status, cancellationReason };
      }
      return appointment;
    }));
  };
  

  return (
    <PageWrapper>
      <Sidebar>
        <Title>Appointments</Title>
        <AppointmentTypes>
          <TypeButton 
            onClick={() => setSelectedType('All Appointments')} 
            selected={selectedType === 'All Appointments'}
          >
            All Appointments
          </TypeButton>
          <TypeButton 
            onClick={() => setSelectedType("Today's Appointments")} 
            selected={selectedType === "Today's Appointments"}
          >
            Today's Appointments
          </TypeButton>
          <TypeButton 
            onClick={() => setSelectedType('Upcoming Appointments')} 
            selected={selectedType === 'Upcoming Appointments'}
          >
            Upcoming Appointments
          </TypeButton>
          <TypeButton 
            onClick={() => setSelectedType('Past Appointments')} 
            selected={selectedType === 'Past Appointments'}
          >
            Past Appointments
          </TypeButton>
        </AppointmentTypes>
    
        <FilterSection>
          <SearchAndFilter 
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            procedureFilter={procedureFilter}
            setProcedureFilter={setProcedureFilter}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            clearFilters={clearFilters} 
          />
        </FilterSection>
      </Sidebar>
      <MainContent>
        <Table 
          appointments={appointments}
          setIsModalOpen={setIsModalOpen}
          setSelectedAppointment={setSelectedAppointment}
          searchTerm={searchTerm}
        />
        <Pagination 
          totalPages={Math.ceil(filteredAppointments.length / rowsPerPage)} 
          currentPage={currentPage} 
          onPageChange={setCurrentPage} 
        />
        <AppointmentModal 
          isOpen={isModalOpen}
          onRequestClose={() => {
              setIsModalOpen(false);
              setSelectedAppointment(null);
          }}
          appointment={selectedAppointment}
          onUpdateStatus={updateAppointmentStatus}
        />
      </MainContent>
    </PageWrapper>
  );
}
