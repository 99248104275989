import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage'; // Ensure this function is correctly implemented

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const CropperModal = styled.div`
  display: ${props => props.imageSrc ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000; // Ensure this is above all other content
  align-items: center; // Centers vertically in the viewport
  justify-content: center; // Centers horizontally in the viewport
  flex-direction: column; // Stack the cropper and button vertically
`;

// CropperContainer should only contain the cropper and the overlay
const CropperContainer = styled.div`
  position: relative;
  width: 90vw;
  height: 60vh;
  max-width: 600px;
  max-height: 400px;
  background-color: #333;
`;

// ButtonContainer is moved outside of CropperContainer to position the button below
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  z-index: 1100; // Ensure it's above the overlay and the image
`;

const StyledCropper = styled(Cropper)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ImagePreviewContainer = styled.div`
  margin-top: 20px;
`;

const ImagePreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  padding: 10px 15px;
  margin-top: 0.1em; // Add some space above the button
  font-size: 1.5em;
  color: #fff;
  background-color: #007bff; // Vibrant blue color
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1100; // Ensure the button is clickable by placing it above the cropper
`;

const StaffPhoto = ({ imageSrc, onPhotoCropped, onClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropConfirm = useCallback(async () => {
    try {
      const blob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const objectUrl = URL.createObjectURL(blob);
      onPhotoCropped(blob);
      setCroppedImage(objectUrl);
      onClose();
    } catch (e) {
      console.error(e);
      }
      }, [imageSrc, croppedAreaPixels, onPhotoCropped, onClose]);
      
      const onDeleteCroppedImage = useCallback(() => {
      setCroppedImage(null);
      onPhotoCropped(null);
      }, [onPhotoCropped]);
      
      return ( <Container> <CropperModal imageSrc={imageSrc}> <CropperContainer> <StyledCropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          /> </CropperContainer> <ButtonContainer> <Button onClick={onCropConfirm}>Confirm Crop</Button> </ButtonContainer> </CropperModal>
      {croppedImage && ( <ImagePreviewContainer> <ImagePreview src={croppedImage} alt="Cropped" /> <Button onClick={onDeleteCroppedImage}>Delete Image</Button> </ImagePreviewContainer>
      )} </Container>
      );
      };
      
      export default StaffPhoto;