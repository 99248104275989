import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Hours from './Hours';
import Select from 'react-select';
import { FaArrowLeft /* other icons */ } from 'react-icons/fa';


// Define a color scheme
const colors = {
  main: '#5A6268', // A professional blue-gray color
  background: '#f2f2f2',
  button: '#6c757d', // A muted button color
  buttonHover: '#5a6268',
  focusBorder: '#00C6B6', // A color for the focus state
  text: '#495057', // Dark gray for text for better readability
  textSoft: '#6c757d', // Soft color for input text
};

const RenderEditModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure the container takes full width
`;

const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -1em; // Space between the top bar and the form
`;

const BackButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.main}; // Use color from your color scheme
  font-size: 2.1em; // Adjust size as needed
  padding: 10px;
  margin-left: 20px; // Give some space from the container edge
  transition: color 0.3s, transform 0.3s;

  &:hover {
    color: ${colors.buttonHover};
    transform: translateX(-5px); // Move slightly to the left on hover
  }

  &:focus {
    outline: none;
  }
`;


const FormSection = styled.div`
  display: flex;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: 500;
`;

const Column = styled.div`
  padding: 0 2em;
  flex: 0 0 30%; // This sets the base width to 40% and prevents it from growing or shrinking
  &:first-child {
    margin-right: 0.7em;
  }
`;

const RightColumn = styled.div`
  padding: 0 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0 60%; // This sets the base width to 60% and prevents it from growing or shrinking
  width: 60%; // This explicitly sets the width to 60% to ensure layout consistency
`;


const HoursContainer = styled.div`
  // No need for top margin adjustment if you want it at the top
`;

const SaveButton = styled.button`
 background: ${props => (props.allFieldsValid ? '#00c6b6' : '#6c757d')}; // Turquoise when valid
  color: white;
  border: none;
  padding: 0.4em 0.8em;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.7em;
  font-weight: 400;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${props => (props.allFieldsValid ? '#009c9a' : '#5a6268')}; // Darker turquoise on hover
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; // Aligns the Save button to the right
  margin-top: auto; // Pushes the Save button to the bottom
  margin-bottom: 0px; // Gives some space from the bottom
`;


const Label = styled.label`
  display: block;
  font-size: 1.5em; // Increased font size for better readability
  font-weight: 300;
  color: #333333;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
`;

const RequiredAsterisk = styled.span`
  color: ${props => (props.isValid ? 'transparent' : 'red')};
  font-size: 30px;
  font-weight: 300;
  margin-left: 2px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.7em 0.8em;
  margin-bottom: 0.6em;
  border-radius: 4px;
  border: 1px solid ${colors.main};
  background: ${colors.background};
  box-sizing: border-box;
  font-size: 1.3em;
  font-weight: 300;
  color: ${colors.textSoft};
  &:focus {
    border-color: ${colors.focusBorder}; // Updated to the new color
    outline: none;
  }
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#ffffff',
    fontSize: '1em',
    border: `1px solid ${colors.main}`,
    boxShadow: 'none',
    minHeight: '2em',
    marginBottom: '0.8em',
    '&:hover': {
      border: `1px solid ${colors.focusBorder}`,
    },
    '&:focus': {
      borderColor: `${colors.focusBorder}`,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.3em 16px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#e0e0e0',
    color: colors.text, // Remove the template literals when using a direct variable
    fontSize: '1.1em',
    fontWeight: '300',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: colors.text,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#c6c6c6',
      color: '#000000',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.textSoft,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: colors.textSoft,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.1em',
    fontWeight: '400',
  }),
};

const RenderEditMode = ({ info, setInfo, handleSave, setEditMode }) => {
  const [validFields, setValidFields] = useState({});

  useEffect(() => {
    validateFields(info);
  }, [info]);

  const validateFields = (info) => {
    const hoursValid = Object.values(info.hours).every(day => day.closed || (day.open && day.close));
    const newValidFields = {
      name: !!info.name,
      address: !!info.address,
      email: !!info.email,
      phone: !!info.phone,
      yearOpened: !!info.yearOpened,
      certifications: info.certifications.length > 0,
      paymentOptions: info.paymentOptions.length > 0,
      services: info.services.length > 0,
      hours: hoursValid,
    };
    setValidFields(newValidFields);
  };

  const handleChange = (event, day, field) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox" && day) {
      setInfo(prevInfo => ({
        ...prevInfo,
        hours: {
          ...prevInfo.hours,
          [day]: {
            ...prevInfo.hours[day],
            [field]: checked,
          },
        },
      }));
    } else if (day) {
      setInfo(prevInfo => ({
        ...prevInfo,
        hours: {
          ...prevInfo.hours,
          [day]: {
            ...prevInfo.hours[day],
            [field]: value,
          },
        },
      }));
    } else {
      setInfo(prevInfo => ({
        ...prevInfo,
        [name]: value,
      }));
    }
    validateFields({ ...info, [name]: value });
  };

  const allFieldsValid = Object.values(validFields).every(Boolean);

  const yearOptions = Array.from({ length: 125 }, (_, index) => 2024 - index);
  const certifications = ['None', 'Joint Commission International', 'ISO'];
  const paymentOptions = ['Cash', 'Check', 'Credit Card', 'Wire Transfer'];
  const services = ['None', 'Translation', 'Airport to Hotel Shuttle', 'Hotel to Clinic Transfer'];

  return (
    <RenderEditModeContainer>
      <TopBarContainer>
        <BackButton onClick={() => setEditMode(false)}>
          <FaArrowLeft />
        </BackButton>
      </TopBarContainer>
      <FormSection>
        <Column>
          {/* Name of Facility */}
          <Label>
            Name of Facility
            <RequiredAsterisk isValid={validFields.name}>*</RequiredAsterisk>
          </Label>
          <Input
            name="name"
            value={info.name}
            onChange={handleChange}
            placeholder="Enter facility name"
          />
  
          {/* Address of Facility */}
          <Label>
            Address of Facility
            <RequiredAsterisk isValid={validFields.address}>*</RequiredAsterisk>
          </Label>
          <Input
            name="address"
            value={info.address}
            onChange={handleChange}
            placeholder="Enter facility address"
          />
  
          {/* Email */}
          <Label>
            Email
            <RequiredAsterisk isValid={validFields.email}>*</RequiredAsterisk>
          </Label>
          <Input
            name="email"
            value={info.email}
            onChange={handleChange}
            placeholder="Enter facility email"
          />
  
          {/* Phone Number */}
          <Label>
            Phone Number
            <RequiredAsterisk isValid={validFields.phone}>*</RequiredAsterisk>
          </Label>
          <Input
            name="phone"
            value={info.phone}
            onChange={handleChange}
            placeholder="Enter facility phone number"
          />
  
          {/* Year Opened */}
          <Label>
            Year Opened
            <RequiredAsterisk isValid={validFields.yearOpened}>*</RequiredAsterisk>
          </Label>
          <Select
            name="yearOpened"
            value={yearOptions.find(option => option.value === info.yearOpened)}
            options={yearOptions.map(year => ({ value: year, label: year }))}
            styles={customStyles}
            placeholder="Select opening year"
            onChange={(selectedOption) => {
              setInfo(prevInfo => ({
                ...prevInfo,
                yearOpened: selectedOption.value,
              }));
            }}
          />
  
          {/* Certifications of Facility */}
          <Label>
            Certifications of Facility
            <RequiredAsterisk isValid={validFields.certifications}>*</RequiredAsterisk>
          </Label>
          <Select
            isMulti
            name="certifications"
            options={certifications.map(cert => ({ value: cert, label: cert }))}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            placeholder="Select certifications"
            onChange={selectedOptions => {
              setInfo(prevInfo => ({
                ...prevInfo,
                certifications: selectedOptions ? selectedOptions.map(option => option.value) : [],
              }));
            }}
          />
  
          {/* Payment Options */}
          <Label>
            Payment Options
            <RequiredAsterisk isValid={validFields.paymentOptions}>*</RequiredAsterisk>
          </Label>
          <Select
            isMulti
            name="paymentOptions"
            options={paymentOptions.map(option => ({ value: option, label: option }))}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            placeholder="Select payment options"
            onChange={selectedOptions => {
              setInfo(prevInfo => ({
                ...prevInfo,
                paymentOptions: selectedOptions ? selectedOptions.map(option => option.value) : [],
              }));
            }}
          />
  
          {/* Services Offered */}
          <Label>
            Services Offered
            <RequiredAsterisk isValid={validFields.services}>*</RequiredAsterisk>
          </Label>
          <Select
            isMulti
            name="services"
            options={services.map(service => ({ value: service, label: service }))}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            placeholder="Select offered services"
            onChange={selectedOptions => {
              setInfo(prevInfo => ({
                ...prevInfo,
                services: selectedOptions ? selectedOptions.map(option => option.value) : [],
              }));
            }}
          />
        </Column>
  
        <RightColumn>
          {/* Hours of Operation at the top */}
          <HoursContainer>
            <Label>
              Hours of Operation
              <RequiredAsterisk isValid={validFields.hours}>*</RequiredAsterisk>
            </Label>
            <Hours
              hours={info.hours}
              handleChange={handleChange}
            />
          </HoursContainer>

          {/* Spacer to push the Save button to the bottom */}
          <div />

          {/* Save button at the bottom */}
          <SaveButtonContainer>
            <SaveButton
              onClick={handleSave}
              disabled={!allFieldsValid}
              allFieldsValid={allFieldsValid}
            >
              Save
            </SaveButton>
          </SaveButtonContainer>
        </RightColumn>
      </FormSection>
    </RenderEditModeContainer>
  );
};

export default RenderEditMode;