import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FaTimes, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import StaffPhoto from './StaffPhoto';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 15px;
  width: 50%;
  max-width: 700px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative; /* Adjusted for close button positioning */
`;

const CloseButton = styled(FaTimes)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5em;
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  max-height: 35em;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 1.5em;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 0.6em;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%; /* Makes input boxes wider */
`;

const EducationTitle = styled.h4`
  font-weight: 300;
  font-size: 1.5em;
  margin-top: 0; /* Removes awkward space */
`;

const EducationSection = styled.div`
  display: flex;
  gap: 10px; /* Adds gap between input boxes */
`;

const AddButton = styled(FaPlusCircle)`
  cursor: pointer;
  margin-top: 10px;
`;

const DeleteIcon = styled(FaTrashAlt)`
  cursor: pointer;
  font-size: 3em;
  color: #dc3545; /* Tasteful shade of red */
  &:hover {
    color: #c82333; /* Darker shade on hover */
  }
`;

const DeleteImageText = styled.div`
  color: #dc3545; /* Tasteful shade of red */
  text-align: center;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #c82333; /* Darker shade on hover */
  }
`;

const Dropdown = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 16px;
  margin-bottom: 0.6em; /* Adjusted to remove awkward space */
`;

const SubmitButton = styled.input`
  background: ${props => (props.allFieldsValid ? '#00c6b6' : '#6c757d')};
  color: white;
  border: none;
  padding: 0.4em 0.8em;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: 400;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${props => (props.allFieldsValid ? '#009c9a' : '#5a6268')};
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const Asterisk = styled.span`
  color: red;
  font-size: 1.2em;
  margin-left: 5px;
`;

const ImagePreview = styled.img`
  width: 10em; // Adjust size to 7em diameter
  height: 10em; // Adjust size to 7em diameter
  object-fit: cover;
  border-radius: 50%;
  margin: 20px auto; // Center the image and add margin
  display: block; // Necessary for margin auto to work
`;

const AddStaff = ({ onClose, onSave }) => {
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('0');
  const [educations, setEducations] = useState([{ id: 1, institution: '', degree: '' }]);
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    const isValidName = name.trim() !== '';
    const isValidRole = role.trim() !== '';
    const isValidExperience = yearsOfExperience.trim() !== '' && !isNaN(yearsOfExperience) && parseInt(yearsOfExperience, 10) >= 0;
    const isValidEducations = educations.every(e => e.institution.trim() !== '' && e.degree.trim() !== '');
    const isValidPhoto = !!croppedImage;
    setAllFieldsValid(isValidName && isValidRole && isValidExperience && isValidEducations && isValidPhoto);
  }, [name, role, yearsOfExperience, educations, croppedImage]);

  const handleAddEducation = () => {
    const newId = educations.length ? Math.max(...educations.map(e => e.id)) + 1 : 1;
    setEducations([...educations, { id: newId, institution: '', degree: '' }]);
  };

  const handleRemoveEducation = (id) => {
    setEducations(educations.filter(e => e.id !== id));
  };

  const handleEducationChange = (id, field, value) => {
    setEducations(educations.map(edu => edu.id === id ? { ...edu, [field]: value } : edu));
  };

  const handlePhotoCropped = (croppedImg) => {
    setCroppedImage(croppedImg);
  };

  const handleDeleteCroppedImage = () => {
    setCroppedImage(null);
  };

  const handleModalClose = () => {
    setCroppedImage(null);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (allFieldsValid) {
      const newStaffMember = {
        id: Date.now().toString(),
        name,
        role,
        experience: yearsOfExperience,
        photo: croppedImage,
        educations
      };
      onSave(newStaffMember);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={handleModalClose} />
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="photo">Photo<Asterisk>*</Asterisk></Label>
          {croppedImage && (
            <>
              <ImagePreview src={croppedImage} alt="Cropped" />
              <DeleteImageText onClick={handleDeleteCroppedImage}>Delete Image</DeleteImageText>
            </>
          )}
          {!croppedImage && (
            <StaffPhoto
              onPhotoCropped={handlePhotoCropped}
            />
          )}
          <Label htmlFor="name">Name<Asterisk>*</Asterisk></Label>
          <Input type="text" id="name" value={name} onChange={e => setName(e.target.value)} required />
          <Label htmlFor="role">Role<Asterisk>*</Asterisk></Label>
          <Input type="text" id="role" value={role} onChange={e => setRole(e.target.value)} required />
          <Label htmlFor="experience">Years of Experience<Asterisk>*</Asterisk></Label>
          <Dropdown id="experience" value={yearsOfExperience} onChange={e => setYearsOfExperience(e.target.value)}>
            {[...Array(71).keys()].map(year => <option key={year} value={year}>{year}</option>)}
          </Dropdown>
          <EducationTitle>Education</EducationTitle>
          {educations.map((education, index) => (
            <EducationSection key={education.id}>
              <Input type="text" value={education.institution} placeholder="Institution" onChange={e => handleEducationChange(education.id, 'institution', e.target.value)} />
              <Input type="text" value={education.degree} placeholder="Degree" onChange={e => handleEducationChange(education.id, 'degree', e.target.value)} />
              <DeleteIcon onClick={() => handleRemoveEducation(education.id)} />
            </EducationSection>
          ))}
          <AddButton onClick={handleAddEducation} />
          <SubmitButton type="submit" value="Add Staff Member" disabled={!allFieldsValid} />
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AddStaff;
