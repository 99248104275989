import React, { useState } from 'react';
import RequestsDateTimePicker from './RequestsDateTimePicker';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import xrayImage from 'assets/xray.png'; // Adjust the path as per your project structure

import {
  ModalBackdrop,
  ModalContainer,
  ModalHeader,
  Title,
  CloseButton,
  ModalContent,
  InfoColumn,
  DateTimeColumn,
  DetailGroup,
  DetailTitle,
  DetailTitle2,
  DetailText,
  DateTimeList,
  DateTimeItem,
  RemoveButton,
  StyledButton,
  EditButton,
  SendButton,
  AddTimeButton,
  AnimatedPickerContainer,
  ModalFooter,
  Overlay,
  Loader, 
  QuestionnaireContainer,
  XRayImage,
  ImageOverlay,
  EnlargedImage,
  DeclineButton,
  RequestedDateRangeContainer,
  RequestedDateRangeTitle,
  RequestedDateRangeText,
  TimeslotDetailGroup,
  RemainingTimeslots,
} from './ModalStyles';

import {
  DeclineModalBackdrop,
  DeclineModalContainer,
  DeclineModalHeader,
  DeclineModalTitle,
  DeclineModalCloseButton,
  DeclineModalContent,
  ReasonTextArea,
  CharacterCount,
  DeclineModalConfirmButton,
  
} from './DeclineModalStyles';


const customToastStyles = {
  width: '15vw', // 15% of the viewport width
  minHeight: '8vh', // 8% of the viewport height
  padding: '20px',
  borderRadius: '18px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  fontSize: '1.1rem',
  fontFamily: "'Poppins', sans-serif",
};

dayjs.extend(advancedFormat);

const formatDateRange = (range) => {
  const [startDate, endDate] = range.split(' - ');

  const formatDayjs = (date) => dayjs(date).format('MMMM D, YYYY');

  // Adding ordinal suffix to the day
  const addOrdinalSuffix = (dateStr) => {
    const day = dayjs(dateStr).format('D');
    return dateStr.replace(day, dayjs(dateStr).format('Do'));
  };

  return `${addOrdinalSuffix(formatDayjs(startDate))} - ${addOrdinalSuffix(formatDayjs(endDate))}`;
};

const CustomCloseButton = ({ closeToast }) => (
  <button onClick={closeToast} style={{ fontSize: '2rem', color: '#c00', background: 'none', border: 'none', cursor: 'pointer' }}>
    &times;
  </button>
);

const RequestsModal = ({ isOpen, closeModal, request, removeRequest }) => {
  // Ensure selectedDateTimes is an array of objects { value: dayjs(), display: 'string' }
  const [selectedDateTimes, setSelectedDateTimes] = useState([]);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [showImageOverlay, setShowImageOverlay] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);


  const handleImageClick = () => {
    setShowImageOverlay(true);
  };

  const closeImageOverlay = () => {
    setShowImageOverlay(false);
  };

  const isDateTimeSelected = (newDateTime) => {
    // Ensure newDateTime is a dayjs object for comparison
    const newDayjsDateTime = dayjs(newDateTime);
    return selectedDateTimes.some(
      (dt) => dt.value && dt.value.isSame(newDayjsDateTime, 'minute')
    );
  };

  const handleDateTimeConfirm = (dateTime) => {
    // Convert the incoming dateTime to a dayjs object
    const formattedDateTime = dayjs(dateTime);
    
    if (!formattedDateTime.isValid()) {
      toast.error("Must select a valid date and time!");
      return;
    }
  
    // Check for uniqueness using the dayjs object
    if (isDateTimeSelected(formattedDateTime)) {
      toast.error("This appointment time has already been selected.");
      return;
    }
  
    if (selectedDateTimes.length < 10) {
      // When adding a new date-time
      setSelectedDateTimes(prevDateTimes => {
        const updatedDateTimes = [
          ...prevDateTimes,
          {
            value: formattedDateTime,
            display: formattedDateTime.format('MMMM D, YYYY h:mm A')
          }
        ];
        // Sort the array of date-time objects
        return updatedDateTimes.sort((a, b) => a.value - b.value);
      });
      setPickerVisible(false); // Hide the picker
    } else {
      toast.error("Maximum of 10 times can be selected.");
    }
  };
  
  const finalizeTimeSelection = () => {
    if (selectedDateTimes.length === 0) {
      toast.error("At least one date and time must be selected.");
      return;
    }
    setIsFinalized(true);
    console.log("Finalized Times:", selectedDateTimes.map(dt => dt.display));
  };

  const sendToPatient = () => {
    setIsLoading(true);
  
    // Simulate a network request with a 5-second delay
    setTimeout(() => {
      // Simulated successful API call response
      console.log("Times sent to patient:", selectedDateTimes.map(dt => dt.display));
  
      toast.success("Success! The patient has been emailed with the available times.", {
        onClose: () => {
          // Remove the request from the state or context that holds the requests
          removeRequest(request.id);
          setIsLoading(false);
  
          // Delay closing the modal to allow the user to read the toast message
          setTimeout(() => {
            closeModal();
          }, 2000);
        }
      });
    }, 3000);
  };
  

  // Function to remove a selected date-time
  const removeDateTime = (index) => {
    setSelectedDateTimes((prevDateTimes) =>
      prevDateTimes.filter((_, i) => i !== index)
    );
  };

  const handleDeclineConfirmation = (reason) => {
    setIsLoading(true);
  
    // Simulate a network request to send the decline reason
    setTimeout(() => {
      // Simulated successful API call response
      console.log("Declined with reason:", reason);
  
      toast.info("The patient has been notified of the appointment decline.", {
        onClose: () => {
          // Remove the request from the state or context that holds the requests
          removeRequest(request.id);
          setIsLoading(false);
          setShowDeclineModal(false);
  
          // Delay closing the modal to allow the user to read the toast message
          setTimeout(() => {
            closeModal();
          }, 2000);
  
          // Reset any related state
          setSelectedDateTimes([]);
          setIsFinalized(false);
        }
      });
    }, 3000);
  };
  

  const DeclineReasonModal = ({ isOpen, onClose }) => {
    const [reason, setReason] = useState('');
    const charLimit = 1000;
  
    const handleConfirm = () => {
      handleDeclineConfirmation(reason); // Call the decline confirmation handler
      onClose();
    };
  
    if (!isOpen) return null;
  
    return (
      <DeclineModalBackdrop>
        <DeclineModalContainer>
          <DeclineModalHeader>
            <DeclineModalTitle>Reason for Declining Request</DeclineModalTitle>
            <DeclineModalCloseButton onClick={onClose}>&times;</DeclineModalCloseButton>
          </DeclineModalHeader>
          <DeclineModalContent>
            <ReasonTextArea
              value={reason}
              onChange={(e) => setReason(e.target.value.slice(0, charLimit))}
              placeholder="Enter your reason here..."
            />
            <CharacterCount>{charLimit - reason.length} characters remaining</CharacterCount>
            <DeclineModalConfirmButton onClick={handleConfirm} disabled={reason.trim().length === 0}>
              Confirm
            </DeclineModalConfirmButton>
          </DeclineModalContent>
        </DeclineModalContainer>
      </DeclineModalBackdrop>
    );
  };
  
  
  
  const questionnaireData = [
    {
      question: "Have you had any major health changes in the past year?",
      answer: "No significant changes. Regular check-ups only."
    },
    {
      question: "Are you currently taking any medication?",
      answer: "Yes, Metformin for diabetes and Lisinopril for hypertension."
    },
    {
      question: "Do you have any allergies, especially to medications, latex, or metals used in dental work?",
      answer: "No known allergies."
    },
    {
      question: "Have you ever had any reactions to anesthesia?",
      answer: "No adverse reactions in the past."
    },
    {
      question: "Do you smoke or use tobacco products?",
      answer: "No, I do not smoke."
    },
    {
      question: "Have you had any issues with dental procedures in the past?",
      answer: "I had a mild infection after a tooth extraction two years ago, which was treated with antibiotics."
    },
    {
      question: "Are you currently experiencing any dental pain or discomfort?",
      answer: "Occasionally, I experience sensitivity in my lower molars."
    },
    {
      question: "Have you undergone any orthodontic treatment or had any dental implants before?",
      answer: "No orthodontic treatments or implants so far."
    },
    {
      question: "Do you have a history of gum disease or tooth loss?",
      answer: "Mild gum disease was treated five years ago; no tooth loss."
    },
    {
      question: "How would you describe your daily oral hygiene routine?",
      answer: "I brush twice daily, floss once daily, and use mouthwash occasionally."
    },
    {
      question: "Do you have any chronic illnesses or conditions?",
      answer: "I have Type 2 diabetes, controlled with medication and diet."
    },
    {
      question: "Are there any concerns or anxieties you have about the All-On-4/6 procedure?",
      answer: "I am concerned about the recovery time and post-procedure care."
    },
    {
      question: "Do you have a history of bruxism (teeth grinding) or clenching?",
      answer: "I used to grind my teeth at night, but it has lessened over the past year."
    },
    {
      question: "Have you experienced any significant changes in your weight recently?",
      answer: "I have lost about 10 pounds over the last six months through diet and exercise."
    },
    {
      question: "How often do you consume alcohol, and do you use any recreational drugs?",
      answer: "I drink socially, maybe once or twice a month. I do not use recreational drugs."
    }
  ];
  
  if (!isOpen || !request) return null;

  return (
    <ModalBackdrop>
      {showImageOverlay && (
        <ImageOverlay onClick={closeImageOverlay}>
          <EnlargedImage src={xrayImage} alt="Patient's X-Ray" />
        </ImageOverlay>
      )}
      <ModalContainer>
      <DeclineReasonModal
        isOpen={showDeclineModal}
        onClose={() => setShowDeclineModal(false)}
        onConfirm={handleDeclineConfirmation}
      />
        <ModalHeader>
          <Title>Appointment Request</Title>
          <CloseButton onClick={closeModal}>&times;</CloseButton>
        </ModalHeader>
        <ModalContent>
          {isLoading && (
            <Overlay>
              <Loader />
            </Overlay>
          )}
          {/* Information Column */}
          <InfoColumn>
            <DetailGroup>
              <DetailTitle>Name</DetailTitle>
              <DetailText>{request.name}</DetailText>
            </DetailGroup>
            <DetailGroup>
              <DetailTitle>Requested Procedures</DetailTitle>
              <DetailText>{request.requestedProcedures}</DetailText>
            </DetailGroup>
            {/* Medical Background and Questionnaire */}
            <DetailGroup>
              <DetailTitle>Medical Background</DetailTitle>
              <QuestionnaireContainer>
                {questionnaireData.map((item, index) => (
                  <div key={index}>
                    <DetailTitle>{item.question}</DetailTitle>
                    <DetailText>{item.answer}</DetailText>
                  </div>
                ))}
                <DetailTitle>Patient X-Ray</DetailTitle>
                <XRayImage src={xrayImage} alt="Patient's X-Ray" onClick={handleImageClick} />
              </QuestionnaireContainer>
            </DetailGroup>
          </InfoColumn>
          {/* DateTime Column */}
          <DateTimeColumn>
            <RequestedDateRangeContainer>
              <RequestedDateRangeTitle>Requested Date Range</RequestedDateRangeTitle>
              <RequestedDateRangeText>{formatDateRange(request.dateRange)}</RequestedDateRangeText>
            </RequestedDateRangeContainer>
            <TimeslotDetailGroup>
              <DetailTitle2>
                {isFinalized ? 'Selected Available Appointment Times' : 'Select Available Appointment Times'}
              </DetailTitle2>
              {!isFinalized && (
                <RemainingTimeslots>
                  Remaining time slots: {10 - selectedDateTimes.length}
                </RemainingTimeslots>
              )}
            </TimeslotDetailGroup>
            {/* Move the AddTimeButton and AnimatedPickerContainer above the DateTimeList */}
            {!isFinalized && selectedDateTimes.length < 10 && (
              <>
                {!pickerVisible && (
                  <AddTimeButton onClick={() => setPickerVisible(true)} variant="contained">
                    Add Available Time
                  </AddTimeButton>
                )}
                {pickerVisible && (
                  <AnimatedPickerContainer isVisible={pickerVisible}>
                    <RequestsDateTimePicker
                      dateRange={request.dateRange}
                      onConfirm={handleDateTimeConfirm}
                    />
                  </AnimatedPickerContainer>
                )}
              </>
            )}
            {/* Scrollable container for selected dates */}
            <DateTimeList>
              {selectedDateTimes.map((dt, index) => (
                <DateTimeItem key={index}>
                  {dt.display}
                  {!isFinalized && (
                    <RemoveButton onClick={() => removeDateTime(index)}>Remove</RemoveButton>
                  )}
                </DateTimeItem>
              ))}
            </DateTimeList>
          </DateTimeColumn>

        </ModalContent>
        <ModalFooter>
        {!isFinalized ? (
          <>
            <DeclineButton onClick={() => setShowDeclineModal(true)}>
              Decline Request
            </DeclineButton>
            <StyledButton
              onClick={finalizeTimeSelection}
              disabled={selectedDateTimes.length === 0}
            >
              Confirm Selected Times
            </StyledButton>
          </>
        ) : (
            <>
              <EditButton onClick={() => {
                // Logic to go back to editing
                setIsFinalized(false);
              }}>
                Edit Times
              </EditButton>
              <SendButton onClick={sendToPatient}>
                Send to Patient
              </SendButton>
            </>
          )}
        </ModalFooter>
      </ModalContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={customToastStyles}
        toastStyle={{
          ...customToastStyles,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        closeButton={<CustomCloseButton />}
      />
    </ModalBackdrop>
  );
};

export default RequestsModal;