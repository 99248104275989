// PatientSide/Navbar.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';
import HamburgerMenu from './HamburgerMenu';

const Nav = styled.nav`
  position: fixed;
  top:0.5em;
  left: 0;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
  z-index: 1000;
  height: 4em;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
`;

const Logo = styled.h1`
  color: #00c6b6;
  font-size: 2.5rem;
  font-weight: 600;
  margin-left: 0.2em;
  text-transform: lowercase;
`;

const MenuIcon = styled.div`
  font-size: 2rem;
  color: #00c6b6;
  cursor: pointer;
  margin-right: 0.5em;
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Nav>
      <Logo>voyadoc</Logo>
      <MenuIcon onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </MenuIcon>
      <HamburgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </Nav>
  );
};

export default Navbar;