import styled, { keyframes } from 'styled-components';
import { Button } from '@mui/material';


const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: white;
  width: 93%;
  height: 93%;
  border-radius: 0.4em;
  box-shadow: 0 0.3em 1em rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  padding: 1.25em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  padding-left: 1.25em;
  padding-top: 1.25em;
  margin: 0em;
  font-weight: 400;
  color: #333;
`;

const CloseButton = styled.button`
  font-size: 2.4rem;
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  &:hover {
    color: #000000;
  }
`;

const ModalContent = styled.div`
  display: flex;
  padding: 1em 3em; 
  font-family: 'Poppins', sans-serif;
  overflow-y: auto;
  flex-grow: 1;
`;

const InfoColumn = styled.div`
  flex: 0 0 65%; // Takes up 65% of the space
  padding: 1.25em 2.5em 1.25em 1.25em; // Top, Right, Bottom, Left padding
  margin-right: 0.7rem; // Additional right margin to avoid content touching the divider
  overflow: auto;
  box-sizing: border-box; // Ensure padding is included in the width
`;

const DateTimeColumn = styled.div`
  flex: 0 0 35%; // Takes up 35% of the space
  padding: 1.25em 1.25em 1.25em 2.5em; // Top, Right, Bottom, Left padding
  margin-left: 0.7em; // Additional left margin
  border-left: 0.06em solid #eee;
  overflow: hidden;
  box-sizing: border-box; // Ensure padding is included in the width
`;

const DetailGroup = styled.div`
  margin-bottom: 1em;
`;

const DetailTitle = styled.h4`
  font-size: 1rem;
  margin: 0em;
  margin-bottom: 0em;
  font-weight: 400;
  color: #999;
`;

const DetailTitle2 = styled.h4`
  font-size: 1.15rem;
  margin: 0em;
  margin-bottom: 0em;
  font-weight: 400;
  color: #333;
`;

const DetailText = styled.p`
  font-size: 1.1rem; // Slightly larger font size for answers
  margin: 0;
  margin-bottom: 1.1rem; // More space after each answer
  font-weight: 400;
  color: #111;
`;

const DateTimeList = styled.div`
  max-height: 30em; // Adjust based on your UI needs
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.4em;
  margin-top: 20px;
`;

const DateTimeItem = styled.div`
  background: #fff;
  padding: 0.7em 1em;
  margin-bottom: 0.5em; // Added margin for whitespace
  border-radius: 0.5em; // Rounded edges
  font-size: 1.1rem; // Larger font
  box-shadow: 0 0.15em 0.18em rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.125em solid #eee; // Subtle border for definition
`;

const RemoveButton = styled.button`
  font-size: 1.1rem; // Slightly larger font
  background: none;
  border: none;
  color: #c00;
  cursor: pointer;
  padding: 0.5em 0.75em; // Padding for larger clickable area
  border-radius: 0.5em; // Rounded corners
  &:hover {
    background-color: #fee; // Background color on hover
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  && {
    font-size: 0.95rem;
    padding: 0.6em 1.2em;
    background-color: #0056b3;
    color: white;
    border-radius: 0.85em;
    &:hover {
      background-color: #003d82;
    }
    &:disabled {
      background-color: #cccccc;
      color: white;
    }
  }
`;

const EditButton = styled(StyledButton)`
  && {
    background-color: #e0e0e0; // A light grey for an elegant look
    color: #757575; // A dark grey for text to be subtle
    margin-right: 0.7em; // Add space between the buttons
    &:hover {
      background-color: #bdbdbd; // A slightly darker shade on hover
      color: #424242;
    }
    &:disabled {
      background-color: #cccccc;
      color: white;
    }
  }
`;

const SendButton = styled(StyledButton)`
  && {
    background-color: #4caf50; // A soft green for sending
    color: white;
    &:hover {
      background-color: #43a047; // A slightly darker green on hover
    }
    &:disabled {
      background-color: #cccccc;
      color: white;
    }
  }
`;

const AddTimeButton = styled(Button)`
  && {
    font-size: 0.85em;
    width: 70%; // Narrower button
    padding: 0.7em 2em; // Comfortable clickable area
    margin: 0.33em auto; // Margin top and auto margins for horizontal centering
    display: block; // Allows for margin auto to center horizontally
    background-color: white; // White background color
    color: #007bff; // Vibrant blue font color
    border: 0.125em solid #007bff; // Vibrant blue border color
    border-radius: 1.25em; // Rounded corners for a modern look
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1); // Subtle shadow for depth
    &:hover {
      background-color: #e6f3ff; // A light blue for hover state that complements the vibrant blue
      color: #0056b3; // A darker blue font color on hover for contrast
      border-color: #0056b3; // A darker blue border color on hover for consistency
    }
    &:disabled {
      background-color: #cccccc;
      color: white;
      border-color: #cccccc; // Disabled state border color
    }
  }
`;

const AnimatedPickerContainer = styled.div`
  max-height: ${props => (props.isVisible ? '30em' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  width: 100%;
`;

const ModalFooter = styled.div`
  padding: 20px;
  display: flex;
  justify-content: right; // Changed to center the confirm button
  border-top: 1px solid #eee;
  margin-right: 40px;
  margin-bottom: 30px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure it's above other content */
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #ccc; /* Sleek silver-grey color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;



const QuestionnaireContainer = styled.div`
  height: 40em; // Adjust this height as needed
  overflow-y: scroll; // Scroll only for the questionnaire
  padding: 1.6em;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); // Enhanced shadow for a 3D effect
  font-size: 1.6rem;
  padding: 10px 20px 10px 20px;
`;


const XRayImage = styled.img`
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out; // Smooth transition for the transform
`;

const ImageOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; // Ensure it's above other content
`;

const EnlargedImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  border: 0.06em solid black;
  border-radius: 10px;
`;

// In your ModalStyles.js
const DeclineButton = styled(StyledButton)`
  && {
    background-color: #dc3545; // A shade of red similar to the decline modal button
    color: white;
    &:hover {
      background-color: #c82333; // A darker shade on hover
    }
    margin-right: 1.1em; // Add space between this and the next button
  }
`;

const RequestedDateRangeContainer = styled.div`
  padding: 10px 20px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  margin-bottom: 1.2em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const RequestedDateRangeTitle = styled.h5`
  font-size: 1.1rem;
  color: #666;
  font-weight: 400;
  margin: 0 0 0px 0;
`;

const RequestedDateRangeText = styled.p`
  font-size: 1.1rem;
  color: #333;
  font-weight: 500;
  margin: 0;
`;

const TimeslotDetailGroup = styled(DetailGroup)`
  margin-bottom: 0.5em; // Reduced margin
  overflow-y: auto;
`;

const RemainingTimeslots = styled.p`
  font-size: 0.95rem;
  color: #333;
  margin-top: -0.1em; // Adjust as needed
  margin-bottom: 0.7em; // Adjust to reduce space
`;


// Export all styled components
export {
  ModalBackdrop,
  ModalContainer,
  ModalHeader,
  Title,
  CloseButton,
  ModalContent,
  InfoColumn,
  DateTimeColumn,
  DetailGroup,
  DetailTitle,
  DetailTitle2,
  DetailText,
  DateTimeList,
  DateTimeItem,
  RemoveButton,
  StyledButton,
  EditButton,
  SendButton,
  AddTimeButton,
  AnimatedPickerContainer,
  ModalFooter,
  Overlay,
  Loader,
  QuestionnaireContainer,
  XRayImage,
  ImageOverlay,
  EnlargedImage,
  DeclineButton,
  RequestedDateRangeContainer,
  RequestedDateRangeTitle,
  RequestedDateRangeText,
  TimeslotDetailGroup,
  RemainingTimeslots,
};