import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import AddStaff from 'components/DemoDashboard/Helpers/Information/AddStaff';

import Doc1Image from 'assets/DoctorHeadshots/doc1.jpg';
import Doc2Image from 'assets/DoctorHeadshots/doc2.jpg';
import Doc3Image from 'assets/DoctorHeadshots/doc3.jpg';
import Doc4Image from 'assets/DoctorHeadshots/doc4.jpg';

// Styled components
const StaffInfoWrapper = styled.div`
  padding: 20px;
  background: #FFFFFF; /* Pure white background */
  border-radius: 8px;
  margin-top: 0.4em;
  display: flex;
  flex-direction: column;
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns button to the right */
  background: #FFFFFF; /* Pure white background */
`;

const Table = styled.table`
  width: 100%;
  border: 0.5px solid #ddd;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures the table respects the set widths */
`;

const Th = styled.th`
  text-align: center;
  padding: 8px;
  background: #eee;
  height: 2.5em;
  font-size: 1.6em;
  font-weight: 300;
  border: none;
  width: 20%; /* Equal width for 5 columns */
`;

const Td = styled.td`
  text-align: center; /* Centers the content */
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 1.4em;
  font-weight: 300;
  width: 20%; /* Equal width for 5 columns */
`;


const CircularImage = styled.img`
  width: 2.5em; /* Adjusted size */
  height: 2.5em; /* Adjusted size */
  border-radius: 50%;
`;


const AddButton = styled.button`
background: #00C6B6; // Bright turquoise color
color: white;
border: none;
padding: 15px 30px;
border-radius: 50px;
cursor: pointer;
display: inline-flex;
align-items: center;
gap: 10px; // Added gap between icon and text for spacing
font-size: 1.5em;
margin-bottom: 0.7em;

&:hover {
  background: #008C8C; // Slightly darker shade on hover
}

&:disabled {
  background: #A5D6A7;
}
  display: block; // Ensure it takes its own line
  `;

const DeleteIcon = styled(FaTrashAlt)`
  color: #ff0000;
  cursor: pointer;
  font-size: 1.1em; /* Adjusted size */

  &:hover {
    color: #cc0000;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: left;
`;

const Button = styled.button`
  padding: 8px 20px;
  font-size: 16px; /* Reduced font size */
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }

  ${props => props.no && css`
    background-color: #6c757d;
    &:hover {
      background-color: #545b62;
    }
  `}

  ${props => props.yes && css`
    background-color: #dc3545;
    &:hover {
      background-color: #c82333;
    }
  `}
`;

const ConfirmationOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1500;
`;

const ConfirmationModal = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  ${({ show }) => show && css`
    transform: scale(1);
  `}
`;

const ConfirmationText = styled.p`
  font-size: 1.5em; // Larger font for clarity
  margin-bottom: 20px;
`;

const ScrollableTableBody = styled.div`
  max-height: calc(68vh);
  overflow-y: auto;
`;


const StaffInformation = () => {
  // Generate 20 John Doe entries for testing
  const initialStaffMembers = [
    {
      id: "1",
      name: 'Amir Saeed',
      role: 'Prosthodontist',
      experience: '6 years',
      photo: Doc1Image, // Replace with actual import or path
    },
    {
      id: "2",
      name: 'Priya Desai',
      role: 'Orthodontist',
      experience: '14 years',
      photo: Doc2Image, // Replace with actual import or path
    },
    {
      id: "3",
      name: 'Luca Moretti',
      role: 'Periodontist',
      experience: '8 years',
      photo: Doc3Image, // Replace with actual import or path
    },
    {
      id: "4",
      name: 'Hugo St-Laurent',
      role: 'Oral Surgeon',
      experience: '21 years',
      photo: Doc4Image, // Replace with actual import or path
    },
  ];
  
  const [staffMembers, setStaffMembers] = useState(initialStaffMembers);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleAddStaff = (newMember) => {
    setStaffMembers([...staffMembers, newMember]);
    setShowAddModal(false); // Hide modal after adding
  };

  const handleDeleteRequest = (id) => {
    setToBeDeleted(id);
  };

  const confirmDelete = () => {
    if (toBeDeleted !== null) {
      setStaffMembers(staffMembers.filter(member => member.id !== toBeDeleted));
      setToBeDeleted(null);
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  return (
    <StaffInfoWrapper>
      <HeaderArea>
        <AddButton onClick={openAddModal}>
          Add Staff Member
        </AddButton>
      </HeaderArea>
      <Table>
        <thead>
          <tr>
            <Th>Photo</Th>
            <Th>Name</Th>
            <Th>Role</Th>
            <Th>Experience</Th>
            <Th>Action</Th>
          </tr>
        </thead>
      </Table>
      <ScrollableTableBody>
        <Table>
          <tbody>
            {staffMembers.map((member, index) => (
              <tr key={member.id}>
                <Td><CircularImage src={member.photo} alt="Staff" /></Td>
                <Td>{member.name}</Td>
                <Td>{member.role}</Td>
                <Td>{member.experience}</Td>
                <Td><DeleteIcon onClick={() => handleDeleteRequest(member.id)} /></Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollableTableBody>
      {toBeDeleted && (
        <ConfirmationOverlay>
          <ConfirmationModal show={toBeDeleted !== null}>
            <ConfirmationText>Are you sure you want to delete?</ConfirmationText>
            <ButtonGroup>
              <Button yes onClick={confirmDelete}>Yes</Button>
              <Button no onClick={() => setToBeDeleted(null)}>No</Button>
            </ButtonGroup>
          </ConfirmationModal>
        </ConfirmationOverlay>
      )}
      {showAddModal && (
        <AddStaff 
          onClose={() => setShowAddModal(false)} 
          onSave={handleAddStaff}
        />
      )}
    </StaffInfoWrapper>
  );
};

export default StaffInformation;