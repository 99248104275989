import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './UserContext';
import ProtectedRoute from './ProtectedRoute';
import Home from './components/PatientSide/Home';
import NotFound from './components/NotFound/NotFound';
import PatientLogin from './components/Login/PatientLogin';
import ProviderLogin from './components/Login/ProviderLogin';
import AdminLogin from './components/Login/AdminLogin';
import DemoDashboard from './components/DemoDashboard/DemoDashboard';
import ProviderDashboard from './components/ProviderDashboard/ProviderDashboard';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import 'styles/ProviderDashboard/ProviderDashboard.css';
import 'typeface-poppins';

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<PatientLogin />} />
          <Route path="/providerlogin" element={<ProviderLogin />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/demo" element={<DemoDashboard />} />
          <Route path="/unauthorized" element={<Home />} />
          <Route element={<ProtectedRoute allowedRoles={['provider']} />}>
            <Route path="/providerdashboard" element={<ProviderDashboard />} />
          </Route>
          <Route element={<ProtectedRoute allowedRoles={['admin']} />}>
            <Route path="/admindashboard" element={<AdminDashboard />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;