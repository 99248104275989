// components/ProviderDashboard/ContentPages/Table.js
import React from 'react';
import styled from 'styled-components';

const HighlightedText = styled.span`
    background-color: #FFEB3B;;
`;

function highlightMatches(text, searchTerm) {
  if (!text) return '';
  const splitText = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  return splitText.map((str, index) => 
      str.toLowerCase() === searchTerm.toLowerCase() 
          ? <HighlightedText key={index}>{str}</HighlightedText> 
          : str
  );
}

const StyledTable = styled.table`
  width: 65vw; 
  border-collapse: collapse;
  margin: 0.5em 0em 1em auto; // Modified margin to push the table to the right
  font-size: 18px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1); 
`;


const TableHeader = styled.th`
  font-size: 0.8em;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  background: #F0F0F0;
  color: #333;
  padding: 0.3em 1em;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-bottom: 1.3px solid #ccc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableRow = styled.tr`
  height: 7vh;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  &:hover {
    background-color: rgba(0, 198, 182, 0.1);
    cursor: pointer;
  }
`;

const TableCell = styled.td`
  padding: 15px 1em;
  border-bottom: 1.3px solid #ddd;
  text-align: left;
  font-size: 0.8em;
  max-width: 0; // Ensure this is set so that overflow logic triggers
  white-space: nowrap; // Do not allow text to wrap to the next line
  overflow: hidden; // Hide overflow
  text-overflow: ellipsis; // Add ellipsis to overflowed text
  
  &.Completed { 
    color: #1CA81B; 
    background-color: rgba(28, 168, 27, 0.1);
  }
  &.Scheduled { 
    color: #FFB800; 
    background-color: rgba(255, 184, 0, 0.1);
  }
  &.Cancelled { 
    color: #FF5C5C; 
    background-color: rgba(255, 92, 92, 0.1);
  }
`;

export const Table = ({ appointments, setIsModalOpen, setSelectedAppointment, searchTerm }) => {

  function formatDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  function formatTime(time) {
    // Strip off any existing 'AM' or 'PM'
    time = time.replace(/(AM|PM)/i, '').trim();
    
    const [hour, minute] = time.split(":");
    let newHour = parseInt(hour, 10);
    const ampm = newHour >= 12 ? "PM" : "AM";

    if (newHour > 12) newHour -= 12;
    if (newHour === 0) newHour = 12;

    return `${newHour}:${minute} ${ampm}`;
  }


  return (
      <StyledTable>
          <thead>
              <TableRow>
                <TableHeader width="10%">Reference ID</TableHeader>
                <TableHeader width="20%">Name</TableHeader>
                <TableHeader width="25%">Procedure</TableHeader>
                <TableHeader width="18%">Date</TableHeader>
                <TableHeader width="12%">Time</TableHeader>
                <TableHeader width="15%">Status</TableHeader>
              </TableRow>
          </thead>
          <tbody>
              {appointments.map(appointment => (
                  <TableRow 
                      key={appointment.confirmationNumber} 
                      onClick={() => {
                          setSelectedAppointment(appointment);
                          setIsModalOpen(true);
                      }}
                  >
                      <TableCell width="10%">{highlightMatches(appointment.confirmationNumber, searchTerm)}</TableCell>
                      <TableCell width="20%">{highlightMatches(appointment.name, searchTerm)}</TableCell>
                      <TableCell width="25%">{appointment.procedure}</TableCell>
                      <TableCell width="18%">{formatDate(appointment.date)}</TableCell>
                      <TableCell width="12%">{formatTime(appointment.time)}</TableCell>
                      <TableCell width="15%" className={appointment.status}>{appointment.status}</TableCell>
                  </TableRow>
              ))}
          </tbody>
      </StyledTable>
  );
};

export default Table;
