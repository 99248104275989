import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import styled, { ThemeProvider } from 'styled-components';
import { useUser } from 'UserContext.js';
import axiosInstance from 'utils/axiosInstance';


const theme = {
  turquoiseColor: '#AFEEEE',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333', // Darker color for actual input text
  placeholderColor: '#606060', // Medium grey for placeholder text
  lightGrey: '#ccc', // Light grey for borders
  darkGrey: '#808080', // Darker grey for focused borders
};

const ModalTitle = styled.h2`
  font-weight: 300;
  font-size: 1.6em;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1.3em;
  margin-bottom: 0.2em;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.3em;
  background-color: #00C6B6;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #009a94;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const CustomInput = styled.input`
  font-size: 1.3em;
  padding: 0.8em; // Adjusted to match Select padding more closely
  width: 100%; // Adjusted width as per requirement
  border: 1px solid ${theme.lightGrey};
  border-radius: 5px;
  transition: border-color 0.2s;
  color: ${theme.fontColor};
  &:focus {
    outline: none;
    border-color: ${theme.darkGrey};
  }
  &::placeholder {
    color: ${theme.placeholderColor};
  }
`;

const SelectContainer = styled.div`
  width: 75%; // Container to control width of React Select
`;

// Update customSelectStyles if necessary to adjust padding
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '1.5em',
    padding: '0.2em 0.4em', // Adjust padding to closely match CustomInput
    borderColor: theme.lightGrey,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    // This ensures the height matches the CustomInput if needed
    minHeight: '2.5em',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '1.3em',
    backgroundColor: state.isFocused ? theme.hoverTurquoiseColor : 'white',
    color: state.isFocused ? theme.fontColor : theme.placeholderColor,
    '&:hover': { backgroundColor: theme.turquoiseColor },
  }),
};

const AddModalContent = ({ onConfirm, type, setType, typeOptions, setTypeOptions, providerCategory, user }) => {
  const { idToken } = useUser();
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [price, setPrice] = useState('');
  const [procedureOptions, setProcedureOptions] = useState([]);

  console.log('Provider category:', providerCategory);
  

  useEffect(() => {
    const fetchProcedures = async () => {
      try {
        if (!user?.uid || !providerCategory) {
          console.log("User UID or category is missing.");
          return;
        }
  
        const proceduresResponse = await axiosInstance.get('/procedures', {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        const proceduresData = proceduresResponse.data || [];
  
        const providerProceduresResponse = await axiosInstance.get(`/providerprocedures/${user.uid}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        const providerProceduresData = providerProceduresResponse.data || [];
  
        const existingProceduresMap = new Map(providerProceduresData.map(proc => [`${proc.name}-${proc.type}`, proc]));
  
        const availableProcedures = proceduresData.reduce((acc, procedure) => {
          const key = `${procedure.name}-${procedure.type}`;
  
          if (procedure.category === providerCategory && !existingProceduresMap.has(key)) {
            let existingProc = acc.find(p => p.label === procedure.name);
            if (existingProc) {
              existingProc.types.push(procedure.type);
            } else {
              acc.push({
                value: procedure.id,
                label: procedure.name,
                types: [procedure.type],
              });
            }
          }
          return acc;
        }, []);
  
        setProcedureOptions(availableProcedures);
      } catch (error) {
        console.error('Error fetching procedures:', error);
      }
    };
  
    fetchProcedures();
  }, [user, idToken, providerCategory]);
  

  useEffect(() => {
    if (selectedProcedure) {
      const { types } = selectedProcedure;
      setTypeOptions(types.map(type => ({ value: type, label: type })));
      setType(types[0] || '');
    } else {
      setTypeOptions([]);
      setType('');
    }
    // eslint-disable-next-line
  }, [selectedProcedure]);

  const handleConfirm = () => {
    if (!selectedProcedure || price.trim() === '') {
      alert('Please select a procedure and set a price.');
      return;
    }
    console.log('Confirming procedure with data:', { name: selectedProcedure.label, price: parseFloat(price), type: type });
    onConfirm({
      name: selectedProcedure.label,
      price: parseFloat(price),
      type: type,
    });
  };
  
  return (
    <ThemeProvider theme={theme}> 
      <div>
        <ModalTitle>Add Procedure</ModalTitle>
        <SelectContainer>
          <Label htmlFor="procedureSelect">Procedure</Label>
          <Select
            id="procedureSelect"
            styles={customSelectStyles}
            value={selectedProcedure}
            onChange={setSelectedProcedure}
            options={procedureOptions}
            placeholder="Select a procedure"
          />
        </SelectContainer>
        {typeOptions.length > 0 && (
          <div style={{ marginTop: '20px', width: '50%' }}>
            <Label htmlFor="typeSelect">Type</Label>
            <Select
              id="typeSelect"
              styles={customSelectStyles}
              value={{ value: type, label: type }}
              onChange={selectedOption => setType(selectedOption.value)}
              options={typeOptions}
              placeholder="Select a type"
            />
          </div>
        )}
        <div style={{ marginTop: '20px', width: '50%' }}>
          <Label htmlFor="priceInput">Price (USD)</Label>
          <CustomInput
            id="priceInput"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Enter price in USD"
          />
        </div>
        <ButtonContainer>
          <ConfirmButton onClick={handleConfirm} disabled={!selectedProcedure || !type}>
            Confirm
          </ConfirmButton>
        </ButtonContainer>
      </div>
    </ThemeProvider>
  );
};

export default AddModalContent;