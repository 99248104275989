// HighlightText.js
import React from 'react';
import styled from 'styled-components';

const HighlightedText = styled.span`
    background-color: #FFEB3B;
`;

export const HighlightText = ({ text, searchTerm }) => {
  if (!text) return '';
  const splitText = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  return (
    <>
      {splitText.map((part, index) => 
        part.toLowerCase() === searchTerm.toLowerCase() 
            ? <HighlightedText key={index}>{part}</HighlightedText> 
            : part
      )}
    </>
  );
};
