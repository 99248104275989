import React from 'react';
import styled from 'styled-components';

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1em;
`;

const FilterTitle = styled.label`
  margin-top: 1.3em;
  font-size: 1em;
  font-weight: 300;
  color: #7E7E7E;
  margin-bottom: 4px;
`;

const InputStyle = `
  padding: 0.6em;
  font-size: 1em;
  width: 70%;
  border-radius: 10px;
  border: 2px solid grey;
`;

const FilterSelect = styled.select`
  ${InputStyle}
`;

const DateInput = styled.input.attrs(props => ({
  type: 'date',
}))`
  ${InputStyle}
`;

const SearchInput = styled.input`
  ${InputStyle}
`;

const ClearButton = styled.button`
  margin-top: 3em;
  padding: 10px 15px;
  font-size: 20px;
  cursor: pointer;
  width: 80%; 
  ${InputStyle}
  &:hover {
    background-color: #ddd;
  }
`;

const procedures = [
  'Dental Cleaning', 'Tooth Extraction', 'Root Canal', 
  'Teeth Whitening', 'Dental Checkup'
];

const Highlight = styled.span`
  background-color: #FFEB3B; // Highlight with a yellow background
`;

const highlightText = (text, searchTerm) => {
  if (!searchTerm || !searchTerm.trim()) return text;

  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi')); // split based on searchTerm
  return parts.map((part, i) => (
    part.toLowerCase() === searchTerm.toLowerCase() ? <Highlight key={i}>{part}</Highlight> : part
  ));
};

const SearchAndFilterReviews = ({
  searchTerm, setSearchTerm, 
  procedureFilter, setProcedureFilter, 
  startDate, setStartDate, 
  endDate, setEndDate,
  clearFilters
  }) => {
    return (
      <TopSection>
        <FilterTitle>Search</FilterTitle>
        <SearchInput 
          placeholder={highlightText("Search by Name or Comment..", searchTerm)}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
  
        <FilterTitle>Filter by Procedure</FilterTitle>
        <FilterSelect value={procedureFilter} onChange={e => setProcedureFilter(e.target.value)}>
          <option value="All Procedures">All Procedures</option>
          {procedures.map(proc => <option key={proc} value={proc}>{proc}</option>)}
        </FilterSelect>

  
        <FilterTitle>Start Date</FilterTitle>
        <DateInput value={startDate} onChange={e => setStartDate(e.target.value)} />
        
        <FilterTitle>End Date</FilterTitle>
        <DateInput value={endDate} onChange={e => setEndDate(e.target.value)} />
  
        <ClearButton onClick={clearFilters}>Clear Filters</ClearButton>
      </TopSection>
    );
  };

export default SearchAndFilterReviews;
