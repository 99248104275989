import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FaTrashAlt } from 'react-icons/fa'; // Importing trash icon and plus icon
import { toast, ToastContainer } from 'react-toastify'; // Importing toast
import { useUser } from 'UserContext.js'; 
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import axiosInstance from 'utils/axiosInstance';
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import { storage } from 'firebase.js';

const PhotosWrapper = styled.div`
  padding: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); // Increased to 5 images per row
  gap: 26px; // Reduced gap size
  // Comment: Reduced the gap between images for a tighter layout and increased the number of images per row
`;

const DeleteOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.42); // semi-transparent white overlay
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: transform 0.3s cubic-bezier(.25,.8,.25,1), box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  background: #fff; // Fallback color if images are loading or failed to load

  &:before {
    content: "";
    display: block;
    padding-top: 100%; // This sets the aspect ratio of the container, adjust as needed
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  &:hover ${DeleteOverlay} {
    opacity: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

const DeleteIcon = styled(FaTrashAlt)`
  color: #ff0000;
  cursor: pointer;
  font-size: 1.3em;
  margin: 10px;
  z-index: 101;
  
  &:hover {
    color: #cc0000;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end; // Align button to the right
  margin-top: 20px;
  width: 100%; // Full width to allow alignment to the right
  // Comment: Moved the AddPhotoButton to the right and made the button group full width
`;


const ConfirmationButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 26px;
  font-size: 1em;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease;

  &:hover {
    opacity: 0.9;
    transform: translateY(-0.05em);
  }

  ${props => props.no && css`
    background-color: #6c757d;
    &:hover {
      background-color: #545b62;
    }
  `}

  ${props => props.yes && css`
    background-color: #dc3545;
    &:hover {
      background-color: #c82333;
    }
  `}
`;

const ConfirmationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmationModal = styled.div`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  ${({ show }) => show && css`
    transform: scale(1);
  `}
`;

const ConfirmationText = styled.p`
  font-size: 1.7em; // Larger font for clarity
  font-weight: 400;
  margin-bottom: 20px;
`;

const SaveButton = styled(Button)`
  margin-top: 20px;
  background-color: #28a745; // A modern green
  &:hover {
    background-color: #218838;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 500;
`;

const AddPhotoButton = styled.button`
  background: #00C6B6; // Bright turquoise color
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1.5em;
  margin-bottom: 0.7em;

  &:hover {
    background: #008C8C; // Slightly darker shade on hover
  }

  &:disabled {
    background: #A5D6A7;
  }
  // Comment: Changed the color to bright turquoise and added a hover effect
`;

// Adjusted the ScrollableGrid to make the height of the container shorter
const ScrollableGrid = styled.div`
  max-height: calc(100vh - 15em); // Decreased the height
  overflow-y: auto;
  padding-right: 20px;
  // Comment: Reduced the height of the grid container to make it shorter
`;

const ModalHeader = styled.h2`
  font-size: 1.5em;
  font-weight: 300;
  color: #333;
  margin-bottom: 1em;
  // Comment: Modernized header with softer weight and larger size
`;

const FileInputLabel = styled.label`
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
  // Comment: Custom styled label for a more refined file input experience
`;

const StyledFileInput = styled.input`
  display: none;
  // Comment: File input is hidden and will be triggered by the label
`;

const ModalSaveButton = styled(SaveButton)`
  font-size: 1em;
  background-color: #00C6B6; // Bright turquoise for a modern look
  padding: 15px 30px;
  display: ${props => props.file ? 'block' : 'none'};

  &:hover {
    background-color: #008C8C; // Darker turquoise on hover
  }

  // Comment: Only display the Save button when a file is selected
  ${props => props.file && css`
    display: block;
    margin-top: 1em; // Add margin above the Save button
  `}
`;

const AddPhotoMessage = styled.p`
  color: 'grey';
  font-size: '1.1rem';
  text-align: 'center';
  margin-top: 0.5em; // Add space above the message
  // Comment: This message is displayed below the Add Photo button
`;

const NoPhotosMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #666;
  margin-top: 20vh;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure it's above other content */
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #ccc; /* Sleek silver-grey color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 200px; // Set a fixed height for the preview image
  object-fit: contain;
  margin-bottom: 20px;
`;

const Photos = () => {
  const { user, idToken } = useUser();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());
  const [previewURL, setPreviewURL] = useState(null);

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      const fetchPhotos = async () => {
        try {
          const response = await axiosInstance.get(`/providerphotos/${user.uid}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
          setItems(response.data.photos);
        } catch (error) {
          console.error("Error fetching photos:", error);
          toast.error("Failed to fetch photos");
        } finally {
          setIsLoading(false);
        }
      };

      fetchPhotos();
    }
  }, [user, idToken]);

  const handleAddClick = () => setIsModalOpen(true);

  const onSelectFile = useCallback((event) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      console.log('Selected File:', selectedFile);

      // Validate file type
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(selectedFile.type)) {
        console.error('Invalid file type. Only JPEG, PNG, and GIF are allowed.');
        toast.error('Invalid file type. Only JPEG, PNG, and GIF are allowed.');
        return;
      }

      // Validate file size (e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (selectedFile.size > maxSize) {
        console.error('File size exceeds the maximum limit of 5MB.');
        toast.error('File size exceeds the maximum limit of 5MB.');
        return;
      }

      setFile(selectedFile);
      setPreviewURL(URL.createObjectURL(selectedFile));
      setInputKey(Date.now());
    }
  }, []);

  const handleSave = async () => {
    if (file && user) {
      try {
        const fileRef = ref(storage, `providerPhotos/${user.uid}/${Date.now()}-${file.name}`);
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        await axiosInstance.post(`/providerphotos/${user.uid}`, { photoURL: downloadURL }, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        setItems((prevItems) => [...prevItems, downloadURL]);
        toast.success("Photo uploaded successfully");
      } catch (error) {
        console.error("Error uploading photo:", error);
        toast.error("Failed to upload photo");
      } finally {
        setIsModalOpen(false);
        setFile(null);
        setPreviewURL(null);
      }
    }
  };

  const handleDelete = useCallback(async () => {
    if (!toBeDeleted || !user) return;

    try {
      const fileRef = ref(storage, toBeDeleted);
      await deleteObject(fileRef);
      await axiosInstance.delete(`/providerphotos/${user.uid}/${encodeURIComponent(toBeDeleted)}`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      setItems((prevItems) => prevItems.filter((item) => item !== toBeDeleted));
      toast.success("Photo deleted successfully");
    } catch (error) {
      console.error("Error deleting photo:", error);
      toast.error("Failed to delete photo");
    } finally {
      setToBeDeleted(null);
    }
  }, [toBeDeleted, user, idToken]);

  const confirmDeletion = () => {
    handleDelete();
  };

  const renderMedia = useCallback((src) => (
    <ImageContainer key={src}>
      <Image src={src} alt="Uploaded content" />
      <DeleteOverlay onClick={() => setToBeDeleted(src)}>
        <DeleteIcon />
      </DeleteOverlay>
    </ImageContainer>
  ), []);

  if (isLoading) {
    return (
      <LoadingOverlay>
        <Loader />
      </LoadingOverlay>
    );
  }


  return (
    <PhotosWrapper>
      <ButtonGroup>
        <AddPhotoButton onClick={handleAddClick} disabled={items.length >= 20}>
          Add Photo
        </AddPhotoButton>
        {items.length >= 20 && <AddPhotoMessage>A maximum of 20 images can be added.</AddPhotoMessage>}
      </ButtonGroup>
      {items.length === 0 ? (
        <NoPhotosMessage>No photos added yet</NoPhotosMessage>
      ) : (
        <ScrollableGrid>
          <Grid>{items.map(renderMedia)}</Grid>
        </ScrollableGrid>
      )}
      {isModalOpen && (
        <>
          <Overlay onClick={() => setIsModalOpen(false)} />
          <Modal>
            <ModalHeader>Upload Photo</ModalHeader>
            {previewURL && <ImagePreview src={previewURL} alt="Preview" />}
            <FileInputLabel htmlFor="file-input">Choose file</FileInputLabel>
            <StyledFileInput
              id="file-input"
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              key={inputKey}
            />
            <ModalSaveButton file={file} onClick={handleSave}>Save Photo</ModalSaveButton>
          </Modal>
        </>
      )}
      {toBeDeleted && (
        <ConfirmationOverlay>
          <ConfirmationModal show={!!toBeDeleted}> 
            <ConfirmationText>Are you sure you want to delete?</ConfirmationText>
            <ConfirmationButtonGroup>
              <Button yes onClick={confirmDeletion}>Yes</Button>
              <Button no onClick={() => setToBeDeleted(null)}>No</Button>
            </ConfirmationButtonGroup>
          </ConfirmationModal>
        </ConfirmationOverlay>
      )}
      <ToastContainer />
    </PhotosWrapper>
  );
}

export default Photos;