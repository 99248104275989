import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const ModalTitle = styled.h2`
  font-weight: 300;
  font-size: 1.6em;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1.3em;
  margin-bottom: 0.2em;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.3em;
  background-color: #00C6B6;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #009a94;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Input = styled.input`
  font-size: 1.3em;
  padding: 0.8em;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s;
  &:focus {
    outline: none;
    border-color: #808080;
  }
`;

const ErrorMessage = styled.p` 
  color: #FF0000;
  font-size: 1.1em;
  margin-top: 10px;
`;

const theme = {
  turquoiseColor: '#AFEEEE',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333', // Darker color for actual input text
  placeholderColor: '#606060', // Medium grey for placeholder text
  lightGrey: '#ccc', // Light grey for borders
  darkGrey: '#808080', // Darker grey for focused borders
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '0.8em',
    padding: '0.1em 0.2em', // Adjust padding to closely match CustomInput
    borderColor: theme.lightGrey,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    // This ensures the height matches the CustomInput if needed
    minHeight: '1.5em',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.8em',
    backgroundColor: state.isFocused ? theme.hoverTurquoiseColor : 'white',
    color: state.isFocused ? theme.fontColor : theme.placeholderColor,
    '&:hover': { backgroundColor: theme.turquoiseColor },
  }),
};

const AddModalContent = ({ onConfirm, category, setCategory, procedureCategories }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleConfirm = async () => {
    if (name.trim() === '' || type.trim() === '') {
      setErrorMessage('Please enter a procedure name and type.');
      return;
    }

    try {
      await onConfirm({ name, type });
      setName('');
      setType('');
      setErrorMessage('');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage('A procedure with the same name and type already exists.');
      } else {
        setErrorMessage('Failed to add the procedure. Please try again.');
      }
    }
  };
  
  return (
    <div>
      <ModalTitle>Add Procedure</ModalTitle>
      <div>
        <Label htmlFor="nameInput">Procedure Name</Label>
        <Input
          id="nameInput"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter procedure name"
        />
      </div>
      <div style={{ marginTop: '20px', width: '50%' }}>
        <Label htmlFor="categorySelect">Category</Label>
        <Select
          id="categorySelect"
          styles={customSelectStyles}
          value={{ value: category, label: category }}
          onChange={(selectedOption) => setCategory(selectedOption.value)}
          options={procedureCategories?.map((cat) => ({ value: cat, label: cat })) || []}
          placeholder="Select a category"
        />
      </div>
      <div>
        <Label htmlFor="typeInput">Procedure Type</Label>
        <Input
          id="typeInput"
          type="text"
          value={type}
          onChange={(e) => setType(e.target.value)}
          placeholder="Enter procedure type"
        />
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ButtonContainer>
        <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
      </ButtonContainer>
    </div>
  );
};

export default AddModalContent;