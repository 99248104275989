import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from './UserContext';

const ProtectedRoute = ({ allowedRoles }) => {
  const { userRole, isLoading, userEmail } = useUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log("userRole: ", userRole);

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/" replace />;
  }

  if (userRole === 'admin' && userEmail !== 'justin@voyadoc.com') {
    return <Navigate to="/adminlogin" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;