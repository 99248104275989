import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import Pagination from 'components/DemoDashboard/Helpers/AppointmentsTable/Pagination.js';
import ProceduresModal from 'components/DemoDashboard/Helpers/Procedures/ProceduresModal.js';

// Styled components
const Title = styled.h1`
  font-weight: 300;
  margin-top: 0.5em;
  font-size: 1.5em;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const Container = styled.div`
  width: 90%;
  margin: 0% auto;
  padding: 3em 8em 3em 8em;
  overflow-y: auto;
`;

const SearchAndAddContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1em;
`;

const SearchInput = styled.input`
  padding: 0.6em 0.8em;
  font-size: 1.2em;
  border: 1px solid #ccc; // Default border color
  border-radius: 5px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 16em;

  // Focus state styling
  &:focus {
    outline: none; // Removes the default outline to use a custom border color
    border-color: #00C6B6; // Changes the border color to bright turquoise when focused
  }
`;


const AddButton = styled.button`
  padding: 0.6em 1em;
  font-size: 1.3em;
  border: none;
  cursor: pointer;
  background-color: #00C6B6;
  color: #FFFFFF;
  border-radius: 5px;
  transition: background-color 0.3s ease; // Smooth transition for the background color

  &:hover {
    background-color: #008C8A; // Darker shade for hover effect
  }
`;

const StyledTable = styled.table`
  width: 90%;
  max-height: 60vh;
  table-layout: fixed; /* Add this line */
  border-collapse: collapse;
  margin: 1.5em auto;
  font-size: 18px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
`;

const TableHeader = styled.th`
  font-size: 1em;
  font-weight: 500;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  background: #F0F0F0;
  color: #333;
  padding: 1em 1.7em;
  border-bottom: 1.3px solid #ccc;
`;

const CenterAlignedHeader = styled(TableHeader)`
  text-align: center;
`;

const TableRow = styled.tr`
  height: 3.5em;
  background-color: white;
  font-family: 'Poppins', sans-serif;
`;

const TableCell = styled.td`
  max-height: 3.5em;
  padding: 0.5em 0.1em;
  border-bottom: 1.3px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconBase = styled.span`
  cursor: pointer;
  margin: 0 10px;
  vertical-align: middle;
`;

const EditIcon = styled(FaPencilAlt)`
  ${IconBase};
  color: #6E6E6E;
  font-size: 1.1em;
  margin-right: 0.7em;

  &:hover {
    color: #4D4D4D;
    transform: scale(1.2);
  }
`;

const RemoveIcon = styled(FaTrashAlt)`
  ${IconBase};
  color: #FF6347;
  font-size: 1.1em;
  margin-left: 0.7em;

  &:hover {
    color: #C9302C;
    transform: scale(1.2);
  }
`;

const CenterAlignedCell = styled(TableCell)`
  text-align: center;
  vertical-align: middle;
`;

const ProcedureNameCell = styled(TableCell)`
  text-align: left;
  padding-left: 50px;
`;

const TableHeaderActions = styled(TableHeader)`
  text-align: center;
  padding: 15px 0;
`;

const TableHeaderProcedure = styled(TableHeader)`
  padding-left: 50px;
  width: 40%;
`;

const NoProceduresMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #333;
  margin-top: 20vh;
`;


const coreProceduresList = [
  { id: 1, name: "All-on-4 Dental Implants", price: 4000 },
  { id: 2, name: "All-on-6 Dental Implants", price: 6000 },
  { id: 3, name: "Porcelain Veneers", price: 1200 },
  { id: 4, name: "Dental Crown", price: 500 },
  { id: 5, name: "Dental Implant", price: 3000 },
  { id: 6, name: "Dental Bridges", price: 700 },
  { id: 7, name: "Orthodontics", price: 4500 },
  { id: 8, name: "Invisalign", price: 5000 },
  { id: 9, name: "Full Mouth Reconstruction", price: 15000 },
  { id: 10, name: "Laser Teeth Whitening", price: 600 },
  { id: 11, name: "Root Canal Treatment", price: 1000 },
  { id: 12, name: "Gum Surgery", price: 1500 },
  { id: 13, name: "Tooth Extractions", price: 300 },
  { id: 14, name: "Dental Bonding", price: 400 },
  { id: 15, name: "Teeth Cleaning", price: 100 },
  { id: 16, name: "Dental Fillings", price: 200 },
  { id: 17, name: "Dental Sealants", price: 250 },
  { id: 18, name: "Ceramic Braces", price: 4000 },
  { id: 19, name: "Smile Makeover", price: 8000 },
  { id: 20, name: "Gum Contouring", price: 500 },
  { id: 21, name: "Composite Veneers", price: 800 }
];

const ProceduresPage = ({ initialProceduresCount = 8 }) => {
  const [procedures, setProcedures] = useState([]);
  const [availableProcedures, setAvailableProcedures] = useState([]);
  const [nextProcedureId, setNextProcedureId] = useState(Math.max(...coreProceduresList.map(p => p.id)) + 1);


  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState(null);
  const [selectedProcedure, setSelectedProcedure] = useState(null);

  useEffect(() => {
    const selectedProcedures = coreProceduresList.slice(0, initialProceduresCount);
    setProcedures(selectedProcedures);
    setAvailableProcedures(coreProceduresList.filter(proc => !selectedProcedures.includes(proc)));
  }, [initialProceduresCount]);

  const filteredProcedures = procedures.filter(proc =>
    proc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedProcedures = filteredProcedures.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const formatPrice = (amount) => {
    return Number(amount).toFixed(0);
  };

  const openModal = (actionType, procedure = null) => {
    setModalActionType(actionType);
    setSelectedProcedure(procedure);
    setModalOpen(true);
  };

  const handleModalConfirm = (procedureData) => {
    if (modalActionType === 'remove') {
      setProcedures(procedures.filter(proc => proc.id !== procedureData.id));
    } else if (modalActionType === 'edit') {
      setProcedures(procedures.map(proc => proc.id === procedureData.id ? { ...proc, price: procedureData.price } : proc));
    } else if (modalActionType === 'add') {
      const newProcedure = { ...procedureData, id: nextProcedureId };
      setProcedures([...procedures, newProcedure]);
      setAvailableProcedures(availableProcedures.filter(proc => proc.name !== procedureData.name));
      setNextProcedureId(prevId => prevId + 1); // Increment the nextProcedureId
    }
    setModalOpen(false);
  };
  

  return (
    <Container>
      <Title>Procedures</Title>
      <SearchAndAddContainer>
        <SearchInput
          placeholder="Search procedure"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AddButton onClick={() => openModal('add')}>
          Add Procedure
        </AddButton>
      </SearchAndAddContainer>
      {procedures.length > 0 ? (
        <StyledTable>
          <thead>
            <TableRow>
              <TableHeaderProcedure>Procedure Name</TableHeaderProcedure>
              <CenterAlignedHeader>Price</CenterAlignedHeader>
              <TableHeaderActions>Actions</TableHeaderActions>
            </TableRow>
          </thead>
          <tbody>
            {paginatedProcedures.map((procedure) => (
              <TableRow key={procedure.id}>
                <ProcedureNameCell>{procedure.name}</ProcedureNameCell>
                <CenterAlignedCell>${formatPrice(procedure.price)}</CenterAlignedCell>
                <CenterAlignedCell>
                  <EditIcon onClick={() => openModal('edit', procedure)} />
                  <RemoveIcon onClick={() => openModal('remove', procedure)} />
                </CenterAlignedCell>
              </TableRow>
            ))}
          </tbody>
        </StyledTable>
      ) : (
        <NoProceduresMessage>No procedures added yet</NoProceduresMessage>
      )}
      <Pagination
        totalPages={Math.ceil(filteredProcedures.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
      {isModalOpen && (
        <ProceduresModal
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          actionType={modalActionType}
          procedure={selectedProcedure}
          onConfirm={handleModalConfirm}
          availableProcedures={availableProcedures}
        />
      )}
    </Container>
  );
};

export default ProceduresPage;
