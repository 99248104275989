import React from 'react';
import styled from 'styled-components';

import EditModalContent from 'components/AdminDashboard/Helpers/Procedures/EditModalContent.js';
import RemoveModalContent from 'components/AdminDashboard/Helpers/Procedures/RemoveModalContent.js';
import AddModalContent from 'components/AdminDashboard/Helpers/Procedures/AddModalContent.js';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContentContainer = styled.div`
  background-color: #FFF;
  padding: 40px;
  width: 40em;
  max-width: 30%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  position: relative;
`;

const ProceduresModal = ({
  isOpen, closeModal, actionType, procedure, onConfirm, category, setCategory, procedureCategories,
}) => {
  if (!isOpen) return null;

  const renderModalContent = () => {
    switch (actionType) {
      case 'edit':
        return <EditModalContent procedure={procedure} onConfirm={onConfirm}
        category={category}
        setCategory={setCategory}
        procedureCategories={procedureCategories}
      />;
      case 'remove':
        return <RemoveModalContent procedure={procedure} onConfirm={onConfirm} />;
        case 'add':
          return <AddModalContent  onConfirm={onConfirm}
              category={category}
              setCategory={setCategory}
              procedureCategories={procedureCategories}
            />;
      default:
        return null;
    }
  };

  return (
    <ModalBackdrop onClick={closeModal}>
      <ModalContentContainer onClick={e => e.stopPropagation()}>
        {renderModalContent()}
      </ModalContentContainer>
    </ModalBackdrop>
  );
};

export default ProceduresModal;