import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FaTrashAlt } from 'react-icons/fa';
import AddStaff from 'components/ProviderDashboard/Helpers/Information/AddStaff';
import { useUser } from 'UserContext.js';
import axiosInstance from 'utils/axiosInstance';
import LoadingOverlay from 'components/Common/LoadingOverlay';

// Styled components
const StaffInfoWrapper = styled.div`
  padding: 20px;
  background: #FFFFFF; /* Pure white background */
  border-radius: 8px;
  margin-top: 0.4em;
  display: flex;
  flex-direction: column;
`;

const HeaderArea = styled.div`
  display: flex;
  justify-content: flex-end; /* Aligns button to the right */
  background: #FFFFFF; /* Pure white background */
`;

const Table = styled.table`
  width: 100%;
  border: 0.5px solid #ddd;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures the table respects the set widths */
`;

const Th = styled.th`
  text-align: center;
  padding: 8px;
  background: #eee;
  height: 2.5em;
  font-size: 1.5em;
  font-weight: 300;
  border: none;
  width: 20%; /* Equal width for 5 columns */
`;

const Td = styled.td`
  text-align: center; /* Centers the content */
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-size: 1.2em;
  font-weight: 300;
  width: 20%; /* Equal width for 5 columns */
`;


const CircularImage = styled.img`
  width: 3em; /* Adjusted size */
  height: 3em; /* Adjusted size */
  border-radius: 50%;
`;


const AddButton = styled.button`
background: #00C6B6; // Bright turquoise color
color: white;
border: none;
padding: 15px 30px;
border-radius: 50px;
cursor: pointer;
display: inline-flex;
align-items: center;
gap: 10px; // Added gap between icon and text for spacing
font-size: 1.5em;
margin-bottom: 0.7em;

&:hover {
  background: #008C8C; // Slightly darker shade on hover
}

&:disabled {
  background: #A5D6A7;
}
  display: block; // Ensure it takes its own line
  `;

const DeleteIcon = styled(FaTrashAlt)`
  color: #ff0000;
  cursor: pointer;
  font-size: 1.1em; /* Adjusted size */

  &:hover {
    color: #cc0000;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: left;
`;

const Button = styled.button`
  padding: 8px 20px;
  font-size: 16px; /* Reduced font size */
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;

  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }

  ${props => props.no && css`
    background-color: #6c757d;
    &:hover {
      background-color: #545b62;
    }
  `}

  ${props => props.yes && css`
    background-color: #dc3545;
    &:hover {
      background-color: #c82333;
    }
  `}
`;

const ConfirmationOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1500;
`;

const ConfirmationModal = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  ${({ show }) => show && css`
    transform: scale(1);
  `}
`;

const ConfirmationText = styled.p`
  font-size: 1.5em; // Larger font for clarity
  margin-bottom: 20px;
`;

const ScrollableTableBody = styled.div`
  max-height: calc(68vh);
  overflow-y: auto;
`;

const NoStaffMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #666;
  margin-top: 20vh;
`;


const StaffInformation = () => {
  const { user, idToken } = useUser();
  const [staffMembers, setStaffMembers] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const loadStaffMembers = useCallback(async () => {
    setIsLoading(true); // Set isLoading to true before fetching data
  
    if (user && user.uid) {
      try {
        const response = await axiosInstance.get(`/providerstaff/${user.uid}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        setStaffMembers(response.data);
      } catch (error) {
        console.error("Failed to fetch staff members:", error);
      } finally {
        setIsLoading(false); // Set isLoading to false after data is fetched or if there's an error
      }
    } else {
      setIsLoading(false); // Handle case where there is no user
    }
  }, [user, idToken]);

  useEffect(() => {
    loadStaffMembers();
  }, [loadStaffMembers]);

  const handleAddStaffModalClose = () => {
    setShowAddModal(false);
    loadStaffMembers();
  };

  const handleDeleteRequest = (id) => {
    setToBeDeleted(id);
  };

  const confirmDelete = async () => {
    if (toBeDeleted !== null && user && user.uid) {
      try {
        await axiosInstance.delete(`/providerstaff/${user.uid}/${toBeDeleted}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        setStaffMembers(currentMembers => currentMembers.filter(member => member.id !== toBeDeleted));
      } catch (error) {
        console.error("Failed to delete staff member:", error);
      } finally {
        setToBeDeleted(null);
      }
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  return (
    <StaffInfoWrapper>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && (
      <>
      <HeaderArea>
        <AddButton onClick={openAddModal}>
          Add Staff Member
        </AddButton>
      </HeaderArea>
      {staffMembers.length > 0 ? (
        <>
          <Table>
            <thead>
              <tr>
                <Th>Photo</Th>
                <Th>Name</Th>
                <Th>Role</Th>
                <Th>Experience</Th>
                <Th>Action</Th>
              </tr>
            </thead>
          </Table>
          <ScrollableTableBody>
            <Table>
              <tbody>
                {staffMembers.map((member) => (
                  <tr key={member.id}>
                    <Td><CircularImage src={member.photoURL} alt={member.name} /></Td>
                    <Td>{member.name}</Td>
                    <Td>{member.role}</Td>
                    <Td>{member.experience} years</Td>
                    <Td><DeleteIcon onClick={() => handleDeleteRequest(member.id)} /></Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollableTableBody>
        </>
      ) : (
        <NoStaffMessage>No staff added yet</NoStaffMessage>
      )}
      {toBeDeleted && (
        <ConfirmationOverlay>
          <ConfirmationModal show={toBeDeleted !== null}>
            <ConfirmationText>Are you sure you want to delete?</ConfirmationText>
            <ButtonGroup>
              <Button yes onClick={confirmDelete}>Yes</Button>
              <Button no onClick={() => setToBeDeleted(null)}>No</Button>
            </ButtonGroup>
          </ConfirmationModal>
        </ConfirmationOverlay>
      )}
      {showAddModal && (
        <AddStaff onClose={handleAddStaffModalClose} />
      )}
      </>
      )}
    </StaffInfoWrapper>
  );
};

export default StaffInformation;