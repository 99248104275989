import React from 'react';
import styled from 'styled-components';
import StarRating from 'components/ProviderDashboard/Helpers/Reviews/StarRating.js';

const Card = styled.div`
  border: 1px solid #e6e6e6;
  margin: 10px 0;
  padding: 1em;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  height: 15em;
  flex-direction: row; // Set to row to have two main columns
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
`;

const LeftColumn = styled.div`
  width: 15%; // Adjust width as needed
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 1em; // Add some space between the columns
`;

const RightColumn = styled.div`
  width: 85%; // Adjust width as needed
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-size: 1.4em;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
`;

const StarsWrapper = styled.div`
  transform: scale(0.7); // Adjusted scale to normal for better visibility
  margin-bottom: 1em;
  align-self: flex-start; // Align to the left
`;

const ProcedureDate = styled.div`
  font-size: 1.1em;
  margin-bottom: 0.5em; // Ensure consistent spacing
`;

const Comment = styled.div`
  background: rgba(255, 255, 255, 0.95);
  font-size: 1.1em; /* Slightly larger font size for readability */
  line-height: 1.3; /* Increased line height for better legibility */
  font-weight: 400;
  color: #333; /* Softer than black for less glare */
  padding: 1em 2.5em 1em 2.5em; /* More padding for a roomier feel */
  border-radius: 0.3em;
  border: 1px solid #e6e6e6;
  overflow-y: auto;
  height: 100%; /* Take full height of the container */
  max-width: 800px; /* Max width for optimal line length */
  margin: 0 auto; /* Center the comment box if it's not full width */
  white-space: pre-wrap; /* Preserves spaces and line breaks */
  word-wrap: break-word; /* Ensures the text breaks to prevent overflow */

  & p {
    margin-bottom: 1em; /* Adds margin to paragraphs for better separation */
  }

  /* Highlighting important comments */
  & .highlight {
    background-color: #00C6B6;
    color: white;
    padding: 3px 6px;
    border-radius: 3px;
  }
`;


const Highlight = styled.span`
  background-color: #FFEB3B;
`;

const ReviewCard = ({ name, stars, procedure, comment, date, searchTerm }) => {
  const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? <Highlight key={i}>{part}</Highlight> : part
    );
  };

  return (
    <Card>
      <LeftColumn>
        <Name>{highlightText(name, searchTerm)}</Name>
        <ProcedureDate>{highlightText(procedure, searchTerm)}</ProcedureDate>
        <ProcedureDate>{new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</ProcedureDate>
        <StarsWrapper>
          <StarRating rating={stars} />
        </StarsWrapper>
      </LeftColumn>
      <RightColumn>
        <Comment>{highlightText(comment, searchTerm)}</Comment>
      </RightColumn>
    </Card>
  );
};

export default ReviewCard;
