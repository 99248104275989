import React, { useState } from 'react';
import {
  CancelModalBackdrop,
  CancelModalContainer,
  CancelModalHeader,
  CancelModalTitle,
  CancelModalCloseButton,
  CancelModalContent,
  ReasonTextArea,
  CharacterCount,
  CancelModalConfirmButton
} from './CancelModalStyles.js';

const CancelModal = ({ isOpen, onClose, onConfirm }) => {
  const [reason, setReason] = useState('');
  const charLimit = 500;

  const handleConfirmCancel = () => {
    onConfirm(reason);
    onClose(); // Close the modal after confirming the cancellation
  };

  const handleClick = e => {
    e.stopPropagation(); // Prevent click from propagating to parent components
  };

  if (!isOpen) return null;

  return (
    <CancelModalBackdrop onClick={onClose}>
      <CancelModalContainer onClick={handleClick}> {/* Updated this line */}
        <CancelModalHeader>
          <CancelModalTitle>Reason for Canceling Appointment</CancelModalTitle>
          <CancelModalCloseButton onClick={handleClick}>&times;</CancelModalCloseButton> {/* Updated this line */}
        </CancelModalHeader>
        <CancelModalContent>
          <ReasonTextArea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            maxLength={charLimit}
            placeholder="Please provide a reason for cancellation..."
          />
          <CharacterCount>{charLimit - reason.length} characters remaining</CharacterCount>
          <CancelModalConfirmButton onClick={handleConfirmCancel} disabled={!reason.trim()}>
            Confirm Cancellation
          </CancelModalConfirmButton>
        </CancelModalContent>
      </CancelModalContainer>
    </CancelModalBackdrop>
  );
};

export default CancelModal;
