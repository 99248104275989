import React from 'react';
import styled from 'styled-components';

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15vw;
  margin-bottom: 20px;
`;

const FilterTitle = styled.label`
  margin-top: 1.4em;
  font-size: 1.1em;
  font-weight: 300;
  color: #7E7E7E;
  margin-bottom: 4px;
`;

const InputStyle = `
  padding: 0.5em;
  font-size: 0.9em;
  width: 12vw;
  border-radius: 0.6em;
  border: 0.08em solid grey;
  color: black;
`;

const SearchInput = styled.input`
  ${InputStyle}
`;

const FilterSelect = styled.select`
  ${InputStyle}
`;

const DateInput = styled.input.attrs(props => ({
  type: 'date',
}))`
  ${InputStyle}
`;

const ClearButton = styled.button`
  margin-top: 2.3em;
  padding: 0.6em 1em;
  font-size: 1em;
  cursor: pointer;
  width: 70%; 
  ${InputStyle}
  &:hover {
    background-color: #ddd;
  }
`;

const procedures = [
  "All-on-4 Dental Implants",
  "All-on-6 Dental Implants",
  "Porcelain Veneers",
  "Dental Crown",
  "Dental Implant",
  "Dental Bridges",
  "Orthodontics",
  "Invisalign",
  "Full Mouth Reconstruction",
  "Laser Teeth Whitening",
  "Root Canal Treatment",
  "Gum Surgery",
  "Tooth Extractions",
  "Dental Bonding",
  "Teeth Cleaning",
  "Dental Fillings",
  "Dental Sealants",
  "Ceramic Braces",
  "Smile Makeover",
  "Gum Contouring",
  "Composite Veneers"
];


export const SearchAndFilter = ({
  searchTerm, setSearchTerm, 
  statusFilter, setStatusFilter, 
  procedureFilter, setProcedureFilter, 
  startDate, setStartDate, 
  endDate, setEndDate, 
  clearFilters
}) => {
  return (
    <TopSection>
      <FilterTitle>Search</FilterTitle>
      <SearchInput 
        placeholder="Name or Reference ID"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <FilterTitle>Filter by Status</FilterTitle>
      <FilterSelect value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
        <option value="All Statuses">All Statuses</option>
        <option value="Completed">Completed</option>
        <option value="Scheduled">Scheduled</option>
        <option value="Cancelled">Cancelled</option>
      </FilterSelect>

      <FilterTitle>Filter by Procedure</FilterTitle>
      <FilterSelect value={procedureFilter} onChange={e => setProcedureFilter(e.target.value)}>
        <option value="All Procedures">All Procedures</option>
        {procedures.map(proc => <option key={proc} value={proc}>{proc}</option>)}
      </FilterSelect>

      <FilterTitle>Start Date</FilterTitle>
      <DateInput value={startDate} onChange={e => setStartDate(e.target.value)} />
      
      <FilterTitle>End Date</FilterTitle>
      <DateInput value={endDate} onChange={e => setEndDate(e.target.value)} />

      <ClearButton onClick={clearFilters}>Clear Filters</ClearButton>
    </TopSection>
  );
};

export default SearchAndFilter;
