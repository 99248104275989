import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import Pagination from 'components/ProviderDashboard/Helpers/AppointmentsTable/Pagination.js';
import ProceduresModal from 'components/ProviderDashboard/Helpers/Procedures/ProceduresModal.js';
import { HighlightText } from '../Helpers/Procedures/HighlightText';
import axiosInstance from 'utils/axiosInstance';
import { useUser } from 'UserContext.js';
import LoadingOverlay from 'components/Common/LoadingOverlay';


const Container = styled.div`
  width: 90%;
  margin: 2em auto;
  padding: 3em 8em 3em 8em;
  overflow-y: auto;
`;

const SearchAndAddContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1em;
`;

const SearchInput = styled.input`
  padding: 0.6em 0.8em;
  font-size: 1.2em;
  border: 1px solid #ccc; // Default border color
  border-radius: 5px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 16em;

  // Focus state styling
  &:focus {
    outline: none; // Removes the default outline to use a custom border color
    border-color: #00C6B6; // Changes the border color to bright turquoise when focused
  }
`;


const AddButton = styled.button`
  padding: 0.6em 1em;
  font-size: 1.3em;
  border: none;
  cursor: pointer;
  background-color: #00C6B6;
  color: #FFFFFF;
  border-radius: 5px;
  transition: background-color 0.3s ease; // Smooth transition for the background color

  &:hover {
    background-color: #008C8A; // Darker shade for hover effect
  }
`;

const StyledTable = styled.table`
  width: 90%;
  max-height: 60vh;
  table-layout: fixed; /* Add this line */
  border-collapse: collapse;
  margin: 1.5em auto;
  font-size: 18px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
`;

const TableHeader = styled.th`
  font-size: 1em;
  font-weight: 500;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  background: #F0F0F0;
  color: #333;
  padding: 1em 1.7em;
  border-bottom: 1.3px solid #ccc;
`;

const CenterAlignedHeader = styled(TableHeader)`
  text-align: center;
`;

const TableRow = styled.tr`
  height: 3.5em;
  background-color: white;
  font-family: 'Poppins', sans-serif;
`;

const TableCell = styled.td`
  max-height: 3.5em;
  padding: 0.5em 0.1em;
  border-bottom: 1.3px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconBase = styled.span`
  cursor: pointer;
  margin: 0 10px;
  vertical-align: middle;
`;

const EditIcon = styled(FaPencilAlt)`
  ${IconBase};
  color: #6E6E6E;
  font-size: 1.1em;
  margin-right: 0.7em;

  &:hover {
    color: #4D4D4D;
    transform: scale(1.2);
  }
`;

const RemoveIcon = styled(FaTrashAlt)`
  ${IconBase};
  color: #FF6347;
  font-size: 1.1em;
  margin-left: 0.7em;

  &:hover {
    color: #C9302C;
    transform: scale(1.2);
  }
`;

const CenterAlignedCell = styled(TableCell)`
  text-align: center;
  vertical-align: middle;
`;

const ProcedureNameCell = styled(TableCell)`
  text-align: left;
  padding-left: 50px;
`;

const TableHeaderActions = styled(TableHeader)`
  text-align: center;
  padding: 15px 0;
`;

const TableHeaderProcedure = styled(TableHeader)`
  padding-left: 50px;
  width: 40%;
`;

const NoProceduresMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #666;
  margin-top: 20vh;
`;

const ProceduresPage = () => {
  const { user, idToken } = useUser();
  const [procedures, setProcedures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 7;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState('');
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [type, setType] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProviderCategory = async () => {
      try {
        const response = await axiosInstance.get(`/providers/${user.uid}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        const providerData = response.data;
        setCategory(providerData.category);
      } catch (error) {
        console.error('Error fetching provider category:', error);
      }
    };

    fetchProviderCategory();
  }, [user, idToken]);

  const fetchProcedures = useCallback(async () => {
    setIsLoading(true); // Set isLoading to true before fetching data
  
    if (user && user.uid) {
      try {
        const response = await axiosInstance.get(`/providerprocedures/${user.uid}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        const fetchedProcedures = response.data || [];
        setProcedures(fetchedProcedures);
      } catch (error) {
        console.error('Error fetching procedures:', error);
      } finally {
        setIsLoading(false); // Set isLoading to false after data is fetched or if there's an error
      }
    } else {
      setIsLoading(false); // Handle case where there is no user
    }
  }, [user, idToken]);

  useEffect(() => {
    fetchProcedures();
  }, [fetchProcedures]);

  const handleModalConfirm = async (procedureData) => {
    try {
      setIsLoading(true); 
      if (modalActionType === 'add') {
        await axiosInstance.post(`/providerprocedures/${user.uid}`, { procedure: procedureData }, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        await fetchProcedures();
      } else if (modalActionType === 'edit') {
        await axiosInstance.patch(`/providerprocedures/${user.uid}/procedures/${selectedProcedure.id}`, procedureData, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        await fetchProcedures();
      } else if (modalActionType === 'remove') {
        await axiosInstance.delete(`/providerprocedures/${user.uid}/procedures/${selectedProcedure.id}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        setProcedures(prevProcedures => prevProcedures.filter(proc => proc.id !== selectedProcedure.id));
      }
    } catch (error) {
      console.error(`Failed to ${modalActionType} procedure:`, error);
      alert(`Failed to ${modalActionType} the procedure. Please try again.`);
    } finally {
      setIsLoading(false);
      setModalOpen(false);
    }
  };
  
  const filteredProcedures = procedures.filter(proc =>
    proc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedProcedures = filteredProcedures.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const formatPrice = (amount) => {
    return Number(amount).toFixed(0);
  };

  const openModal = (actionType, procedure = null) => {
    setModalActionType(actionType);
    setSelectedProcedure(procedure);
    setModalOpen(true);
  };

  return (
    <Container>
       <LoadingOverlay isLoading={isLoading} />
    {!isLoading && (
      <>
      <SearchAndAddContainer>
        <SearchInput
          placeholder="Search procedure"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AddButton onClick={() => openModal('add')}>
          Add Procedure
        </AddButton>
      </SearchAndAddContainer>
      {procedures.length > 0 ? (
        <StyledTable>
          <thead>
            <TableRow>
              <TableHeaderProcedure>Procedure Name</TableHeaderProcedure>
              <CenterAlignedHeader>Type</CenterAlignedHeader>
              <CenterAlignedHeader>Price</CenterAlignedHeader>
              <TableHeaderActions>Actions</TableHeaderActions>
            </TableRow>
          </thead>
          <tbody>
          {paginatedProcedures.map((procedure) => (
            <TableRow key={procedure.id}>
              <ProcedureNameCell>
                <HighlightText text={procedure.name} searchTerm={searchTerm} />
              </ProcedureNameCell>
              <CenterAlignedCell>{procedure.type || '-'}</CenterAlignedCell>
              <CenterAlignedCell>${formatPrice(procedure.price)}</CenterAlignedCell>
              <CenterAlignedCell>
                <EditIcon onClick={() => openModal('edit', procedure)} />
                <RemoveIcon onClick={() => openModal('remove', procedure)} />
              </CenterAlignedCell>
            </TableRow>
          ))}
          </tbody>
        </StyledTable>
      ) : (
        <NoProceduresMessage>No procedures added yet</NoProceduresMessage>
      )}
      <Pagination
        totalPages={Math.ceil(filteredProcedures.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
      {isModalOpen && (
        <ProceduresModal
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          actionType={modalActionType}
          procedure={selectedProcedure}
          type={type}
          setType={setType}
          typeOptions={typeOptions}
          setTypeOptions={setTypeOptions}
          onConfirm={handleModalConfirm}
          category={category}
          user={user}
        />
      )}
      </>
    )}
    </Container>
  );
};

export default ProceduresPage;