import React from 'react';
import styled from 'styled-components';

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25em 0em;
`;

const PageNumber = styled.span`
  margin: 0em 0.45em;
  font-size: 0.9em;
  padding: 0.6em 0.9em;
  border-radius: 35%;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  ${props => props.active && `
    background-color: #00C6B6;
    color: white;
  `}
`;

const Arrow = styled.span`
  font-size: 1.3em;
  margin: 0em 0.45em;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  
  &:hover {
    color: #00C6B6;
  }
`;

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    return (
      <PaginationWrapper>
        {currentPage > 1 && <Arrow onClick={() => onPageChange(currentPage - 1)}>&lt;</Arrow>}
        
        {startPage > 1 && <PageNumber onClick={() => onPageChange(1)}>1</PageNumber>}
        {startPage > 2 && <span>...</span>}

        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
          <PageNumber 
            key={page}
            active={page === currentPage}
            onClick={() => onPageChange(page)}
          >
            {page}
          </PageNumber>
        ))}

        {endPage < totalPages - 1 && <span>...</span>}
        {endPage < totalPages && <PageNumber onClick={() => onPageChange(totalPages)}>{totalPages}</PageNumber>}
        
        {currentPage < totalPages && <Arrow onClick={() => onPageChange(currentPage + 1)}>&gt;</Arrow>}
      </PaginationWrapper>    
    );
}

export default Pagination;
