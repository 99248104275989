import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import Hours from './Hours';
import Select from 'react-select';
import { FaArrowLeft /* other icons */ } from 'react-icons/fa';
import axiosInstance from 'utils/axiosInstance';
import { useUser } from 'UserContext.js';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import LoadingOverlay from 'components/Common/LoadingOverlay.js'


// Define a color scheme
const colors = {
  main: '#5A6268', // A professional blue-gray color
  background: '#f2f2f2',
  button: '#6c757d', // A muted button color
  buttonHover: '#5a6268',
  focusBorder: '#00C6B6', // A color for the focus state
  text: '#495057', // Dark gray for text for better readability
  textSoft: '#495057', // Soft color for input text
};

const RenderEditModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure the container takes full width
`;

const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em; // Space between the top bar and the form
`;

const BackButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.main}; // Use color from your color scheme
  font-size: 2.1em; // Adjust size as needed
  padding: 10px;
  margin-left: 20px; // Give some space from the container edge
  transition: color 0.3s, transform 0.3s;

  &:hover {
    color: ${colors.buttonHover};
    transform: translateX(-5px); // Move slightly to the left on hover
  }

  &:focus {
    outline: none;
  }
`;


const FormSection = styled.div`
  display: flex;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: 500;
`;

const Column = styled.div`
  padding: 0 2em;
  flex: 0 0 30%; // This sets the base width to 40% and prevents it from growing or shrinking
  &:first-child {
    margin-right: 0.7em;
  }
`;

const RightColumn = styled.div`
  padding: 0 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0 60%; // This sets the base width to 60% and prevents it from growing or shrinking
  width: 60%; // This explicitly sets the width to 60% to ensure layout consistency
`;


const HoursContainer = styled.div`
  // No need for top margin adjustment if you want it at the top
`;

const SaveButton = styled.button`
 background: ${props => (props.allFieldsValid ? '#00c6b6' : '#6c757d')}; // Turquoise when valid
  color: white;
  border: none;
  padding: 0.4em 0.8em;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.7em;
  font-weight: 400;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${props => (props.allFieldsValid ? '#009c9a' : '#5a6268')}; // Darker turquoise on hover
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; // Aligns the Save button to the right
  margin-top: auto; // Pushes the Save button to the bottom
  margin-bottom: 0px; // Gives some space from the bottom
`;


const Label = styled.label`
  display: block;
  font-size: 1.5em; // Increased font size for better readability
  font-weight: 300;
  color: #333333;
  margin-top: 0.2em;
  margin-bottom: 0.1em;
`;

const RequiredAsterisk = styled.span`
  color: ${props => (props.isValid ? 'transparent' : 'red')};
  font-size: 30px;
  font-weight: 300;
  margin-left: 2px;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.7em 0.8em;
  margin-bottom: 0.6em;
  border-radius: 4px;
  border: 1px solid ${colors.main};
  background: ${colors.background};
  box-sizing: border-box;
  font-size: 1.3em;
  font-weight: 300;
  color: ${colors.textSoft};
  &:focus {
    border-color: ${colors.focusBorder}; // Updated to the new color
    outline: none;
  }
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#ffffff',
    fontSize: '1.2em', // Increase font size
    border: `1px solid ${colors.main}`,
    boxShadow: 'none',
    minHeight: '3em', // Increase height of the select box
    marginBottom: '0.8em',
    '&:hover': {
      border: `1px solid ${colors.focusBorder}`,
    },
    '&:focus': {
      borderColor: `${colors.focusBorder}`,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.5em 16px', // Increase padding for better readability
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#e0e0e0',
    color: colors.text,
    fontSize: '1.3em', // Increase font size of selected options
    fontWeight: '400', // Slightly bolder font weight
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: colors.text,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#c6c6c6',
      color: '#000000',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.textSoft,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: colors.textSoft,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.3em', // Increase font size of placeholder
    fontWeight: '400',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '1.3em', // Increase font size of options
    fontWeight: '400',
    backgroundColor: state.isSelected ? colors.focusBorder : 'white',
    color: state.isSelected ? 'white' : colors.textSoft,
    '&:hover': {
      backgroundColor: colors.focusBorder,
      color: 'white',
    },
  }),
};

const PhoneInputContainer = styled.div`
  .PhoneInput {
    display: flex;
    align-items: center;
    background: ${colors.background};
    border: 1px solid ${colors.main};
    border-radius: 4px;
    padding: 0.7em 0.8em;
    margin-bottom: 0.6em;
    font-size: 1.3em;
    font-weight: 300;
    color: ${colors.textSoft};
    &:focus-within {
      border-color: ${colors.focusBorder};
      outline: none;
    }
  }
  .PhoneInputCountry {
    margin-right: 0.5em;
  }
  .PhoneInputInput {
    flex: 1;
    min-width: 0;
    border: none;
    background: transparent;
    &:focus {
      outline: none;
    }
  }
`;

const RenderEditMode = ({ setEditMode }) => {
  const defaultHours = {
    Monday: { closed: true },
    Tuesday: { closed: true },
    Wednesday: { closed: true },
    Thursday: { closed: true },
    Friday: { closed: true },
    Saturday: { closed: true },
    Sunday: { closed: true },
  };

  const [info, setInfo] = useState({
    name: '',
    country: '',
    city: '',
    email: '',
    phone: '',
    yearOpened: '',
    certifications: [],
    paymentOptions: [],
    services: [],
    hours: defaultHours,
  });
  const [validFields, setValidFields] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(info.phoneNumber || '');
  const [isLoading, setIsLoading] = useState(true);
  const { user, idToken } = useUser();

  const countries = ['Mexico'];
  const cities = ['Cancun', 'Tijuana', 'Mexico City', 'Los Algodones', 'Cabo San Lucas'];


  useEffect(() => {
    validateFields(info);
    // eslint-disable-next-line
  }, [info, phoneNumber]);

  useEffect(() => {
    const fetchProviderInfo = async () => {
      if (user && user.uid && idToken) {
        try {
          const response = await axiosInstance.get(`/providerinfo/${user.uid}`, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
          setInfo(response.data || {});
          setPhoneNumber(response.data?.phoneNumber || '');
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching provider information:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchProviderInfo();
  }, [user, idToken]);

  useEffect(() => {
    validateFields(info);
    // eslint-disable-next-line
  }, [info]);

  const handleSave = async () => {
    if (!user) {
      alert("You must be logged in to save.");
      return;
    }
  
    if (!isValidPhoneNumber(phoneNumber)) {
      alert("Please enter a valid phone number.");
      return;
    }
  
    if (!allFieldsValid) {
      alert("Please ensure all fields are correctly filled.");
      return;
    }
  
    try {
      console.log("phoneNumber before sending to server:", phoneNumber);
      const response = await axiosInstance.post('/providerinfo', {
        uid: user.uid,
        providerInfo: {
          ...info,
          phoneNumber: phoneNumber,
        },
      }, {
        headers: { Authorization: `Bearer ${idToken}` }, // Use idToken from UserContext
      });
      if (response.status === 200) {
        console.log("Provider information saved successfully");
        setEditMode(false);
      } else {
        throw new Error('Save operation was not successful.');
      }
    } catch (error) {
      console.error("Failed to save information:", error);
    }
  };
  
  const validateFields = (info) => {
    const hoursValid = Object.values(info.hours).every(day => day.closed || (day.open && day.close));
    const phoneValid = isValidPhoneNumber(phoneNumber);
    const newValidFields = {
      name: !!info.name,
      country: !!info.country,
      city: !!info.city,
      email: !!info.email,
      phone: phoneValid,
      yearOpened: !!info.yearOpened,
      paymentOptions: info.paymentOptions.length > 0,
      hours: hoursValid,
    };
    setValidFields(newValidFields);
  };

  const handleChange = (event, day, field) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox" && day) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        hours: {
          ...prevInfo.hours,
          [day]: {
            ...prevInfo.hours[day] || {},
            [field]: checked,
          },
        },
      }));
    } else if (day) {
      if (!info.hours[day]) {
        setInfo((prevInfo) => ({
          ...prevInfo,
          hours: {
            ...prevInfo.hours,
            [day]: { open: '', close: '' },
          },
        }));
      }

      setInfo((prevInfo) => ({
        ...prevInfo,
        hours: {
          ...prevInfo.hours,
          [day]: {
            ...prevInfo.hours[day],
            [field]: value,
          },
        },
      }));
    } else {
      setInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
    validateFields({ ...info, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value || '');
  };
  
  const allFieldsValid = Object.values(validFields).every(Boolean);

  const yearOptions = Array.from({ length: 125 }, (_, index) => 2024 - index);
  // eslint-disable-next-line
  const certifications = ['None', 'Joint Commission International', 'ISO'];
  const paymentOptions = ['Cash', 'Check', 'Credit Card', 'Wire Transfer'];
  // eslint-disable-next-line
  const services = ['None', 'Translation', 'Airport to Hotel Shuttle', 'Hotel to Clinic Transfer'];

  return (
    <RenderEditModeContainer>
      <LoadingOverlay isLoading={isLoading} />
      {!isLoading && 
      <>
      <TopBarContainer>
        <BackButton onClick={() => setEditMode(false)}>
          <FaArrowLeft />
        </BackButton>
      </TopBarContainer>
      <FormSection>
        <Column>
          {/* Name of Facility */}
          <Label>
            Name of Facility
            <RequiredAsterisk isValid={validFields.name}>*</RequiredAsterisk>
          </Label>
          <Input
            name="name"
            value={info.name}
            onChange={handleChange}
            placeholder="Enter facility name"
          />
  
          {/* Country */}
          <Label>
              Country
              <RequiredAsterisk isValid={validFields.country}>*</RequiredAsterisk>
            </Label>
            <Select
              name="country"
              value={{ label: info.country, value: info.country }}
              options={countries.map(country => ({ value: country, label: country }))}
              styles={customStyles}
              onChange={(selectedOption) => {
                setInfo(prevInfo => ({
                  ...prevInfo,
                  country: selectedOption ? selectedOption.value : '',
                }));
              }}
            />

            {/* City */}
            <Label>
              City
              <RequiredAsterisk isValid={validFields.city}>*</RequiredAsterisk>
            </Label>
            <Select
              name="city"
              value={{ label: info.city, value: info.city }}
              options={cities.map(city => ({ value: city, label: city }))}
              styles={customStyles}
              onChange={(selectedOption) => {
                setInfo(prevInfo => ({
                  ...prevInfo,
                  city: selectedOption ? selectedOption.value : '',
                }));
              }}
            />

          {/* Email */}
          <Label>
            Contact Email
            <RequiredAsterisk isValid={validFields.email}>*</RequiredAsterisk>
          </Label>
          <Input
            name="email"
            value={info.email}
            onChange={handleChange}
            placeholder="Enter email for patients"
          />
  
          {/* Phone Number */}
          <Label>
            Phone Number
            <RequiredAsterisk isValid={validFields.phone}>*</RequiredAsterisk>
          </Label>
          <PhoneInputContainer>
            <PhoneInput
              value={phoneNumber}
              onChange={handlePhoneChange}
              defaultCountry="US"
              placeholder="Enter phone number"
            />
          </PhoneInputContainer>
  
          {/* Year Opened */}
          <Label>
            Year Opened
            <RequiredAsterisk isValid={validFields.yearOpened}>*</RequiredAsterisk>
          </Label>
          <Select
            name="yearOpened"
            value={{ label: info.yearOpened, value: info.yearOpened }} // Adjust this line
            options={yearOptions.map(year => ({ value: year, label: year.toString() }))}
            styles={customStyles}
            onChange={(selectedOption) => {
              setInfo(prevInfo => ({
                ...prevInfo,
                yearOpened: selectedOption.value,
              }));
            }}
          />
  
          {/* Payment Options */}
          <Label>
            Payment Options
            <RequiredAsterisk isValid={validFields.paymentOptions}>*</RequiredAsterisk>
          </Label>
          <Select
            isMulti
            name="paymentOptions"
            value={info.paymentOptions.map(option => ({ label: option, value: option }))}
            options={paymentOptions.map(option => ({ label: option, value: option }))}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            onChange={(selectedOptions) => {
              setInfo(prevInfo => ({
                ...prevInfo,
                paymentOptions: selectedOptions.map(option => option.value),
              }));
            }}
          />
         
          {/* Certifications of Facility */}
          {/* <Label>
            Certifications of Facility
            <RequiredAsterisk isValid={validFields.certifications}>*</RequiredAsterisk>
          </Label>
          <Select
            isMulti
            name="certifications"
            value={info.certifications.map(cert => ({ label: cert, value: cert }))}
            options={certifications.map(cert => ({ value: cert, label: cert }))}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            onChange={(selectedOptions) => {
              setInfo(prevInfo => ({
                ...prevInfo,
                certifications: selectedOptions.map(option => option.value),
              }));
            }}
          /> */}
          {/* ... (existing code) */}
          {/* Services Offered */}
          {/* <Label>
            Services Offered
            <RequiredAsterisk isValid={validFields.services}>*</RequiredAsterisk>
          </Label>
          <Select
            isMulti
            name="services"
            value={info.services.map(service => ({ label: service, value: service }))}
            options={services.map(service => ({ label: service, value: service }))}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={customStyles}
            onChange={(selectedOptions) => {
              setInfo(prevInfo => ({
                ...prevInfo,
                services: selectedOptions.map(option => option.value),
              }));
            }}
          /> */}
        </Column>
  
        <RightColumn>
          {/* Hours of Operation at the top */}
          <HoursContainer>
            <Label>
              Hours of Operation
              <RequiredAsterisk isValid={validFields.hours}>*</RequiredAsterisk>
            </Label>
            <Hours
              hours={info.hours}
              handleChange={handleChange}
            />
          </HoursContainer>

          {/* Spacer to push the Save button to the bottom */}
          <div />

          {/* Save button at the bottom */}
          <SaveButtonContainer>
            <SaveButton
              onClick={handleSave}
              disabled={!allFieldsValid}
              allFieldsValid={allFieldsValid}
            >
              Save
            </SaveButton>
          </SaveButtonContainer>
        </RightColumn>
      </FormSection>
      </>
    }
    </RenderEditModeContainer>
  );
};

export default RenderEditMode;
