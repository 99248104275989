import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaTimes, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import StaffPhoto from './StaffPhoto';
import { useUser } from 'UserContext.js';
import axiosInstance from 'utils/axiosInstance';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from 'firebase.js';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  width: 50%;
  max-width: 700px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.1);
  position: relative;
`;

const ModalPhoto = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const PhotoPreview = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const PhotoUploadButton = styled.button`
  background-color: #00c6b6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;

  &:hover {
    background-color: #008c8a;
  }
`;

const CloseButton = styled(FaTimes)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5em;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: 300;
  font-size: 1.5em;
  display: flex;
  align-items: center;
`;


const Input = styled.input`
  padding: 10px;
  margin-bottom: 0.6em;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%; /* Makes input boxes wider */
`;

const EducationTitle = styled.h4`
  font-weight: 300;
  font-size: 1.5em;
  margin-top: 0; /* Removes awkward space */
`;

const EducationSection = styled.div`
  display: flex;
  gap: 10px; /* Adds gap between input boxes */
`;

const AddButton = styled(FaPlusCircle)`
  cursor: pointer;
  margin-top: 10px;
`;

const DeleteIcon = styled(FaTrashAlt)`
  cursor: pointer;
  font-size: 3em;
  color: #dc3545; /* Tasteful shade of red */
  &:hover {
    color: #c82333; /* Darker shade on hover */
  }
`;

const DeleteImageText = styled.div`
  color: #dc3545; /* Tasteful shade of red */
  text-align: center;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: #c82333; /* Darker shade on hover */
  }
`;

const Dropdown = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 16px;
  margin-bottom: 0.6em; /* Adjusted to remove awkward space */
`;

const SubmitButton = styled.input`
  background: ${props => (props.allFieldsValid ? '#00c6b6' : '#6c757d')};
  color: white;
  border: none;
  padding: 0.4em 0.8em;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.4em;
  font-weight: 400;
  margin-top: 30px;
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background: ${props => (props.allFieldsValid ? '#008c8a' : '#5a6268')};
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const Asterisk = styled.span`
  color: red;
  font-size: 1.2em;
  margin-left: 5px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const FileInput = styled.input`
  display: none;
`;

const AddStaff = ({ onClose }) => {
  const { user, idToken } = useUser();
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('0');
  const [educations, setEducations] = useState([{ id: 1, institution: '', degree: '' }]);
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCroppingModalVisible, setIsCroppingModalVisible] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [imageSrc, setImageSrc] = useState(null);

  const onSelectFile = useCallback((event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setImageSrc(reader.result);
        setIsCroppingModalVisible(true);
      };
      setInputKey(Date.now());
    }
  }, []);

  useEffect(() => {
    const isValidName = name.trim() !== '';
    const isValidRole = role.trim() !== '';
    const isValidExperience = yearsOfExperience.trim() !== '' && !isNaN(yearsOfExperience) && parseInt(yearsOfExperience, 10) >= 0;
    const isValidEducations = educations.every(e => e.institution.trim() !== '' && e.degree.trim() !== '');

    const allValid = isValidName && isValidRole && isValidExperience && isValidEducations && croppedImage;
    setAllFieldsValid(allValid);
  }, [name, role, yearsOfExperience, educations, croppedImage]);


  const handleAddEducation = () => {
    const newId = educations.length ? Math.max(...educations.map(e => e.id)) + 1 : 1;
    setEducations([...educations, { id: newId, institution: '', degree: '' }]);
  };

  const handleRemoveEducation = (id) => {
    setEducations(educations.filter(e => e.id !== id));
  };

  const handleEducationChange = (id, field, value) => {
    setEducations(educations.map(edu => edu.id === id ? { ...edu, [field]: value } : edu));
  };

  const handlePhotoCropped = (croppedImg) => {
    setCroppedImage(croppedImg);
  };

  const handleDeleteCroppedImage = () => {
    setCroppedImage(null);
  };

  const handleModalClose = () => {
    setCroppedImage(null);
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (allFieldsValid && user && user.uid) {
      try {
        const fileRef = ref(storage, `providerStaff/${user.uid}/${Date.now()}-${croppedImage.name}`);
        await uploadBytes(fileRef, croppedImage);
        const photoURL = await getDownloadURL(fileRef);
  
        // Filter out any invalid education objects
        const validEducations = educations.filter(edu => edu.institution.trim() !== '' && edu.degree.trim() !== '');
  
        const newStaffMember = {
          name,
          role,
          experience: yearsOfExperience,
          educations: validEducations,
          photoURL,
        };
  
        await axiosInstance.post(`/providerstaff/${user.uid}`, newStaffMember, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
  
        onClose();
      } catch (error) {
        console.error("Error adding staff member:", error);
      }
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={handleModalClose} />
        <Form onSubmit={handleSubmit}>
        <Label htmlFor="photo">Photo</Label>
            <ModalPhoto>
              {croppedImage ? (
                <PhotoPreview src={URL.createObjectURL(croppedImage)} alt="Staff Photo" />
              ) : (
                <>
                  <PhotoUploadButton as="label" htmlFor="file-input">
                    Upload Photo
                  </PhotoUploadButton>
                  <FileInput id="file-input" type="file" accept="image/*" onChange={onSelectFile} key={inputKey} />
                </>
              )}
              {croppedImage && (
                <DeleteImageText onClick={handleDeleteCroppedImage}>
                  Delete Image
                </DeleteImageText>
              )}
            </ModalPhoto>
          <Label htmlFor="name">Name<Asterisk visible={!name.trim()}>*</Asterisk></Label>
          <Input type="text" id="name" value={name} onChange={e => setName(e.target.value)} required />
          <Label htmlFor="role">Role<Asterisk visible={!role.trim()}>*</Asterisk></Label>
          <Input type="text" id="role" value={role} onChange={e => setRole(e.target.value)} required />
          <Label htmlFor="experience">Years of Experience<Asterisk visible={!yearsOfExperience.trim() || isNaN(yearsOfExperience) || parseInt(yearsOfExperience, 10) < 0}>*</Asterisk></Label>
          <Dropdown id="experience" value={yearsOfExperience} onChange={e => setYearsOfExperience(e.target.value)}>
            {[...Array(71).keys()].map(year => <option key={year} value={year}>{year}</option>)}
          </Dropdown>
          <EducationTitle>Education</EducationTitle>
          {educations.map((education, index) => (
            <EducationSection key={education.id}>
              <Input type="text" value={education.institution} placeholder="Institution" onChange={e => handleEducationChange(education.id, 'institution', e.target.value)} />
              <Input type="text" value={education.degree} placeholder="Degree" onChange={e => handleEducationChange(education.id, 'degree', e.target.value)} />
              <DeleteIcon onClick={() => handleRemoveEducation(education.id)} />
            </EducationSection>
          ))}
          <AddButton onClick={handleAddEducation} />
          <SubmitButton type="submit" value="Add Staff Member" allFieldsValid={allFieldsValid} />
          </Form>
      </ModalContent>
      {isCroppingModalVisible && (
        <StaffPhoto
          imageSrc={imageSrc}
          onPhotoCropped={handlePhotoCropped}
          onClose={() => setIsCroppingModalVisible(false)}
        />
      )}
    </ModalOverlay>
  );
};

export default AddStaff;
