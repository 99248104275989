import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC_wxoHoDEGxQIjuw_N36EGhFfN03RhLsk",
  authDomain: "voyadoc.firebaseapp.com",
  projectId: "voyadoc",
  storageBucket: "voyadoc.appspot.com",
  messagingSenderId: "543834856209",
  appId: "1:543834856209:web:e77cc47699c5f659680ab0",
  measurementId: "G-XGSH4EW1J1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, auth, storage };