import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'firebase.js';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from 'assets/Logo.png';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from 'UserContext.js';
import 'react-toastify/dist/ReactToastify.css';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LogoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
`;

const LogoImage = styled.img`
  width: 4em; // Adjust width as needed
  height: auto;
`;

const LogoText = styled.span`
  font-size: 3.7em;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
`;

const Title = styled.h1`
  color: #B0B0B0;
  font-weight:400;
  font-size: 1.6em;
  margin-bottom: 1.5rem;
`;

const StyledInput = styled.input`
  font-size: 1em;
  padding: 10px;
  margin-bottom: 1rem;
  width: 300px;
  border: 2px solid #B0B0B0; // A classy dark grey
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out; // Smooth transition for hover effect

  &:focus, &:hover {
    border-color: #00C6B6; // Turquoise tone on hover/focus
    outline: none; // Remove default focus outline
  }
`;

const Button = styled.button`
  background-color: #00C6B6; // Accent color
  color: white;
  font-size: 1em;
  padding: 10px 20px;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 320px;

  &:hover {
    background-color: #008C7A; // Darker shade for hover effect
  }
`;

const SwitchModeText = styled.p`
  color: #00C6B6; // Accent color
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ReturnToLoginText = styled.p`
  color: #00C6B6;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();
  const { updateIdToken, updateUserRole, updateUserEmail } = useUser();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken();
      updateIdToken(idToken);
      updateUserRole('admin');
      updateUserEmail(email);
      toast.success('Logged in successfully!');
      navigate('/admindashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      handleAuthenticationError(error);
    }
  };

  const handleAuthenticationError = (error) => {
    if (error.code === 'auth/user-not-found') {
      toast.error('User not found. Please check your email.');
    } else if (error.code === 'auth/wrong-password') {
      toast.error('Incorrect password. Please try again.');
    } else {
      toast.error('An error occurred during login. Please try again later.');
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleSendResetEmail = async () => {
    try {
      console.log("Sending password reset email to:", email);
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent!");
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Failed to send password reset email:", error);
      toast.error("Failed to send password reset email. Please try again.");
    }
  };

  const handleReturnToLogin = () => {
    setIsForgotPassword(false);
  };

  return (
    <Container>
      <ToastContainer position="top-center" autoClose={5000} />
      <LogoPlaceholder>
        <LogoImage src={logo} alt="VoyaDoc Logo" />
        <LogoText>VoyaDoc</LogoText>
      </LogoPlaceholder>
      <Title>{isForgotPassword ? "Reset Password" : "Admin Login"}</Title>

      {isForgotPassword ? (
        <>
          <StyledInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <Button onClick={handleSendResetEmail}>Recover Password</Button>
          <ReturnToLoginText onClick={handleReturnToLogin}>
            Return to Login
          </ReturnToLoginText>
        </>
      ) : (
        <>
          <StyledInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <StyledInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <Button onClick={handleLogin}>Login</Button>

          <SwitchModeText onClick={handleForgotPassword}>
            Forgot Password?
          </SwitchModeText>
        </>
      )}
    </Container>
  );
}

export default AdminLogin;