import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'firebase.js';
import axiosInstance from 'utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from 'UserContext.js';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import logo from 'assets/Logo.png';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LogoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 4.2em;
  height: auto;
  margin-right: 0.4em;
`;

const LogoText = styled.span`
  font-size: 3.7em;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
`;

const ProvidersText = styled.span`
  font-size: 1.3em;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-left: 11em;
  margin-top: -0.7em;
  margin-bottom: 0em;
`;


const Title = styled.h1`
  color: #B0B0B0;
  font-weight:400;
  font-size: 1.6em;
  margin-bottom: 1.5rem;
`;

const StyledInput = styled.input`
  font-size: 1em;
  padding: 10px;
  margin-bottom: 1rem;
  width: 300px;
  border: 2px solid #B0B0B0; // A classy dark grey
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out; // Smooth transition for hover effect

  &:focus, &:hover {
    border-color: #00C6B6; // Turquoise tone on hover/focus
    outline: none; // Remove default focus outline
  }
`;

const Button = styled.button`
  background-color: #00C6B6; // Accent color
  color: white;
  font-size: 1em;
  padding: 10px 20px;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 320px;

  &:hover {
    background-color: #008C7A; // Darker shade for hover effect
  }
`;

const SwitchModeText = styled.p`
  color: #00C6B6; // Accent color
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ReturnToLoginText = styled.p`
  color: #00C6B6;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

function ProviderLogin() {
  const [email, setEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [isCodeValidated, setIsCodeValidated] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { updateIdToken, updateUserRole, updateUserEmail } = useUser();
  const navigate = useNavigate();

  const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateAccessCodeAndEmail = async () => {
    if (!email || !accessCode) {
      toast.error("Please fill in both email and access code.");
      return;
    }
    if (!emailIsValid(email)) {
      toast.error("Invalid email format.");
      return;
    }

    try {
      const response = await axiosInstance.post('/providers/validateAccessCode', { email, accessCode });
      if (response.status === 200) {
        setIsCodeValidated(true);
      } else {
        toast.error("Invalid access code for the provided email.");
      }
    } catch (error) {
      console.error('Error validating access code:', error);
      toast.error("Failed to validate access code. Please try again.");
    }
  };

  const handleSignUp = async () => {
    if (!password) {
      toast.error("Please set a password.");
      return;
    }
    try {
      const response = await axiosInstance.post('/providers/signup', { email, accessCode, password });
      if (response.status === 200) {
        toast.success("Registered successfully!");
  
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const idToken = await userCredential.user.getIdToken();
          updateIdToken(idToken);
          updateUserRole('provider');
          updateUserEmail(email);
          navigate('/providerdashboard');
        } catch (error) {
          console.error('Error logging in after registration:', error);
          toast.error("Failed to log in automatically. Please log in manually.");
          navigate('/providerlogin');
        }
      } else {
        toast.error("Failed to register. Please try again.");
      }
    } catch (error) {
      console.error('Error signing up:', error);
      toast.error("Failed to register. Please try again.");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken();
      updateIdToken(idToken);
      updateUserRole('provider'); // Set the user role to 'provider'
      updateUserEmail(email); // Store the user's email in the user context
      toast.success('Logged in successfully!');
      navigate('/providerdashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      if (error.code === 'auth/user-not-found') {
        toast.error('User not found. Please check your email.');
      } else if (error.code === 'auth/wrong-password') {
        toast.error('Incorrect password. Please try again.');
      } else {
        toast.error('An error occurred during login. Please try again later.');
      }
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleSendResetEmail = async () => {
    try {
      console.log("Sending password reset email to:", email);
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent!");
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Failed to send password reset email:", error);
      toast.error("Failed to send password reset email. Please try again.");
    }
  };

  const handleReturnToLogin = () => {
    setIsRegistering(false);
    setIsCodeValidated(false);
    setIsForgotPassword(false);
  };

  return (
    <Container>
      <ToastContainer position="top-center" autoClose={5000} />
      <LogoPlaceholder>
        <LogoWrapper>
          <LogoImage src={logo} alt="VoyaDoc Logo" />
          <LogoText>VoyaDoc</LogoText>
        </LogoWrapper>
        <ProvidersText>Providers</ProvidersText>
      </LogoPlaceholder>
      <Title>
        {isForgotPassword
          ? "Reset Password"
          : isRegistering
          ? isCodeValidated
            ? "Set Password"
            : "Verify Email and Access Code"
          : "Login"}
      </Title>

      {isForgotPassword ? (
        <>
          <StyledInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <Button onClick={handleSendResetEmail}>Recover Password</Button>
          <ReturnToLoginText onClick={handleReturnToLogin}>
            Return to Login
          </ReturnToLoginText>
        </>
      ) : (
        <>
          {isRegistering && !isCodeValidated && (
            <>
              <StyledInput
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <StyledInput
                type="text"
                value={accessCode}
                onChange={(e) => setAccessCode(e.target.value)}
                placeholder="Access Code"
              />
              <Button onClick={validateAccessCodeAndEmail}>
                Verify Access Code
              </Button>
            </>
          )}

          {(!isRegistering || isCodeValidated) && (
            <>
              <StyledInput
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                style={{ display: isRegistering ? "none" : "block" }}
              />
              <StyledInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <Button onClick={isRegistering ? handleSignUp : handleLogin}>
                {isRegistering ? "Confirm Password" : "Login"}
              </Button>
            </>
          )}

          {!isCodeValidated && (
            <>
              <SwitchModeText onClick={() => setIsRegistering(!isRegistering)}>
                {isRegistering ? "Switch to Login" : "Switch to Sign Up"}
              </SwitchModeText>
              {!isRegistering && (
                <SwitchModeText onClick={handleForgotPassword}>
                  Forgot Password?
                </SwitchModeText>
              )}
            </>
          )}

          {isRegistering && isCodeValidated && (
            <ReturnToLoginText onClick={handleReturnToLogin}>
              Return to Login
            </ReturnToLoginText>
          )}
        </>
      )}
    </Container>
  );
}

export default ProviderLogin;