import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaTrashAlt, FaPauseCircle, FaPlayCircle } from 'react-icons/fa';
import { HighlightText } from '../Helpers/Providers/HighlightText';
import Pagination from 'components/AdminDashboard/Helpers/AppointmentsTable/Pagination.js';
import ProvidersModal from 'components/AdminDashboard/Helpers/Providers/ProvidersModal.js';
import axiosInstance from 'utils/axiosInstance';
import { useUser } from 'UserContext.js';

const Container = styled.div`
  width: 90%;
  margin: 2em auto;
  padding: 3em 8em 3em 8em;
  overflow-y: auto;
`;

const SearchAndAddContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1em;
`;

const SearchInput = styled.input`
  padding: 0.6em 0.8em;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 16em;

  &:focus {
    outline: none;
    border-color: #00C6B6;
  }
`;

const AddButton = styled.button`
  padding: 0.6em 1em;
  font-size: 1.3em;
  border: none;
  cursor: pointer;
  background-color: #00C6B6;
  color: #FFFFFF;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #008C8A;
  }
`;

const StyledTable = styled.table`
  width: 90%;
  max-height: 60vh;
  table-layout: fixed; /* Add this line */
  border-collapse: collapse;
  margin: 1.5em auto;
  font-size: 18px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
`;

const TableHeader = styled.th`
  font-size: 1em;
  font-weight: 500;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  background: #F0F0F0;
  color: #333;
  padding: 1em 1.7em;
  border-bottom: 1.3px solid #ccc;
`;

const CenterAlignedHeader = styled(TableHeader)`
  text-align: center;
`;

const TableRow = styled.tr`
  height: 3.5em;
  background-color: white;
  font-family: 'Poppins', sans-serif;
`;

const TableCell = styled.td`
  max-height: 3.5em;
  padding: 0.5em 0.1em;
  border-bottom: 1.3px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconBase = styled.span`
  cursor: pointer;
  margin: 0 10px;
  vertical-align: middle;
`;

const RemoveIcon = styled(FaTrashAlt)`
  ${IconBase};
  color: #FF6347;
  font-size: 1.1em;
  margin-left: 0.7em;

  &:hover {
    color: #C9302C;
    transform: scale(1.2);
  }
`;

const CenterAlignedCell = styled(TableCell)`
  text-align: center;
  vertical-align: middle;
`;

const EmailCell = styled(TableCell)`
  text-align: left;
  padding-left: 50px;
`;

const TableHeaderActions = styled(TableHeader)`
  text-align: center;
  padding: 15px 0;
`;

const TableHeaderEmail = styled(TableHeader)`
  padding-left: 50px;
  width: 30%;
`;

const NoProvidersMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #666;
  margin-top: 20vh;
`;

const SuspendIcon = styled(FaPauseCircle)`
  ${IconBase};
  color: #C0C0C0;
  font-size: 1.1em;
  margin-left: 0.7em;

  &:hover {
    color: #808080;
    transform: scale(1.2);
  }
`;

const UnsuspendIcon = styled(FaPlayCircle)`
  ${IconBase};
  color: #4CAF50; /* Updated vibrant green color */
  font-size: 1.1em;
  margin-left: 0.7em;

  &:hover {
    color: #43A047; /* Slightly darker green for hover state */
    transform: scale(1.2);
  }
`;


const ProvidersPage = () => {
  const { user } = useUser();
  const [providers, setProviders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 7;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState('');
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [category, setCategory] = useState('');

  const procedureCategories = ['Dental', 'Cosmetic', 'Hair Restoration'];

  const fetchProviders = useCallback(async () => {
    if (user && user.uid) {
      try {
        const response = await axiosInstance.get(`/providers`, {
          headers: { Authorization: `Bearer ${await user.getIdToken()}` },
        });
        setProviders(response.data || []);
      } catch (error) {
        console.error('Error fetching providers:', error);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  const handleModalConfirm = async (providerData) => {
    console.log(`Attempting to ${modalActionType} provider with data:`, providerData);
    try {
      if (modalActionType === 'add') {
        console.log(`Sending POST request to /providers with data:`, providerData);
        await axiosInstance.post(`/providers`, { ...providerData, category }, {
          headers: { Authorization: `Bearer ${await user.getIdToken()}` },
        });
        await fetchProviders();
      } else if (modalActionType === 'remove') {
        await axiosInstance.delete(`/providers/${selectedProvider.id}`, {
          headers: { Authorization: `Bearer ${await user.getIdToken()}` },
        });
        setProviders(prevProviders => prevProviders.filter(prov => prov.id !== selectedProvider.id));
      } else if (modalActionType === 'suspend') {
        await axiosInstance.patch(`/providers/${selectedProvider.id}`, { suspended: providerData.suspended }, {
          headers: { Authorization: `Bearer ${await user.getIdToken()}` },
        });
        setProviders(prevProviders =>
          prevProviders.map(provider =>
            provider.id === selectedProvider.id ? { ...provider, suspended: providerData.suspended } : provider
          )
        );
      }
    } catch (error) {
      console.error(`Failed to ${modalActionType} provider:`, error);
      alert(`Failed to ${modalActionType} the provider. Please try again.`);
    } finally {
      setModalOpen(false);
    }
  };
  
  const filteredProviders = providers.filter(prov =>
    prov && prov.email && prov.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedProviders = filteredProviders.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const openModal = (actionType, provider = null) => {
    setModalActionType(actionType);
    setSelectedProvider(provider);
    setModalOpen(true);
  };

  return (
    <Container>
      <SearchAndAddContainer>
        <SearchInput
          placeholder="Search provider"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AddButton onClick={() => openModal('add')}>
          Add Provider
        </AddButton>
      </SearchAndAddContainer>
      {providers.length > 0 ? (
        <StyledTable>
          <thead>
            <TableRow>
              <TableHeaderEmail>Email</TableHeaderEmail>
              <CenterAlignedHeader>Category</CenterAlignedHeader>
              <CenterAlignedHeader>Access Code</CenterAlignedHeader>
              <CenterAlignedHeader>Status</CenterAlignedHeader>
              <TableHeaderActions>Actions</TableHeaderActions>
            </TableRow>
          </thead>
          <tbody>
          {paginatedProviders.map((provider) => (
            <TableRow key={provider.id}>
              <EmailCell>
                <HighlightText text={provider.email} searchTerm={searchTerm} />
              </EmailCell>
              <CenterAlignedCell>{provider.category}</CenterAlignedCell>
              <CenterAlignedCell>{provider.accessCode}</CenterAlignedCell>
              <CenterAlignedCell>{provider.suspended ? 'Suspended' : 'Active'}</CenterAlignedCell>
              <CenterAlignedCell>
                {provider.suspended ? (
                  <UnsuspendIcon onClick={() => openModal('suspend', provider)} />
                ) : (
                  <SuspendIcon onClick={() => openModal('suspend', provider)} />
                )}
                <RemoveIcon onClick={() => openModal('remove', provider)} />
              </CenterAlignedCell>
            </TableRow>
          ))}
          </tbody>
        </StyledTable>
      ) : (
        <NoProvidersMessage>No providers added yet</NoProvidersMessage>
      )}
      <Pagination
        totalPages={Math.ceil(filteredProviders.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
      {isModalOpen && (
        <ProvidersModal
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          actionType={modalActionType}
          provider={selectedProvider}
          category={category}
          setCategory={setCategory}
          procedureCategories={procedureCategories}
          onConfirm={handleModalConfirm}
        />
      )}
    </Container>
  );
};

export default ProvidersPage;