import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReviewsSection from 'components/ProviderDashboard/Helpers/Reviews/ReviewsSection.js';
import Pagination from 'components/ProviderDashboard/Helpers/AppointmentsTable/Pagination.js'; // Assuming you're reusing the Pagination
import SearchAndFilterReviews from 'components/ProviderDashboard/Helpers/Reviews/SearchAndFilterReviews.js'; // Import the component
import { generateReviews } from 'components/ProviderDashboard/Helpers/Reviews/GenerateReviews.js';
import ReviewCard from 'components/ProviderDashboard/Helpers/Reviews/ReviewCard.js';  // Replace 'path-to-ReviewCard.js' with the actual path


const PageWrapper = styled.div`
  display: flex;
  padding: 1% 1% 2% 1.5%;
  width: 90%;
`;

const Sidebar = styled.div`
  width: 20em;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const Title = styled.h1`
  font-weight: 300;
  margin-top: 1em;
  margin-bottom: 0px;
  font-size: 2em;
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const MainContent = styled.div`
  flex: 1;
`;

const ReviewFeed = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export default function ReviewsPage() {
  const n = 200; // Sample size
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 3;
  const [reviews, setReviews] = useState([]); // Create a state to hold reviews

  useEffect(() => {
    // Generate the reviews once when the component mounts
    const generatedReviews = generateReviews(n);

    // Sort the reviews by date and time
    generatedReviews.sort((a, b) => {
        return new Date(b.date) - new Date(a.date); // This will sort from newest to oldest
    });

    setReviews(generatedReviews);
}, []);


  const [searchTerm, setSearchTerm] = useState('');
  const [procedureFilter, setProcedureFilter] = useState('All Procedures');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null); // State for managing selected rating

  const clearAllFilters = () => {
    setSearchTerm('');
    setSelectedRating(null);  // Clear the selected rating
  };

  // Function to filter the reviews based on the search term.
  const filteredReviews = reviews.filter(review => {
    // Search term filter
    const matchesSearchTerm = !searchTerm || 
                          review.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
                          review.comment.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          review.procedure.toLowerCase().includes(searchTerm.toLowerCase());  // Add this line
    
    // Procedure filter
    const matchesProcedure = procedureFilter === 'All Procedures' || review.procedure === procedureFilter;
    
    // Date filters
    const reviewDate = new Date(review.date);
    const afterStartDate = !startDate || reviewDate >= new Date(startDate);
    const beforeEndDate = !endDate || reviewDate <= new Date(endDate);
    const matchesRating = !selectedRating || review.stars === selectedRating;

    return matchesSearchTerm && matchesProcedure && afterStartDate && beforeEndDate && matchesRating;
  });
  
  

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <PageWrapper>
      <Sidebar>
        <Title>Reviews</Title>
        <ReviewsSection 
          reviews={filteredReviews} 
          selectedRating={selectedRating} 
          setSelectedRating={setSelectedRating}
          isFiltered={filteredReviews.length !== reviews.length} 
        />
        <SearchAndFilterReviews 
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          procedureFilter={procedureFilter}
          setProcedureFilter={setProcedureFilter}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          clearFilters={() => {
            setSearchTerm('');
            setSelectedRating(null); 
          }}
        />
      </Sidebar>

      <MainContent>
      <ReviewFeed>
        {filteredReviews.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((review, index) => (
          <ReviewCard 
              key={index}
              name={review.name}
              stars={review.stars}
              procedure={review.procedure}
              comment={review.comment}
              date={review.date}
              searchTerm={searchTerm}  
          />
        ))}
      </ReviewFeed>

        <Pagination 
          totalPages={Math.ceil(filteredReviews.length / rowsPerPage)} 
          currentPage={currentPage} 
          onPageChange={setCurrentPage} 
        />
      </MainContent>
    </PageWrapper>
  );
}
