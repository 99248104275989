import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Select from 'react-select';

const theme = {
  turquoiseColor: '#AFEEEE',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333',
  placeholderColor: '#606060',
  lightGrey: '#ccc',
  darkGrey: '#808080',
};

const ModalTitle = styled.h2`
  font-weight: 300;
  font-size: 1.6em;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1.3em;
  margin-bottom: 0.2em;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.3em;
  background-color: #00C6B6;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #009a94;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const CustomInput = styled.input`
  font-size: 1.3em;
  padding: 0.8em;
  width: 100%;
  border: 1px solid ${theme.lightGrey};
  border-radius: 5px;
  transition: border-color 0.2s;
  color: ${theme.fontColor};
  &:focus {
    outline: none;
    border-color: ${theme.darkGrey};
  }
  &::placeholder {
    color: ${theme.placeholderColor};
  }
`;

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '0.8em',
    padding: '0.1em 0.2em', // Adjust padding to closely match CustomInput
    borderColor: theme.lightGrey,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    // This ensures the height matches the CustomInput if needed
    minHeight: '1.5em',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.8em',
    backgroundColor: state.isFocused ? theme.hoverTurquoiseColor : 'white',
    color: state.isFocused ? theme.fontColor : theme.placeholderColor,
    '&:hover': { backgroundColor: theme.turquoiseColor },
  }),
};


const AddModalContent = ({ onConfirm, category, setCategory, procedureCategories }) => {
  const [email, setEmail] = useState('');

  const generateAccessCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let accessCode = '';
    for (let i = 0; i < 10; i++) {
      accessCode += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return accessCode;
  };

  const handleConfirm = () => {
    if (email.trim() === '') {
      alert('Please enter an email.');
      return;
    }
    const accessCode = generateAccessCode();
    console.log('Confirming provider with data:', { email, accessCode });
    onConfirm({ email, accessCode });
  };

    return (
      <ThemeProvider theme={theme}> 
        <div>
          <ModalTitle>Add Provider</ModalTitle>
          <div style={{ marginTop: '20px', width: '50%' }}>
            <Label htmlFor="emailInput">Email</Label>
            <CustomInput
              id="emailInput"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter provider email"
            />
          </div>
          <Label htmlFor="categorySelect">Category</Label>
              <Select
                id="categorySelect"
                styles={customSelectStyles}
                value={{ value: category, label: category }}
                onChange={(selectedOption) => setCategory(selectedOption.value)}
                options={procedureCategories.map((cat) => ({ value: cat, label: cat }))}
                placeholder="Select a category"
              />
          </div>
          <ButtonContainer>
            <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
          </ButtonContainer>
    </ThemeProvider>
  );
}


export default AddModalContent;