import React from 'react';
import styled from 'styled-components';

const Menu = styled.div`
  position: fixed;
  top: 6em;
  right: 0;
  height: calc(90vh);
  width: 100%;
  background-color: #00c6b6;
  padding: 2rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const MenuItem = styled.a`
  display: block;
  color: white;
  font-size: 2.3rem;
  font-weight: 500;
  margin-bottom: 2rem;
  cursor: pointer;
  text-align: right;
  width: 100%;
  transition: color 0.3s ease;
`;

const Separator = styled.div`
  height: 3rem;
`;

const HamburgerMenu = ({ isOpen, toggleMenu }) => {
  return (
    <Menu isOpen={isOpen}>
      <MenuItem onClick={toggleMenu}>Sign Up / Log In</MenuItem>
      <Separator />
      <MenuItem onClick={toggleMenu}>How it Works</MenuItem>
      <MenuItem onClick={toggleMenu}>FAQs</MenuItem>
      <MenuItem onClick={toggleMenu}>Blog</MenuItem>
      <Separator />
      <MenuItem onClick={toggleMenu}>Partner with Us</MenuItem>
    </Menu>
  );
};

export default HamburgerMenu;