import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Title = styled.h2`
  font-size: 1.8em;
  font-weight: 400;
  margin-top: 0;
  align-self: flex-start;
  margin-bottom: 30px;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.4em;
  background-color: #FF6347;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 40px;

  &:hover {
    background-color: #FF4500;
  }
`;

const RemoveModalContent = ({ provider, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm({ id: provider.id });
  };

  return (
    <Container>
      <Title>Remove {provider.email}?</Title>
      <ConfirmButton onClick={handleConfirm}>
        Confirm Removal
      </ConfirmButton>
    </Container>
  );
};

export default RemoveModalContent;