import React from 'react';
import styled from 'styled-components';
import logo from 'assets/Logo.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { signOut } from 'firebase/auth'; // Import signOut
import { auth } from 'firebase.js'; // Ensure this is the correct path to your Firebase auth initialization

const SidebarContainer = styled.div`
  min-width: 12.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(240, 240, 240, 0.3);
  backdrop-filter: blur(5px);
  border-right: 1px solid #e1e1e1;
  padding: 0.5em;
  height: 100%;
`;

const MenuItem = styled.li`
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 300;
  color: ${props => props.active ? '#00C6B6' : '#B0B0B0'};
  padding: 0.5em 0em;
  list-style-type: none;
  width: 100%;
  text-align: left;

  &:hover {
    color: ${props => props.active ? '#00C6B6' : '#7E7E7E'};
  }
`;

const LogoutLink = styled(MenuItem)`
  color: #B0B0B0;
  &:hover {
    color: #7E7E7E;
  }
  margin-left: 1.5em;
  margin-bottom: 1em;
`;

const LogoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const LogoImage = styled.img`
  margin-top: 1.5em;
  margin-left: 0.6em;
  width: 2.4em; // Adjust width as needed
  height: auto;
`;

const LogoText = styled.span`
  font-size: 1.8em;
  font-family: 'Poppins';
  font-weight: 400;
  margin-left: 0.1em;
  margin-top: 0.5em;
`;

const Sidebar = ({ activeItem, setActiveItem }) => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/providerlogin');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const menuItems = [
    //{ name: 'Requests' },
    //{ name: 'Appointments' },
    //{ name: 'Reviews' },
    { name: 'Information' },
    { name: 'Procedures' },
  ];

  return (
    <SidebarContainer>
      <div>
        <LogoPlaceholder>
          <LogoImage src={logo} alt="VoyaDoc Logo" />
          <LogoText>VoyaDoc</LogoText>
        </LogoPlaceholder>
        <ul>
          {menuItems.map(({ name }) => (
            <MenuItem 
              key={name}
              onClick={() => setActiveItem(name)}
              active={name === activeItem}
            >
              {name}
            </MenuItem>
          ))}
        </ul>
      </div>
      <LogoutLink onClick={handleLogout}>Logout</LogoutLink>
    </SidebarContainer>
  );
}

export default Sidebar;
