import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Container = styled.div`
  padding: 1em;
`;

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 0;
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 300;
  font-size: 1.3em;
  margin-bottom: 0.3em;
  margin-top: 30px;
`;

const StyledInput = styled.input`
  font-size: 1.4em;
  padding: 0.5em 0.5em;
  width: 100%;
  margin-top: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.3em;
  background-color: #00C6B6;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #009a94;
  }
`;

const theme = {
  turquoiseColor: '#AFEEEE',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333', // Darker color for actual input text
  placeholderColor: '#606060', // Medium grey for placeholder text
  lightGrey: '#ccc', // Light grey for borders
  darkGrey: '#808080', // Darker grey for focused borders
};

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '0.8em',
    padding: '0.1em 0.2em', // Adjust padding to closely match CustomInput
    borderColor: theme.lightGrey,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    // This ensures the height matches the CustomInput if needed
    minHeight: '1.5em',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.8em',
    backgroundColor: state.isFocused ? theme.hoverTurquoiseColor : 'white',
    color: state.isFocused ? theme.fontColor : theme.placeholderColor,
    '&:hover': { backgroundColor: theme.turquoiseColor },
  }),
};

const EditModalContent = ({ procedure, onConfirm, procedureCategories }) => {
  const [name, setName] = useState(procedure.name);
  const [type, setType] = useState(procedure.type);
  const [category, setCategory] = useState(procedure.category);

  // Transform procedureCategories into an array of objects for the Select component
  const options = procedureCategories.map(cat => ({ value: cat, label: cat }));

  // Adjust how you determine the current value to match the format expected by your Select component
  const currentValue = options.find(option => option.value === category);

  const handleConfirm = () => {
    if (name.trim() === '' || type.trim() === '' || !category) {
      alert('Procedure name, type, and category cannot be empty.');
      return;
    }
    onConfirm({ ...procedure, name, type, category });
  };

  return (
    <Container>
      <Title>Edit Procedure</Title>
      <StyledLabel htmlFor="nameInput">Procedure Name</StyledLabel>
      <StyledInput
        id="nameInput"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter procedure name"
      />
      <StyledLabel htmlFor="typeInput">Procedure Type</StyledLabel>
      <StyledInput
        id="typeInput"
        type="text"
        value={type}
        onChange={(e) => setType(e.target.value)}
        placeholder="Enter procedure type"
      />
      <StyledLabel htmlFor="categorySelect">Category</StyledLabel>
      <Select
        id="categorySelect"
        value={currentValue}
        onChange={(selectedOption) => setCategory(selectedOption.value)}
        options={options}
        placeholder="Select a category"
      />
      <ButtonContainer>
        <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
      </ButtonContainer>
    </Container>
  );
};

export default EditModalContent;