import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setIdToken(token);
        setUser(user);
        setUserEmail(user.email);
      } else {
        setIdToken(null);
        setUser(null);
        setUserRole(null);
        setUserEmail(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const updateIdToken = (token) => {
    setIdToken(token);
  };

  const updateUserRole = (role) => {
    setUserRole(role);
  };

  const updateUserEmail = (email) => {
    setUserEmail(email);
  };

  const getIdToken = async () => {
    if (user) {
      return await user.getIdToken();
    }
    return null;
  };

  return (
    <UserContext.Provider value={{ user, idToken, userRole, userEmail, isLoading, updateIdToken, updateUserRole, updateUserEmail, getIdToken}}>
      {children}
    </UserContext.Provider>
  );
};