import styled from 'styled-components';

export const DeclineModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); // Darken the backdrop for better focus on the modal
  display: flex;
  align-items: center; // Center vertically
  justify-content: center; // Center horizontally
  z-index: 1050; // Ensure it's above other content
`;

export const DeclineModalContainer = styled.div`
  background-color: white;
  width: 65%; // Further increased width
  max-width: 50em; // Corresponding max width for larger screens
  min-height: 24em; // Slightly increased height for more content
  border-radius: 0.75em; // Soft rounded corners for modern aesthetics
  box-shadow: 0em 1em 2.5em rgba(0, 0, 0, 0.2); // Stronger shadow for a "pop-out" effect
  display: flex;
  flex-direction: column;
  padding: 2.5em; // More padding for a spacious layout
  align-items: stretch; // Stretch children to fill width
  overflow: hidden; // Prevent overflow issues
`;

export const DeclineModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5em; // Added margin-bottom for spacing
  border-bottom: 0.125em solid #eee; // Slightly thicker border for definition
`;

export const DeclineModalTitle = styled.h2`
  font-size: 2.3rem; // Increased font size for prominence
  font-weight: 400; // Bold font weight for emphasis
  color: #333;
  margin: 0;
  line-height: 1.2; // Improved line height for readability
`;

export const DeclineModalCloseButton = styled.button`
  font-size: 3rem; // Keep large size for easy interaction
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  padding: 0em;
  &:hover {
    color: #c82333; // Consistent with confirm button hover state
  }
`;

export const DeclineModalContent = styled.div`
  padding: 0; // Remove padding here as we have padding on the container
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReasonTextArea = styled.textarea`
  width: 100%; // Full width of the container
  height: 15em; // Increased height for more text visibility
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.7em; // Comfortable padding inside
  font-size: 1.1rem; // Readable font size
  border-radius: 0.5em;
  border: 0.125em solid #ccc;
  resize: vertical; // Allow vertical resizing
  box-shadow: inset 0 0.125em 0.25em rgba(0, 0, 0, 0.1); // Inner shadow for depth
`;

export const CharacterCount = styled.div`
  align-self: flex-end;
  font-size: 1.25rem; // Increased font size for better visibility
  color: #666;
  padding-right: 1.25em; // Padding to align with the textarea padding
  margin-bottom: 1.5em; // Increased space before the confirm button
`;

export const DeclineModalConfirmButton = styled.button`
  padding: 0.6em 1.8em; // More padding for a larger clickable area
  font-size: 1.5rem; // Increased font size for visibility
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  box-shadow: 0em 0.25em 0.5em rgba(0, 0, 0, 0.1); // Shadow for depth
  &:hover {
    background-color: #c82333; // Darken for interaction feedback
  }
  &:disabled {
    background-color: #e2e3e5; // Disabled state
    color: #6c757d;
  }
`;
