import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from 'firebase.js'; // Ensure this path is correct
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from 'assets/Logo.png'; // Ensure this path is correct
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LogoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
`;

const LogoImage = styled.img`
  width: 4em; // Adjust width as needed
  height: auto;
`;

const LogoText = styled.span`
  font-size: 3.7em;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
`;

const Title = styled.h1`
  color: #B0B0B0;
  font-weight:400;
  font-size: 1.6em;
  margin-bottom: 1.5rem;
`;

const StyledInput = styled.input`
  font-size: 1em;
  padding: 10px;
  margin-bottom: 1rem;
  width: 300px;
  border: 2px solid #B0B0B0; // A classy dark grey
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out; // Smooth transition for hover effect

  &:focus, &:hover {
    border-color: #00C6B6; // Turquoise tone on hover/focus
    outline: none; // Remove default focus outline
  }
`;

const Button = styled.button`
  background-color: #00C6B6; // Accent color
  color: white;
  font-size: 1em;
  padding: 10px 20px;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 320px;

  &:hover {
    background-color: #008C7A; // Darker shade for hover effect
  }
`;

const SwitchModeText = styled.p`
  color: #00C6B6; // Accent color
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ReturnToLoginText = styled.p`
  color: #00C6B6;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

function PatientLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();

  const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSignUp = async () => {
    if (!email || !password) {
      toast.error("Please fill in both email and password.");
      return;
    }
    if (!emailIsValid(email)) {
      toast.error("Invalid email format.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Send a request to the backend to store additional user data
      const response = await fetch('/users/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: user.uid,
          email: user.email,
          role: 'patient',
        }),
      });
  
      if (response.ok) {
        toast.success("Registered successfully!");
        navigate('/');
      } else {
        throw new Error('Failed to register user on the backend');
      }
    } catch (error) {
      console.error('Error registering user:', error);
      toast.error("Failed to register. Please try again.");
    }
  };

  const handleLogin = async () => {
    if (!email || !password) {
      toast.error("Please fill in both email and password.");
      return;
    }
    if (!emailIsValid(email)) {
      toast.error("Invalid email format.");
      return;
    }
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success("Logged in successfully!");
      navigate('/');
    } catch (error) {
      toast.error("Failed to log in. Please check your credentials.");
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleSendResetEmail = async () => {
    try {
      console.log("Sending password reset email to:", email);
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent!");
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Failed to send password reset email:", error);
      toast.error("Failed to send password reset email. Please try again.");
    }
  };

  const handleReturnToLogin = () => {
    setIsRegistering(false);
    setIsForgotPassword(false);
  };

  return (
    <Container>
      <ToastContainer position="top-center" autoClose={5000} />
      <LogoPlaceholder>
        <LogoImage src={logo} alt="VoyaDoc Logo" />
        <LogoText>VoyaDoc</LogoText>
      </LogoPlaceholder>
      <Title>
        {isForgotPassword
          ? "Reset Password"
          : isRegistering
          ? "Sign Up"
          : "Login"}
      </Title>

      {isForgotPassword ? (
        <>
          <StyledInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <Button onClick={handleSendResetEmail}>Recover Password</Button>
          <ReturnToLoginText onClick={handleReturnToLogin}>
            Return to Login
          </ReturnToLoginText>
        </>
      ) : (
        <>
          <StyledInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <StyledInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <Button onClick={isRegistering ? handleSignUp : handleLogin}>
            {isRegistering ? "Sign Up" : "Login"}
          </Button>

          <>
            <SwitchModeText onClick={() => setIsRegistering(!isRegistering)}>
              {isRegistering ? "Switch to Login" : "Switch to Sign Up"}
            </SwitchModeText>
            {!isRegistering && (
              <SwitchModeText onClick={handleForgotPassword}>
                Forgot Password?
              </SwitchModeText>
            )}
          </>

        </>
      )}
    </Container>
  );
}

export default PatientLogin;