// StarRating.js

import React from 'react';
import 'styles/ProviderDashboard/Helpers/Reviews/StarRating.css';

const StarRating = ({ rating }) => {
  return (
    <div className="stars">
      {[...Array(5)].map((_, idx) => (
        <div className="star" key={idx}>
          <div className="star-fill" style={{ width: `${Math.min(100, Math.max(0, 100 * (rating - idx)))}%` }}></div>
        </div>
      ))}
    </div>
  );
}

export default StarRating;
