import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaTrashAlt, FaPencilAlt } from 'react-icons/fa';
import Pagination from 'components/AdminDashboard/Helpers/Procedures/Pagination.js';
import ProceduresModal from 'components/AdminDashboard/Helpers/Procedures/ProceduresModal.js';
import { HighlightText } from '../Helpers/Procedures/HighlightText';
import axiosInstance from 'utils/axiosInstance';
import { useUser } from 'UserContext.js';

const Container = styled.div`
  width: 90%;
  margin: 2em auto;
  padding: 3em 8em 3em 8em;
  overflow-y: auto;
`;

const SearchAndAddContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 1em;
`;

const SearchInput = styled.input`
  padding: 0.6em 0.8em;
  font-size: 1.2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 16em;

  &:focus {
    outline: none;
    border-color: #00C6B6;
  }
`;

const AddButton = styled.button`
  padding: 0.6em 1em;
  font-size: 1.3em;
  border: none;
  cursor: pointer;
  background-color: #00C6B6;
  color: #FFFFFF;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #008C8A;
  }
`;

const StyledTable = styled.table`
  width: 90%;
  max-height: 60vh;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 1.5em auto;
  font-size: 18px;
  box-shadow: 0 4px 4px rgba(0,0,0,0.1);
`;

const TableHeader = styled.th`
  font-size: 1em;
  font-weight: 500;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  background: #F0F0F0;
  color: #333;
  padding: 1em 1.7em;
  border-bottom: 1.3px solid #ccc;
`;

const CenterAlignedHeader = styled(TableHeader)`
  text-align: center;
  width: 25%; // This applies to both the type and category columns based on your layout
`;

const TableRow = styled.tr`
  height: 3.5em;
  background-color: white;
  font-family: 'Poppins', sans-serif;
`;

const TableCell = styled.td`
  max-height: 3.5em;
  padding: 0.5em 0.1em;
  border-bottom: 1.3px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const IconBase = styled.span`
  cursor: pointer;
  margin: 0 10px;
  vertical-align: middle;
`;

const EditIcon = styled(FaPencilAlt)`
  ${IconBase};
  color: #6E6E6E;
  font-size: 1.1em;
  margin-right: 0.7em;

  &:hover {
    color: #4D4D4D;
    transform: scale(1.2);
  }
`;

const RemoveIcon = styled(FaTrashAlt)`
  ${IconBase};
  color: #FF6347;
  font-size: 1.1em;
  margin-left: 0.7em;

  &:hover {
    color: #C9302C;
    transform: scale(1.2);
  }
`;

const CenterAlignedCell = styled(TableCell)`
  text-align: center;
  vertical-align: middle;
`;

const ProcedureNameCell = styled(TableCell)`
  text-align: left;
  padding-left: 50px;
`;

const TableHeaderActions = styled(TableHeader)`
  text-align: center;
  padding: 15px 0;
  width: 15%;
`;

const TableHeaderProcedure = styled(TableHeader)`
  padding-left: 50px;
  width: 30%;
`;

const NoProceduresMessage = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #666;
  margin-top: 20vh;
`;

const ProceduresPage = () => {
  const [procedures, setProcedures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 12;
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalActionType, setModalActionType] = useState('');
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [category, setCategory] = useState('');
  const { user } = useUser();

  const procedureCategories = ['Dental', 'Cosmetic', 'Hair Restoration'];

  const fetchProcedures = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/procedures`, {
        headers: { Authorization: `Bearer ${await user.getIdToken()}` },
      });
      setProcedures(response.data || []);
    } catch (error) {
      console.error('Error fetching procedures:', error);
    }
  }, [user]);

  useEffect(() => {
    fetchProcedures();
  }, [fetchProcedures]);

  const handleModalConfirm = async (procedureData) => {
    try {
      if (modalActionType === 'add') {
        await axiosInstance.post('/procedures', { ...procedureData, category }, {
          headers: { Authorization: `Bearer ${await user.getIdToken()}` },
        });
        await fetchProcedures();
      } else if (modalActionType === 'edit') {
        await axiosInstance.put(`/procedures/${selectedProcedure.id}`, procedureData, {
          headers: { Authorization: `Bearer ${await user.getIdToken()}` },
        });
        await fetchProcedures();
      } else if (modalActionType === 'remove') {
        await axiosInstance.delete(`/procedures/${selectedProcedure.id}`, {
          headers: { Authorization: `Bearer ${await user.getIdToken()}` },
        });
        setProcedures(prevProcedures => prevProcedures.filter(proc => proc.id !== selectedProcedure.id));
      }
    } catch (error) {
      console.error(`Failed to ${modalActionType} procedure:`, error);
      alert(`Failed to ${modalActionType} the procedure. Please try again.`);
    } finally {
      setModalOpen(false);
    }
  };
  
  const filteredProcedures = procedures.filter(proc =>
    proc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedProcedures = filteredProcedures.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const openModal = (actionType, procedure = null) => {
    setModalActionType(actionType);
    setSelectedProcedure(procedure);
    setModalOpen(true);
  };

  return (
    <Container>
      <SearchAndAddContainer>
        <SearchInput
          placeholder="Search procedure"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AddButton onClick={() => openModal('add')}>
          Add Procedure
        </AddButton>
      </SearchAndAddContainer>
      {procedures.length > 0 ? (
        <StyledTable>
          <thead>
            <TableRow>
              <TableHeaderProcedure>Procedure Name</TableHeaderProcedure>
              <CenterAlignedHeader>Procedure Type</CenterAlignedHeader>
              <CenterAlignedHeader>Category</CenterAlignedHeader> {/* New Category Header */}
              <TableHeaderActions>Actions</TableHeaderActions>
            </TableRow>
          </thead>
          <tbody>
            {paginatedProcedures.map((procedure) => (
              <TableRow key={procedure.id}>
                <ProcedureNameCell>
                  <HighlightText text={procedure.name} searchTerm={searchTerm} />
                </ProcedureNameCell>
                <CenterAlignedCell>{procedure.type}</CenterAlignedCell>
                <CenterAlignedCell>{procedure.category}</CenterAlignedCell> {/* New Category Cell */}
                <CenterAlignedCell>
                  <EditIcon onClick={() => openModal('edit', procedure)} />
                  <RemoveIcon onClick={() => openModal('remove', procedure)} />
                </CenterAlignedCell>
              </TableRow>
            ))}
          </tbody>
        </StyledTable>
      ) : (
        <NoProceduresMessage>No procedures added yet</NoProceduresMessage>
      )}
      <Pagination
        totalPages={Math.ceil(filteredProcedures.length / itemsPerPage)}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
      {isModalOpen && (
        <ProceduresModal
          isOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          actionType={modalActionType}
          procedure={selectedProcedure}
          category={category}
          setCategory={setCategory}
          procedureCategories={procedureCategories}
          onConfirm={handleModalConfirm}
        />
      )}
    </Container>
  );
};

export default ProceduresPage;