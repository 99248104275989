// GeneralInformation.js
import React, { useState } from 'react';
import styled from 'styled-components';
import 'styles/ProviderDashboard/Helpers/Information/GeneralInformation.css';
import RenderEditMode from './RenderEditMode';
import RenderViewMode from './RenderViewMode';

// Styled components
const Container = styled.div`
  background: #fff;
  padding: 2em;
`;

const defaultInfo = {
  name: '',
  address: '',
  yearOpened: '',
  certifications: [],
  email: '',
  phone: '',
  paymentOptions: [],
  services: [],
  hours: {
    Monday: { open: '8:00 AM', close: '5:00 PM', closed: false },
    Tuesday: { open: '8:00 AM', close: '5:00 PM', closed: false },
    Wednesday: { open: '8:00 AM', close: '5:00 PM', closed: false },
    Thursday: { open: '8:00 AM', close: '5:00 PM', closed: false },
    Friday: { open: '8:00 AM', close: '5:00 PM', closed: false },
    Saturday: { open: '', close: '', closed: true },
    Sunday: { open: '', close: '', closed: true },
  },
};

// The GeneralInformation component
const GeneralInformation = ({ initialInfo }) => {
  const initialData = { ...defaultInfo, ...initialInfo };
  const [editMode, setEditMode] = useState(false);
  const [info, setInfo] = useState(initialData);
  
  const handleSave = () => {
    console.log('Info to save:', info);
    setEditMode(false);
  };

  return (
    <Container>
      {editMode ? (
        <RenderEditMode info={info} setInfo={setInfo} handleSave={handleSave} setEditMode={setEditMode} />
      ) : (
        <RenderViewMode info={info} setEditMode={setEditMode} />
      )}
    </Container>
  );
};

export default GeneralInformation;