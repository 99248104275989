import React, { useState, useEffect } from 'react';
import { useUser } from 'UserContext.js';
import Sidebar from './Sidebar';

import RequestsPage from 'components/AdminDashboard/ContentPages/RequestsPage.js';
import ProvidersPage from 'components/AdminDashboard/ContentPages/ProvidersPage.js';
import ProceduresPage from 'components/AdminDashboard/ContentPages/ProceduresPage.js';

import 'styles/ProviderDashboard/ProviderDashboard.css';

const AdminDashboard = () => {
  const [activeItem, setActiveItem] = useState('Providers');
  const { user } = useUser();

  useEffect(() => {
    console.log("Current User:", user);
  }, [user]);

  return (
    <div className="dashboardContainer">
      <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} />
      {activeItem === 'Requests' && <RequestsPage />}
      {activeItem === 'Providers' && <ProvidersPage />}
      {activeItem === 'Procedures' && <ProceduresPage />}
    </div>
  );
};

export default AdminDashboard;
