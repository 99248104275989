import React, { useState } from 'react';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';

// Define the theme with increased font sizes and a more vibrant blue
const theme = createTheme({
  palette: {
    primary: {
      main: '#0056b3', // A vibrant blue color
    },
    secondary: {
      main: '#f50057',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%', // Ensure full width for better visibility
          '& .MuiInputBase-root': {
            height: '5rem', // Adjusting height for balance
            '& input': {
              padding: '0.75rem',
              fontSize: '1.3rem', // Uniform font size for legibility
            },
            '& fieldset': {
              borderColor: '#c4c4c4',
            },
            '&:hover fieldset': {
              borderColor: '#a0a0a0',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0056b3',
            },
          },
        },
      },
    },
    
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.95em',
          padding: '0.4em 0.9em',
          margin: '1.2em 1.6em',
          backgroundColor: 'white',
          color: '#0056b3',
          border: '.12em solid #0056b3',
          borderRadius: '1.25em',
          boxShadow: '0em 0.25em 0.5em rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#e6f3ff',
            color: '#003366',
            borderColor: '#003366',
          },
          '&:disabled': {
            backgroundColor: '#cccccc',
            color: 'white',
            borderColor: '#cccccc',
          },
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: '2rem', // Slightly larger for a better fit
          height: '2rem',
          margin: '0.2rem',
          fontSize: '1.2rem', // Keeping the larger font size
          '&.Mui-selected': {
            backgroundColor: '#0056b3',
            '&:hover': {
              backgroundColor: '#003366',
            },
          },
          '&.Mui-disabled': {
            color: '#e0e0e0',
          },
          '&.Mui-today': {
            border: '0.06em solid #0056b3',
          },
        },
      },
    },    
  },
  MuiClockPicker: {
    styleOverrides: {
      clock: {
        // Optional: Adjust clock size if necessary
      },
      clockHour: {
        '&.MuiClockNumber-clockNumber': {
          fontSize: '1.6rem', // Ensuring consistency in font size
        },
      },
      clockMinute: {
        '&.MuiClockNumber-clockNumber': {
          fontSize: '1.6rem', // Same as above for consistency
        },
      },
    },  
  },

  MuiPaper: {
    styleOverrides: {
      root: {
        // Adjust the overall size of the picker dropdown
        minWidth: '40em',
        minHeight: '30em',
      },
    },
  },
  typography: {
    // Update the font size for the entire picker component
    fontSize: 16,
  },
});

const RequestsDateTimePicker = ({ dateRange, onConfirm }) => {
  const [minDateString, maxDateString] = dateRange.split(' - ');
  const minDate = dayjs(minDateString);
  const maxDate = dayjs(maxDateString);
  const defaultTime = minDate || dayjs().hour(8).minute(0);
  const [selectedDateTime, setSelectedDateTime] = useState(defaultTime);

  const handleDateTimeChange = (newDateTime) => {
    setSelectedDateTime(newDateTime);
  };

  const handleConfirm = () => {
    if (selectedDateTime === null || !dayjs(selectedDateTime).isValid()) {
      toast.error("Must select a valid date and time!");
      return;
    }
    onConfirm(dayjs(selectedDateTime).format('YYYY-MM-DD HH:mm'));
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker
        value={selectedDateTime}
        onChange={handleDateTimeChange}
        renderInput={(params) => (
          <TextField 
            {...params} 
            // Format the value for the input display
            value={selectedDateTime ? dayjs(selectedDateTime).format('MMMM D, YYYY h:mm A') : ''} 
          />
        )}
        inputFormat="MMMM D, YYYY h:mm A"
        views={['year', 'month', 'day', 'hours', 'minutes']}
        minDate={minDate}
        maxDate={maxDate}
        PaperProps={{
          style: {
            width: 'auto',
            maxWidth: 'none',
          },
        }}
        ToolbarComponent={({ date, isLandscape, onChange, setOpenView, openView }) => (
          <div style={{ padding: '0em 1.5em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ fontSize: '2rem' }}>{dayjs(date).format('MMMM D, YYYY')}</div>
            {/* Additional toolbar elements with increased sizes can be included here */}
          </div>
        )}
        />

        <Button onClick={handleConfirm} variant="contained">
          Confirm Time
        </Button>
      </LocalizationProvider>
    </ThemeProvider>
  );  
};

export default RequestsDateTimePicker;
