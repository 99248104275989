import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import RequestsModal from 'components/ProviderDashboard/Helpers/Requests/RequestsModal.js';
import Pagination from 'components/ProviderDashboard/Helpers/AppointmentsTable/Pagination.js';
import dayjs from 'dayjs'; 
import advancedFormat from 'dayjs/plugin/advancedFormat'; 
import { HighlightText } from '../Helpers/Requests/HighlightText';


const Container = styled.div`
  max-width: 90%;
  margin: 0.2em auto;
  padding: 1em;
  overflow-y: auto;
`;

const ScrollableTableContainer = styled.div`
  max-height: 80vh; // Set the maximum height you want for your table
  overflow-y: auto; // Enable vertical scrolling
  margin-top: 0.2em;
  box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.1);
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 1.5em; // 24px, adjusted for responsiveness
  font-family: 'Poppins', sans-serif;
  color: #333;
`;

const SearchInput = styled.input`
  padding: 0.6em; // 15px
  font-size: 1em; // 26px
  border: 1px solid #ccc;
  border-radius: 5px;
  width: auto; // Adjust based on content
`;

const StyledTable = styled.table`
  width: 80vw; // Ensure table fills the container
  border-collapse: collapse;
  font-size: 0.95em;
  box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const TableHeader = styled.th`
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  background: #F0F0F0;
  color: #333;
  padding: 1em;
  text-align: left;
  border-bottom: 1.3px solid #ccc;
  width: ${props => props.width || 'auto'}; // Default to 'auto' if no width prop is provided
`;

const TableRow = styled.tr`
  background-color: white;
  height: 7vh;
  font-family: 'Poppins', sans-serif;
  &:hover {
    background-color: rgba(0, 198, 182, 0.1);
    cursor: pointer;
  }
`;

const TableCell = styled.td`
  padding: 0.5em;
  border-bottom: 1.3px solid #ddd;
  text-align: left;
  height: 5vh;
  white-space: nowrap; // Prevent wrapping of the text
  overflow: hidden; // Hide overflow
  text-overflow: ellipsis; // Show ellipsis for overflow
  max-width: 0; // Ensures the cell does not stretch out of the table layout
`;

const NoRequestsMessage = styled.div`
  text-align: center;
  font-size: 24px; // Adjust as needed
  color: #666; // Adjust as needed
  margin-top: 2em; // Adjust as needed
`;



dayjs.extend(advancedFormat);

const generateReferenceId = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

// Helper function to select a random item from a list
const getRandomItem = (list) => {
  return list[Math.floor(Math.random() * list.length)];
};

// Helper function to create a random date range with constraints
const generateDateRange = () => {
  const startDate = new Date(2024, 2, 1); // March 1, 2024
  const endDate = new Date(2024, 10, 15); // November 15, 2024
  const randomStartDate = new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
  const latestPossibleEndDate = new Date(2024, 10, 15); // November 15, 2024
  let randomEndDate = new Date(randomStartDate.getTime() + (3 + Math.random() * 180) * 24 * 60 * 60 * 1000); // 3 to 180 days later
  randomEndDate = randomEndDate > latestPossibleEndDate ? latestPossibleEndDate : randomEndDate; // Cap the end date

  const showTable = false;
  
  const format = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return `${format(randomStartDate)} - ${format(randomEndDate)}`;
};


// Lists for randomization
// Expanded lists for randomization
const firstNames = [
  "Olivia", "Liam", "Emma", "Noah", "Ava", "Oliver", "Sophia", "Elijah", "Isabella", "William",
  "Mia", "James", "Amelia", "Lucas", "Charlotte", "Ethan", "Harper", "Mason", "Evelyn", "Logan",
  "Luna", "Alexander", "Avery", "Jacob", "Ella", "Michael", "Scarlett", "Benjamin", "Grace", "Carter",
  "Chloe", "Sebastian", "Riley", "Owen", "Zoey", "Samuel", "Hannah", "Jack", "Lily", "Levi",
  "Addison", "Mateo", "Aria", "Matthew", "Ellie", "Joseph", "Natalie", "David", "Bella", "Dylan"
];

const lastNames = [
  "Smith", "Johnson", "Williams", "Brown", "Jones", "Garcia", "Miller", "Davis", "Rodriguez", "Martinez",
  "Hernandez", "Lopez", "Wilson", "Anderson", "Thomas", "Taylor", "Moore", "Jackson", "Martin", "Lee",
  "Perez", "Thompson", "White", "Harris", "Sanchez", "Clark", "Ramirez", "Lewis", "Robinson", "Walker",
  "Young", "Allen", "King", "Wright", "Scott", "Torres", "Nguyen", "Hill", "Flores", "Green",
  "Adams", "Nelson", "Baker", "Hall", "Rivera", "Campbell", "Mitchell", "Carter", "Roberts", "Gomez"
];

const procedures = [
  "All-on-4 Dental Implants",
  "All-on-6 Dental Implants",
  "Porcelain Veneers",
  "Composite Veneers",
  "Single Dental Implants",
  "Dental Crowns",
  "Orthodontics",
  "Invisalign",
  "Full Mouth Reconstruction",
  "Laser Teeth Whitening",
  "Dental Bridges",
  "Root Canal Treatment",
  "Gum Surgery",
  "Tooth Extractions",
  "Dental Bonding",
  "Teeth Cleaning",
  "Dental Fillings",
  "Dental Sealants",
  "Ceramic Braces",
  "Smile Makeover",
  "Gum Contouring"
];



// Main function to generate the list of requests
const generateRequestsList = (numRequests) => {
  const requestsList = [];
  for (let i = 0; i < numRequests; i++) {
    requestsList.push({
      id: i + 1,
      referenceId: generateReferenceId(10),
      name: `${getRandomItem(firstNames)} ${getRandomItem(lastNames)}`,
      requestedProcedures: getRandomItem(procedures),
      dateRange: generateDateRange(),
    });
  }
  return requestsList;
};

// Generate the list of 20 requests
const requestsList = generateRequestsList(20);

const RequestsPage = ({ requests = requestsList }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRequests, setFilteredRequests] = useState(requests);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [currentRequests, setCurrentRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null); // Track the selected request
  const [isModalOpen, setIsModalOpen] = useState(false); // Track if the modal is open

  const showTable = false;

  const removeRequestFromList = (id) => {
    setFilteredRequests((prevRequests) => prevRequests.filter((request) => request.id !== id));
  };

  useEffect(() => {
    setFilteredRequests(
      requests.filter(request =>
        request.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.referenceId.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, requests]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentRequests(filteredRequests.slice(startIndex, endIndex));
  }, [currentPage, filteredRequests]);

  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);

  // Function to handle row click
  const handleRowClick = (request) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  // Function to format date range
  const formatDateRange = (range) => {
    const dates = range.split(" - ");
    if(dates.length === 2) {
      const formattedStart = dayjs(dates[0]).format('MMM DD, YYYY');
      const formattedEnd = dayjs(dates[1]).format('MMM DD, YYYY');
      return `${formattedStart} - ${formattedEnd}`;
    }
    return range; // Return the original range if it's not in expected format
  };

  return (
    <Container>
      <HeaderRow>
        {showTable && (
          <SearchInput
            placeholder="Name or Reference ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
      </HeaderRow>
      {!showTable ? (
        <NoRequestsMessage>No requests yet</NoRequestsMessage>
      ) : (
        <>
          <ScrollableTableContainer>
            <StyledTable>
              <thead>
                <TableRow>
                  <TableHeader width="20%">Reference ID</TableHeader>
                  <TableHeader width="20%">Patient Name</TableHeader>
                  <TableHeader width="30%">Requested Procedure</TableHeader>
                  <TableHeader width="30%">Requested Date Range</TableHeader>
                </TableRow>
              </thead>
              <tbody>
                {currentRequests.map((request) => (
                  <TableRow key={request.id} onClick={() => handleRowClick(request)}>
                    <TableCell>
                      <HighlightText text={request.referenceId} searchTerm={searchTerm} />
                    </TableCell>
                    <TableCell>
                      <HighlightText text={request.name} searchTerm={searchTerm} />
                    </TableCell>
                    <TableCell>{request.requestedProcedures}</TableCell>
                    <TableCell>{formatDateRange(request.dateRange)}</TableCell>
                  </TableRow>
                ))}
              </tbody>
            </StyledTable>
          </ScrollableTableContainer>
          {filteredRequests.length > itemsPerPage && (
            <Pagination
              totalPages={Math.ceil(filteredRequests.length / itemsPerPage)}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          )}
        </>
      )}
      {isModalOpen && (
        <RequestsModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          request={selectedRequest}
          removeRequest={removeRequestFromList}
        />
      )}
    </Container>
  );  
};

export default RequestsPage;