import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage'; // Ensure this function is correctly implemented

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const CropperModal = styled.div`
  display: ${props => props.isVisible ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000; // Ensure this is above all other content
  align-items: center; // Centers vertically in the viewport
  justify-content: center; // Centers horizontally in the viewport
`;

// CropperContainer should only contain the cropper and the overlay
const CropperContainer = styled.div`
  position: relative;
  width: 90vw;
  height: 60vh;
  max-width: 600px;
  max-height: 400px;
  background-color: #333;
`;

// ButtonContainer is moved outside of CropperContainer to position the button below
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  z-index: 1100; // Ensure it's above the overlay and the image
`;

const StyledCropper = styled(Cropper)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ImagePreviewContainer = styled.div`
  margin-top: 20px;
`;

const ImagePreview = styled.img`
  max-width: 100px;
  max-height: 100px;
  border: 1px solid #ddd;
`;

const FileInput = styled.input`
  margin: 20px 0;
`;

const Button = styled.button`
  padding: 10px 15px;
  margin-top: 20px; // Add some space above the button
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1100; // Ensure the button is clickable by placing it above the cropper
`;

const StaffPhoto = ({ onPhotoCropped }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCroppingModalVisible, setIsCroppingModalVisible] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());

  const onSelectFile = useCallback((event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setImageSrc(reader.result);
        setIsCroppingModalVisible(true);
      };
      setInputKey(Date.now());
    }
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropConfirm = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels);
      onPhotoCropped(croppedImg);
      setCroppedImage(croppedImg);
      setIsCroppingModalVisible(false);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setImageSrc(null);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, onPhotoCropped]);

  const onDeleteCroppedImage = useCallback(() => {
    setCroppedImage(null); // This will clear the cropped image from the state
    onPhotoCropped(null);  // Also inform the parent component that the image has been deleted
  }, [onPhotoCropped]);

  return (
    <Container>
      <FileInput type="file" accept="image/*" onChange={onSelectFile} key={inputKey} />
      <CropperModal isVisible={isCroppingModalVisible}>
        {/* CropperContainer now only includes the Cropper and the overlay */}
        <CropperContainer>
          <StyledCropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </CropperContainer>
        {/* ButtonContainer is now outside of CropperContainer */}
        <ButtonContainer>
          <Button onClick={onCropConfirm}>Confirm Crop</Button>
        </ButtonContainer>
      </CropperModal>
      {croppedImage && (
        <ImagePreviewContainer>
          <ImagePreview src={croppedImage} alt="Cropped" />
          <Button onClick={onDeleteCroppedImage}>Delete Image</Button>
        </ImagePreviewContainer>
      )}
    </Container>
  );
};

export default StaffPhoto;