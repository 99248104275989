// Import necessary hooks
import React, { useState, useEffect } from 'react';
import StarRating from 'components/ProviderDashboard/Helpers/Reviews/StarRating.js';
import 'styles/ProviderDashboard/Helpers/Reviews/ReviewsSection.css';
import styled from 'styled-components';

const Header = styled.h5`
  font-size: 1.6em;
  font-weight: 300;
`;

const ReviewsSection = ({ reviews, selectedRating, setSelectedRating, isFiltered }) => {

  const [reviewBreakdown, setReviewBreakdown] = useState({
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0
  });

  useEffect(() => {
    const newBreakdown = {
      5: 0,
      4: 0,
      3: 0,
      2: 0,
      1: 0
    };

    reviews.forEach(review => {
      if (review.stars in newBreakdown) {
        newBreakdown[review.stars]++;
      }
    });

    setReviewBreakdown(newBreakdown);
  }, [reviews]);

  const totalReviews = Object.values(reviewBreakdown).reduce((acc, val) => acc + val, 0);

  const overallRating = Object.entries(reviewBreakdown).reduce((acc, [star, count]) => {
    return acc + (parseInt(star) * count);
  }, 0) / totalReviews;

  const handleBarClick = (rating) => {
    setSelectedRating(rating);
  };

  return (
    <div className="reviews-container">
      <div className="rating-section">
        <Header>Overall rating</Header>
        <StarRating rating={overallRating} />
        <Header>{totalReviews} reviews {isFiltered && '(filtered)'}</Header>
        {Object.entries(reviewBreakdown).reverse().map(([star, count]) => (
          <div 
            className={`rating-breakdown ${selectedRating === parseInt(star) ? 'selected' : ''}`} 
            key={star} 
            onClick={() => handleBarClick(parseInt(star))}
          >
            <span className="star-span">{star} stars</span>
            <div className="bar-container">
              <div className="bar-background">
                <div className="bar-fill" style={{ width: `${(count/totalReviews)*100}%` }}></div>
              </div>
            </div>
            <span className="count-span">{count}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReviewsSection;
