// RenderEditMode.js
import React from 'react';
import styled from 'styled-components';
import {
  FaBuilding,
  FaClock,
  FaRegCalendarAlt,
  FaMedal,
  FaEnvelope,
  FaPhone,
  FaMoneyBillWave,
  FaHandshake,
  FaHospital
} from 'react-icons/fa';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px; // Adjust the padding as needed
  margin-bottom: 10px; // Space between the button and the container
`;

// Adjust the Container to fill the remaining space after EditButtonContainer
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; // Two columns of equal width
  column-gap: 40px; // Space between columns
  flex-grow: 1; // Takes the remaining space
  overflow: auto; // In case the content overflows the available space
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Distribute space between the items
`;

const LeftColumn = styled(Column)``;

const RightColumn = styled(Column)``;

const EditButton = styled.button`
  background-color: #00c6b6;
  color: white;
  padding: 0.3em 1.1em;
  border: none;
  border-radius: 30px; // More pronounced rounded corners
  cursor: pointer;
  margin-bottom: 0.1em;
  font-size: 1.7em;
  font-weight: 400;
  transition: background-color 0.3s, transform 0.3s; // Smooth transition for hover effects
  &:hover {
    background-color: #009c9a;
  }
  display: block; // Ensure it takes its own line
`;


const InfoSection = styled.div`
  flex: 1; // Allows the section to expand
  padding: 30px 40px 30px 30px; // Increased padding for more white space
  margin-bottom: 20px; // Increase space between sections
  border-radius: 10px; // More pronounced rounded corners for a 3D effect
  background: #ffffff; /* White background for a clean look */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); // Soft shadow for a 3D effect
  border: 1px solid #eaeaea; // Subtle border for depth
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; // Smooth transition for hover effects

  &:hover {
    transform: translateY(-5px); // Slightly raise the section on hover
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2); // Enhanced shadow on hover for a more dynamic effect
  }
`;

const Icon = styled.span`
  font-size: 1.7em;
  margin-top: 10px;
  margin-right: 0.8em;
  color: #00c6b6; /* Or your primary color */
`;

const InfoContent = styled.div`
  flex-grow: 1;
  font-size: 1.2em;
  font-weight: 300;
`;

const InfoTitle = styled.strong`
  font-weight: bold;
  display: block;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 5px;
`;

const HoursTable = styled.table`
  width: 100%; // Full width of the container
  border-collapse: collapse; // Remove spaces between borders
`;

const TableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid transparent; // Invisible line
  }
`;

const TableData = styled.td`
  padding: 8px; // Some padding for aesthetics
  text-align: left; // Left-align the text
`;

const formatHours = ({ open, close, closed }) => {
  if (closed) {
    return 'Closed';
  }

  if (!open || !close) {
    return 'Closed';
  }

  return `${open} - ${close}`;
};

const renderViewMode = ({ info, setEditMode }) => (
  <MainContainer>
    <EditButtonContainer>
      <EditButton onClick={() => setEditMode(true)}>Edit</EditButton>
    </EditButtonContainer>
    <Container>
      <LeftColumn>
        <InfoSection>
          <Icon><FaHospital /></Icon>
          <InfoContent>
            <InfoTitle>Name of Facility</InfoTitle>
            {info.name || 'N/A'} 
          </InfoContent>
        </InfoSection>

        <InfoSection>
          <Icon><FaBuilding /></Icon>
          <InfoContent>
            <InfoTitle>Address</InfoTitle>
            {info.address}
          </InfoContent>
        </InfoSection>

        <InfoSection>
          <Icon><FaEnvelope /></Icon>
          <InfoContent>
            <InfoTitle>Email</InfoTitle>
            {info.email}
          </InfoContent>
        </InfoSection>

        <InfoSection>
          <Icon><FaPhone /></Icon>
          <InfoContent>
            <InfoTitle>Phone Number</InfoTitle>
            {info.phone}
          </InfoContent>
        </InfoSection>
        <InfoSection>
          <Icon><FaMoneyBillWave /></Icon>
          <InfoContent>
            <InfoTitle>Forms of Payment</InfoTitle>
            {info.paymentOptions ? info.paymentOptions.join(', ') : 'N/A'}
          </InfoContent>
        </InfoSection>
      </LeftColumn>

      <RightColumn>
        <InfoSection>
          <Icon><FaClock /></Icon>
          <InfoContent>
            <InfoTitle>Hours of Operation</InfoTitle>
            <HoursTable>
              <tbody>
                {Object.entries(info.hours).map(([day, schedule]) => (
                  <TableRow key={day}>
                    <TableData>{day}</TableData>
                    <TableData>{formatHours(schedule)}</TableData>
                  </TableRow>
                ))}
              </tbody>
            </HoursTable>
          </InfoContent>
        </InfoSection>
        <InfoSection>
          <Icon><FaRegCalendarAlt /></Icon>
          <InfoContent>
            <InfoTitle>Year Opened</InfoTitle>
            {info.yearOpened}
          </InfoContent>
        </InfoSection>

        <InfoSection>
          <Icon><FaMedal /></Icon>
          <InfoContent>
            <InfoTitle>Certifications</InfoTitle>
            {info.certifications ? info.certifications.join(', ') : 'N/A'}
          </InfoContent>
        </InfoSection>
        <InfoSection>
          <Icon><FaHandshake /></Icon>
          <InfoContent>
            <InfoTitle>Services Offered</InfoTitle>
            {info.services.join(', ')}
          </InfoContent>
        </InfoSection>
      </RightColumn>
    </Container>
  </MainContainer>
);

export default renderViewMode;