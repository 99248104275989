import React, { useState } from 'react';
import Select from 'react-select';
import styled, { ThemeProvider } from 'styled-components';

const theme = {
  turquoiseColor: '#AFEEEE',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333', // Darker color for actual input text
  placeholderColor: '#606060', // Medium grey for placeholder text
  lightGrey: '#ccc', // Light grey for borders
  darkGrey: '#808080', // Darker grey for focused borders
};

const ModalTitle = styled.h2`
  font-weight: 300;
  font-size: 1.6em;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: block;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 1.3em;
  margin-bottom: 0.2em;
`;

const ConfirmButton = styled.button`
  padding: 15px 20px;
  font-size: 1.3em;
  background-color: #00C6B6;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #009a94;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const CustomInput = styled.input`
  font-size: 1.3em;
  padding: 0.8em; // Adjusted to match Select padding more closely
  width: 100%; // Adjusted width as per requirement
  border: 1px solid ${theme.lightGrey};
  border-radius: 5px;
  transition: border-color 0.2s;
  color: ${theme.fontColor};
  &:focus {
    outline: none;
    border-color: ${theme.darkGrey};
  }
  &::placeholder {
    color: ${theme.placeholderColor};
  }
`;

const SelectContainer = styled.div`
  width: 75%; // Container to control width of React Select
`;

// Update customSelectStyles if necessary to adjust padding
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: '1.5em',
    padding: '0.2em 0.4em', // Adjust padding to closely match CustomInput
    borderColor: theme.lightGrey,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    // This ensures the height matches the CustomInput if needed
    minHeight: '2.5em',
  }),
  input: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '1.3em',
    backgroundColor: state.isFocused ? theme.hoverTurquoiseColor : 'white',
    color: state.isFocused ? theme.fontColor : theme.placeholderColor,
    '&:hover': { backgroundColor: theme.turquoiseColor },
  }),
};


const AddModalContent = ({ availableProcedures, onConfirm }) => {
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [price, setPrice] = useState('');

  const handleConfirm = () => {
    const numericPrice = price.replace(/[^0-9.]/g, '');
    const priceValue = parseFloat(numericPrice);
    if (!isNaN(priceValue) && selectedProcedure) {
      const formattedPrice = priceValue.toFixed(2);
      onConfirm({ ...selectedProcedure.value, price: formattedPrice });
    } else {
      alert("Please select a procedure and enter a valid price.");
    }
  };

  const procedureOptions = availableProcedures.map(proc => ({ value: proc, label: proc.name }));

  return (
    <ThemeProvider theme={theme}>
      <div>
      <ModalTitle>Add Procedure</ModalTitle>
        <SelectContainer>
          <Label htmlFor="procedureSelect">Procedure</Label>
          <Select
            id="procedureSelect"
            styles={customSelectStyles}
            value={selectedProcedure}
            onChange={setSelectedProcedure}
            options={procedureOptions}
            placeholder="Select a procedure"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: theme.darkGrey, // Border color when focused
              },
            })}
          />
        </SelectContainer>
        <div style={{ marginTop: '20px', width: '50%' }}>
          <Label htmlFor="priceInput">Price (USD)</Label>
          <CustomInput
            id="priceInput"
            type="text"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Enter price in USD"
          />
        </div>
        <ButtonContainer>
          <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
        </ButtonContainer>
      </div>
    </ThemeProvider>
  );
};

export default AddModalContent;
