import React from 'react';
import styled, { keyframes } from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure it's above other content */
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #ccc; /* Sleek silver-grey color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

const LoadingOverlay = ({ isLoading }) => {
  return isLoading ? (
    <Overlay>
      <Loader />
    </Overlay>
  ) : null;
};

export default LoadingOverlay;