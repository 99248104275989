function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
}

function getRandomDate(startDate, endDate) {
    const timeDiff = endDate.getTime() - startDate.getTime();
    const randomTime = Math.random() * timeDiff;
    return new Date(startDate.getTime() + randomTime);
}

function generateRandomTimeString() {
    const hour = getRandomInt(8, 18); // Business hours from 8 to 18
    const minutes = [0, 15, 30, 45][getRandomInt(0, 4)]; // 15-minute increments
    const formattedTime = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    return formattedTime;
}

function generateRandomString(length) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(getRandomInt(0, chars.length));
    }
    return result;
}

  
export const generateAppointments = (n) => {
    const firstNames = [
        "Olivia", "Liam", "Emma", "Noah", "Ava", "Oliver", "Sophia", "Elijah", "Isabella", "William",
        "Mia", "James", "Amelia", "Lucas", "Charlotte", "Ethan", "Harper", "Mason", "Evelyn", "Logan",
        "Luna", "Alexander", "Avery", "Jacob", "Ella", "Michael", "Scarlett", "Benjamin", "Grace", "Carter",
        "Chloe", "Sebastian", "Riley", "Owen", "Zoey", "Samuel", "Hannah", "Jack", "Lily", "Levi",
        "Addison", "Mateo", "Aria", "Matthew", "Ellie", "Joseph", "Natalie", "David", "Bella", "Dylan"
    ];
    
    const lastNames = [
        "Smith", "Johnson", "Williams", "Brown", "Jones", "Garcia", "Miller", "Davis", "Rodriguez", "Martinez",
        "Hernandez", "Lopez", "Wilson", "Anderson", "Thomas", "Taylor", "Moore", "Jackson", "Martin", "Lee",
        "Perez", "Thompson", "White", "Harris", "Sanchez", "Clark", "Ramirez", "Lewis", "Robinson", "Walker",
        "Young", "Allen", "King", "Wright", "Scott", "Torres", "Nguyen", "Hill", "Flores", "Green",
        "Adams", "Nelson", "Baker", "Hall", "Rivera", "Campbell", "Mitchell", "Carter", "Roberts", "Gomez"
    ];

    const procedures = [
        "All-on-4 Dental Implants",
        "All-on-6 Dental Implants",
        "Porcelain Veneers",
        "Dental Crown",
        "Dental Implant",
        "Dental Bridges",
        "Orthodontics",
        "Invisalign",
        "Full Mouth Reconstruction",
        "Laser Teeth Whitening",
        "Root Canal Treatment",
        "Gum Surgery",
        "Tooth Extractions",
        "Dental Bonding",
        "Teeth Cleaning",
        "Dental Fillings",
        "Dental Sealants",
        "Ceramic Braces",
        "Smile Makeover",
        "Gum Contouring",
        "Composite Veneers"
      ];      

    const statuses = ["Completed", "Cancelled", "Scheduled"];
    
    const appointments = [];
    const today = new Date();
    const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate());
    const sixMonthsLater = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());

    for (let i = 0; i < n; i++) {
        const name = `${firstNames[getRandomInt(0, firstNames.length)]} ${lastNames[getRandomInt(0, lastNames.length)]}`;
        const procedure = procedures[getRandomInt(0, procedures.length)];

        // Generate a random date within +/- six months of today
        const randomDate = getRandomDate(sixMonthsAgo, sixMonthsLater);
        const date = `${randomDate.getFullYear()}-${(randomDate.getMonth() + 1).toString().padStart(2, '0')}-${randomDate.getDate().toString().padStart(2, '0')}`;
        const time = generateRandomTimeString();

        // Determine status based on whether the date is in the past or future
        let status;
        if (randomDate < today) {
            // Past dates are either 'Cancelled' (1 in 10 chance) or 'Completed'
            status = getRandomInt(1, 8) === 1 ? 'Cancelled' : 'Completed';
        } else {
            // Future dates are 'Scheduled'
            status = 'Scheduled';
        }

        appointments.push({
            confirmationNumber: generateRandomString(10),
            name: name,
            procedure: procedure,
            date: date,
            time: time,
            status: status
        });
    }

    return appointments;
}