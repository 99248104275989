import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from './Navbar';
import axiosInstance from 'utils/axiosInstance';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  font-family: 'Poppins', sans-serif;
  margin-top: 8em;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const Tagline = styled.p`
  color: #00c6b6;
  font-size: 2.6rem;
  text-align: left;
  margin-bottom: 3rem;
  font-weight: 300;

  span {
    font-weight: 700;
  }
`;

const MailingListLabel = styled.p`
  color: #b0b0b0;
  font-size: 1.1rem;
  font-weight: 300;
  margin-bottom: 1rem;
`;

const EmailInput = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 300px;
`;

const SubmitButton = styled.button`
  background-color: #00c6b6;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Home = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    try {
      console.log('Sending POST request to:', '/earlyadopters');
      await axiosInstance.post('/earlyadopters', { email });
      setEmail('');
      toast.success('Thank you for signing up!', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error signing up for mailing list:', error);
      toast.error('An error occurred. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        <Tagline>
          Save <span>thousands</span> on quality care.
        </Tagline>
        <MailingListLabel>Stay informed about our latest developments:</MailingListLabel>
        <form onSubmit={handleSubmit}>
          <EmailInput
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <SubmitButton type="submit">Sign Up</SubmitButton>
        </form>
      </Container>
      <ToastContainer />
    </>
  );
};

export default Home;