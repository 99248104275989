// RenderEditMode.js
import React, { useState, useEffect }  from 'react';
import { useUser } from 'UserContext.js'; 
import styled, { keyframes } from 'styled-components';
import {
  FaBuilding,
  FaClock,
  FaRegCalendarAlt,
  // eslint-disable-next-line
  FaMedal,
  FaEnvelope,
  FaPhone,
  FaMoneyBillWave,
  // eslint-disable-next-line
  FaHandshake,
  FaHospital
} from 'react-icons/fa';
import axiosInstance from 'utils/axiosInstance';


const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px; // Adjust the padding as needed
  margin-bottom: 10px; // Space between the button and the container
`;

// Adjust the Container to fill the remaining space after EditButtonContainer
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; // Two columns of equal width
  column-gap: 40px; // Space between columns
  flex-grow: 1; // Takes the remaining space
  overflow: auto; // In case the content overflows the available space
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Distribute space between the items
`;

const LeftColumn = styled(Column)``;

const RightColumn = styled(Column)``;

const EditButton = styled.button`
  background-color: #00c6b6;
  color: white;
  padding: 0.3em 1.1em;
  border: none;
  border-radius: 30px; // More pronounced rounded corners
  cursor: pointer;
  margin-bottom: 0.1em;
  font-size: 1.7em;
  font-weight: 400;
  transition: background-color 0.3s, transform 0.3s; // Smooth transition for hover effects
  &:hover {
    background-color: #009c9a;
  }
  display: block; // Ensure it takes its own line
`;


const InfoSection = styled.div`
  flex: 1; // Allows the section to expand
  padding: 30px 40px 30px 30px; // Increased padding for more white space
  margin-bottom: 20px; // Increase space between sections
  border-radius: 10px; // More pronounced rounded corners for a 3D effect
  background: #ffffff; /* White background for a clean look */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1); // Soft shadow for a 3D effect
  border: 1px solid #eaeaea; // Subtle border for depth
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; // Smooth transition for hover effects

  &:hover {
    transform: translateY(-5px); // Slightly raise the section on hover
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2); // Enhanced shadow on hover for a more dynamic effect
  }
`;

const Icon = styled.span`
  font-size: 1.7em;
  margin-top: 10px;
  margin-right: 0.8em;
  color: #00c6b6; /* Or your primary color */
`;

const InfoContent = styled.div`
  flex-grow: 1;
  font-size: 1.2em;
  font-weight: 300;
`;

const InfoTitle = styled.strong`
  font-weight: bold;
  display: block;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 5px;
`;

const HoursTable = styled.table`
  width: 100%; // Full width of the container
  border-collapse: collapse; // Remove spaces between borders
`;

const TableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid transparent; // Invisible line
  }
`;

const TableData = styled.td`
  padding: 8px; // Some padding for aesthetics
  text-align: left; // Left-align the text
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure it's above other content */
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #ccc; /* Sleek silver-grey color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 2s linear infinite;
`;

const formatHours = ({ open, close, closed }) => {
  if (closed) {
    return 'Closed';
  }

  if (!open || !close) {
    return 'Closed';
  }

  return `${open} - ${close}`;
};

const orderedDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const RenderViewMode = ({ setEditMode }) => {
  const { user } = useUser();
  const [info, setInfo] = useState({
    name: 'N/A',
    country: 'N/A',
    city: 'N/A',
    email: 'N/A',
    phoneNumber: 'N/A',
    paymentOptions: [],
    hours: {},
    yearOpened: 'N/A',
    certifications: [],
    services: [],
  });

  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const fetchProviderInfo = async () => {
      setIsLoading(true);

      if (user && user.uid) {
        try {
          const response = await axiosInstance.get(`/providerinfo/${user.uid}`, {
            headers: { Authorization: `Bearer ${await user.getIdToken()}` },
          });
          setInfo(response.data || {});
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching provider information:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchProviderInfo();
  }, [user]);

  const handleEdit = () => {
    setEditMode(true);
  };

  if (isLoading) {
    return (
      <Overlay>
        <Loader />
      </Overlay>
    );
  }

  // JSX rendering
  return (
    <MainContainer>
      <EditButtonContainer>
        <EditButton onClick={handleEdit}>Edit</EditButton>
      </EditButtonContainer>
      <Container>
        <LeftColumn>
          {/* Info sections for left column */}
          <InfoSection>
            <Icon><FaHospital /></Icon>
            <InfoContent>
              <InfoTitle>Name of Facility</InfoTitle>
              {info.name}
            </InfoContent>
          </InfoSection>
          <InfoSection>
            <Icon>
              <FaBuilding />
            </Icon>
            <InfoContent>
              <InfoTitle>Country</InfoTitle>
              {info.country}
            </InfoContent>
          </InfoSection>
          <InfoSection>
            <Icon>
              <FaBuilding />
            </Icon>
            <InfoContent>
              <InfoTitle>City</InfoTitle>
              {info.city}
            </InfoContent>
          </InfoSection>
          <InfoSection>
            <Icon><FaEnvelope /></Icon>
            <InfoContent>
              <InfoTitle>Contact Email</InfoTitle>
              {info.email}
            </InfoContent>
          </InfoSection>
          <InfoSection>
            <Icon><FaPhone /></Icon>
            <InfoContent>
              <InfoTitle>Phone Number</InfoTitle>
              {info.phoneNumber}
            </InfoContent>
          </InfoSection>
          <InfoSection>
            <Icon><FaMoneyBillWave /></Icon>
            <InfoContent>
              <InfoTitle>Forms of Payment</InfoTitle>
              {info.paymentOptions.join(', ')}
            </InfoContent>
          </InfoSection>
        </LeftColumn>
        <RightColumn>
          {/* Info sections for right column */}
          <InfoSection>
            <Icon><FaClock /></Icon>
            <InfoContent>
              <InfoTitle>Hours of Operation</InfoTitle>
              <HoursTable>
              <tbody>
                  {orderedDays.map(day => (
                    <TableRow key={day}>
                      <TableData>{day}</TableData>
                      <TableData>{formatHours(info.hours[day] || {closed: true})}</TableData>
                    </TableRow>
                  ))}
                </tbody>
              </HoursTable>
            </InfoContent>
          </InfoSection>
          <InfoSection>
            <Icon><FaRegCalendarAlt /></Icon>
            <InfoContent>
              <InfoTitle>Year Opened</InfoTitle>
              {info.yearOpened}
            </InfoContent>
          </InfoSection>
          {/* 
          <InfoSection>
            <Icon><FaMedal /></Icon>
            <InfoContent>
              <InfoTitle>Certifications</InfoTitle>
              {info.certifications.join(', ')}
            </InfoContent>
          </InfoSection>
          <InfoSection>
            <Icon><FaHandshake /></Icon>
            <InfoContent>
              <InfoTitle>Services Offered</InfoTitle>
              {info.services.join(', ')}
            </InfoContent>
          </InfoSection>
          */}
        </RightColumn>
      </Container>
    </MainContainer>
  );
};

export default RenderViewMode;