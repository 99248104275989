import React from 'react';
import styled from 'styled-components';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = {
  turquoiseColor: '#00C6B6',
  hoverTurquoiseColor: '#E0FFFF',
  fontColor: '#333',
  placeholderColor: '#606060',
  lightGrey: '#ccc',
  darkGrey: '#808080',
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.85fr 2.2fr 2.2fr;
  gap: 1em;
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-size: 1.4em;
  font-weight: 400;
`;

const Row = styled.div`
  display: contents;
  align-items: center;
  height: 5em;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 400;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  font-size: 1.6em;
  color: ${theme.turquoiseColor};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${theme.hoverTurquoiseColor};
  }

  &:focus {
    outline: 2px solid ${theme.hoverTurquoiseColor};
  }
`;

const CheckedIcon = styled(FaCheckSquare)`
  color: #00C6B6; // Use theme color
  cursor: pointer;
`;

const UncheckedIcon = styled(FaRegSquare)`
  color: ${theme.lightGrey}; // Use theme color
  cursor: pointer;
`;

const RequiredAsterisk = styled.span`
  color: red;
`;

const customSelectTheme = (currentTheme) => ({
  ...currentTheme,
  borderRadius: 0,
  colors: {
    ...currentTheme.colors,
    primary25: theme.hoverTurquoiseColor,
    primary: theme.turquoiseColor,
    neutral0: 'white', // background color for the input
    neutral20: theme.lightGrey, // base border color
    neutral30: theme.darkGrey, // border color on hover or when the input is focused
    neutral40: theme.fontColor, // color of the dropdown arrow
    neutral80: theme.fontColor, // color of the input text
  },
});

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: 'white',
    borderColor: theme.lightGrey,
    fontSize: '1em',
    boxShadow: 'none',
    minHeight: '1em',
    '&:hover': {
      borderColor: theme.darkGrey,
    },
    '&:focus': {
      borderColor: theme.turquoiseColor,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '14px 16px',
    width: '8em',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '0.9em',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.placeholderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.fontColor,
  }),
};


const InvisibleSelect = styled(Select)`
  .react-select__control {
    background-color: transparent;
    border: none;
    cursor: default;
    pointer-events: none;
  }
  .react-select__value-container,
  .react-select__single-value,
  .react-select__placeholder,
  .react-select__indicators {
    opacity: 0;
  }
`;

const customToastStyles = {
  width: '20vw',
  minHeight: '8vh',
  padding: '0.8em',
  borderRadius: '18px',
  fontSize: '1.1rem',
  fontFamily: "'Poppins', sans-serif",
};

const CustomCloseButton = ({ closeToast }) => (
  <button onClick={closeToast} style={{ fontSize: '2rem', color: '#c00', background: 'none', border: 'none', cursor: 'pointer' }}>
    &times;
  </button>
);

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const timeOptions = Array.from({ length: 48 }, (_, index) => {
  const hour = Math.floor(index / 2);
  const minute = index % 2 === 0 ? '00' : '30';
  const suffix = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  return { value: `${formattedHour}:${minute} ${suffix}`, label: `${formattedHour}:${minute} ${suffix}` };
});

const Hours = ({ hours, handleChange }) => {
  const getTimeIndex = (time) => timeOptions.findIndex(option => option.value === time);

  const notifyOpenAfterClose = () => toast.warn("Open time must be before the close time.", { position: "top-center" });
  const notifyCloseBeforeOpen = () => toast.warn("Close time must be after the open time.", { position: "top-center" });

  const handleTimeChange = (selectedOption, day, type) => {
    const value = selectedOption ? selectedOption.value : '';
    const oppositeType = type === 'open' ? 'close' : 'open';
    const oppositeTime = hours[day][oppositeType];
    const selectedTimeIndex = getTimeIndex(value);
    const oppositeTimeIndex = getTimeIndex(oppositeTime);

    if (type === 'open' && selectedTimeIndex >= oppositeTimeIndex && oppositeTime) {
      handleChange({ target: { name: `${day}.${oppositeType}`, value: '' } }, day, oppositeType);
      notifyOpenAfterClose();
    } else if (type === 'close' && selectedTimeIndex <= oppositeTimeIndex && oppositeTime) {
      handleChange({ target: { name: `${day}.${type}`, value: '' } }, day, type);
      notifyCloseBeforeOpen();
      return;
    }
    handleChange({ target: { name: `${day}.${type}`, value } }, day, type);
  };

  const resetTimes = (day) => {
    handleChange({ target: { name: `${day}.open`, value: '' } }, day, 'open');
    handleChange({ target: { name: `${day}.close`, value: '' } }, day, 'close');
  };

  const handleClosedChange = (day, isClosed) => {
    handleChange({ target: { type: 'checkbox', checked: !isClosed } }, day, 'closed');
    if (!isClosed) resetTimes(day);
  };

  return (
    <>
      <Grid>
        <div>Day</div>
        <div>Closed</div>
        <div>Open</div>
        <div>Close</div>
        {days.map(day => {
          const isOpenTime = hours[day]?.open || '';
          const isClosed = hours[day]?.closed || false;
          const isCloseTime = hours[day]?.close || '';
          const isDayIncomplete = !isClosed && (!isOpenTime || !isCloseTime);
          const SelectComponent = isClosed ? InvisibleSelect : Select;

          return (
            <Row key={day}>
              <Cell>{day}{isDayIncomplete && <RequiredAsterisk>*</RequiredAsterisk>}</Cell>
              <Cell>
                <StyledCheckbox onClick={() => handleClosedChange(day, isClosed)}>
                  {isClosed ? <CheckedIcon /> : <UncheckedIcon />}
                </StyledCheckbox>
              </Cell>
              <Cell>
                <SelectComponent
                  theme={customSelectTheme}
                  value={timeOptions.find(option => option.value === isOpenTime)}
                  options={timeOptions}
                  styles={customSelectStyles}
                  onChange={selectedOption => handleTimeChange(selectedOption, day, 'open')}
                  isClearable
                  isSearchable
                  isDisabled={isClosed}
                />
              </Cell>
              <Cell>
                <SelectComponent
                  theme={customSelectTheme}
                  value={timeOptions.find(option => option.value === isCloseTime)}
                  options={timeOptions}
                  styles={customSelectStyles}
                  onChange={selectedOption => handleTimeChange(selectedOption, day, 'close')}
                  isClearable
                  isSearchable
                  isDisabled={isClosed}
                />
              </Cell>
            </Row>
          );
        })}
      </Grid>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={customToastStyles}
        toastStyle={{
          ...customToastStyles,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        closeButton={<CustomCloseButton />}
      />
    </>
  );
};

export default Hours;
