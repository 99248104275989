import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FaTrashAlt, FaPlusCircle } from 'react-icons/fa'; // Importing trash icon and plus icon
import { toast, ToastContainer } from 'react-toastify'; // Importing toast
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

const PhotosWrapper = styled.div`
  padding: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); // Increased to 5 images per row
  gap: 26px; // Reduced gap size
  // Comment: Reduced the gap between images for a tighter layout and increased the number of images per row
`;

const DeleteOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.42); // semi-transparent white overlay
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
`;

const ImageContainer = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: transform 0.3s cubic-bezier(.25,.8,.25,1), box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
  background: #fff; // Fallback color if images are loading or failed to load

  &:before {
    content: "";
    display: block;
    padding-top: 100%; // This sets the aspect ratio of the container, adjust as needed
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  &:hover ${DeleteOverlay} {
    opacity: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

const DeleteIcon = styled(FaTrashAlt)`
  color: #ff0000;
  cursor: pointer;
  font-size: 1.3em;
  margin: 10px;
  z-index: 101;
  
  &:hover {
    color: #cc0000;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end; // Align button to the right
  margin-top: 20px;
  width: 100%; // Full width to allow alignment to the right
  // Comment: Moved the AddPhotoButton to the right and made the button group full width
`;


const ConfirmationButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 26px;
  font-size: 1em;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease;

  &:hover {
    opacity: 0.9;
    transform: translateY(-0.05em);
  }

  ${props => props.no && css`
    background-color: #6c757d;
    &:hover {
      background-color: #545b62;
    }
  `}

  ${props => props.yes && css`
    background-color: #dc3545;
    &:hover {
      background-color: #c82333;
    }
  `}
`;

const ConfirmationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmationModal = styled.div`
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
  ${({ show }) => show && css`
    transform: scale(1);
  `}
`;

const ConfirmationText = styled.p`
  font-size: 1.7em; // Larger font for clarity
  font-weight: 400;
  margin-bottom: 20px;
`;

const SaveButton = styled(Button)`
  margin-top: 20px;
  background-color: #28a745; // A modern green
  &:hover {
    background-color: #218838;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 500;
`;

const AddPhotoButton = styled.button`
  background: #00C6B6; // Bright turquoise color
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 1.5em;
  margin-bottom: 0.7em;

  &:hover {
    background: #008C8C; // Slightly darker shade on hover
  }

  &:disabled {
    background: #A5D6A7;
  }
  // Comment: Changed the color to bright turquoise and added a hover effect
`;

// Adjusted the ScrollableGrid to make the height of the container shorter
const ScrollableGrid = styled.div`
  max-height: calc(100vh - 15em); // Decreased the height
  overflow-y: auto;
  padding-right: 20px;
  // Comment: Reduced the height of the grid container to make it shorter
`;

const ModalHeader = styled.h2`
  font-size: 1.5em;
  font-weight: 300;
  color: #333;
  margin-bottom: 1em;
  // Comment: Modernized header with softer weight and larger size
`;

const FileInputLabel = styled.label`
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 1em;
  display: inline-block;
  cursor: pointer;
  // Comment: Custom styled label for a more refined file input experience
`;

const FileInputDisplay = styled.div`
  padding: 10px;
  font-size: 1em;
  color: #333;
  border: 2px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  // Comment: Styled div to display the selected file name
`;


const StyledFileInput = styled.input`
  display: none;
  // Comment: File input is hidden and will be triggered by the label
`;

const ModalSaveButton = styled(SaveButton)`
  font-size: 1em;
  background-color: #00C6B6; // Bright turquoise for a modern look
  padding: 15px 30px;
  display: ${props => props.file ? 'block' : 'none'};

  &:hover {
    background-color: #008C8C; // Darker turquoise on hover
  }

  // Comment: Only display the Save button when a file is selected
  ${props => props.file && css`
    display: block;
    margin-top: 1em; // Add margin above the Save button
  `}
`;

const AddPhotoMessage = styled.p`
  color: 'grey';
  font-size: '1.1rem';
  text-align: 'center';
  margin-top: 0.5em; // Add space above the message
  // Comment: This message is displayed below the Add Photo button
`;

export default function Photos() {
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [toBeDeleted, setToBeDeleted] = useState(null);

  // Load initial hospital images
  useEffect(() => {
    let initialImages = [];
    for (let i = 1; i <= 19; i++) {
      initialImages.push(require(`assets/HospitalThumbnails/hospital${i}_thumb.jpg`));
    }
    setItems(initialImages);
  }, []);

  // Check for more than 20 images
  useEffect(() => {
    if (items.length >= 20) {
      toast.info('Maximum of 20 images has been reached.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          fontSize: '1.2rem',
          padding: '20px'
        }
      });
    }
  }, [items]);

  const handleAddClick = () => {
    if (items.length < 20) {
      setIsModalOpen(true);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    } else {
      setFile(null); // Make sure to reset the file state if no file is selected
    }
  };
  

  const handleSave = () => {
    if (file) {
      setItems([...items, URL.createObjectURL(file)]);
      setIsModalOpen(false);
      setFile(null);
    }
  };

  const handleDelete = (index) => {
    setToBeDeleted(index);
  };

  const confirmDelete = () => {
    setItems(items.filter((_, index) => index !== toBeDeleted));
    setToBeDeleted(null);
  };

  const renderMedia = (src, index) => {
    return (
      <ImageContainer key={index}>
        <Image src={src} alt="Uploaded content" />
        <DeleteOverlay>
          <DeleteIcon onClick={() => handleDelete(index)} />
        </DeleteOverlay>
      </ImageContainer>
    );
  };

  return (
    <PhotosWrapper>
      <ButtonGroup>
        <AddPhotoButton onClick={handleAddClick} disabled={items.length >= 20}>
          Add Photo
        </AddPhotoButton>
        {items.length >= 20 && (
          <AddPhotoMessage>
            A maximum of 20 images can be added.
          </AddPhotoMessage>
        )}
      </ButtonGroup>
      <ScrollableGrid>
        <Grid>
          {items.map(renderMedia)}
        </Grid>
      </ScrollableGrid>
      {isModalOpen && (
        <>
          <Overlay onClick={() => setIsModalOpen(false)} />
          <Modal>
            <ModalHeader>Upload Photo</ModalHeader>
            <FileInputLabel htmlFor="file-upload">Choose file</FileInputLabel>
            <StyledFileInput 
              id="file-upload"
              type="file" 
              onChange={handleFileChange} 
            />
            {file && (
              <>
                <FileInputDisplay>{file.name}</FileInputDisplay>
                <ModalSaveButton file={file} onClick={handleSave}>Save Photo</ModalSaveButton>
              </>
            )}
          </Modal>
        </>
      )}
      {toBeDeleted !== null && (
        <ConfirmationOverlay>
          <ConfirmationModal show={toBeDeleted !== null}>
            <ConfirmationText>Are you sure you want to delete?</ConfirmationText>
            <ConfirmationButtonGroup>
              <Button yes onClick={confirmDelete}>Yes</Button>
              <Button no onClick={() => setToBeDeleted(null)}>No</Button>
            </ConfirmationButtonGroup>
          </ConfirmationModal>
        </ConfirmationOverlay>
      )}
      <ToastContainer />
    </PhotosWrapper>
  );
}
