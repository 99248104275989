import styled from 'styled-components';

export const CancelModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); // Darken the backdrop for better focus on the modal
  display: flex;
  align-items: center; // Center vertically
  justify-content: center; // Center horizontally
  z-index: 1050; // Ensure it's above other content
`;

export const CancelModalContainer = styled.div`
  background-color: white;
  width: 65%; // Further increased width
  max-width: 50em; // Corresponding max width for larger screens
  min-height: 30em; // Slightly increased height for more content
  border-radius: 12px; // Soft rounded corners for modern aesthetics
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2); // Stronger shadow for a "pop-out" effect
  display: flex;
  flex-direction: column;
  padding: 40px; // More padding for a spacious layout
  align-items: stretch; // Stretch children to fill width
  overflow: hidden; // Prevent overflow issues
`;

export const CancelModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px; // Added margin-bottom for spacing
  border-bottom: 2px solid #eee; // Slightly thicker border for definition
`;

export const CancelModalTitle = styled.h2`
  font-size: 1.6rem; // Increased font size for prominence
  font-weight: 400; // Bold font weight for emphasis
  color: #333;
  margin: 0;
  line-height: 1.2; // Improved line height for readability
`;

export const CancelModalCloseButton = styled.button`
  font-size: 2.2rem; // Keep large size for easy interaction
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  padding: 0 8px; // Padding to avoid accidental clicks
  &:hover {
    color: #c82333; // Consistent with confirm button hover state
  }
`;

export const CancelModalContent = styled.div`
  padding: 0; // Remove padding here as we have padding on the container
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReasonTextArea = styled.textarea`
  width: 100%; // Full width of the container
  height: 14em; // Increased height for more text visibility
  margin-bottom: 0.4em;
  padding: 20px; // Comfortable padding inside
  font-size: 1.1rem; // Readable font size
  border-radius: 8px;
  border: 2px solid #ccc;
  overflow-y: auto; // Enable vertical scrolling if needed
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); // Inner shadow for depth
`;

export const CharacterCount = styled.div`
  align-self: flex-end;
  font-size: 1.1rem; // Increased font size for better visibility
  color: #666;
  padding-right: 20px; // Padding to align with the textarea padding
  margin-bottom: 24px; // Increased space before the confirm button
`;

export const CancelModalConfirmButton = styled.button`
  padding: 15px 35px; // More padding for a larger clickable area
  font-size: 1.3rem; // Increased font size for visibility
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Shadow for depth
  &:hover {
    background-color: #c82333; // Darken for interaction feedback
  }
  &:disabled {
    background-color: #e2e3e5; // Disabled state
    color: #6c757d;
  }
`;

const SubmitButton = styled.button`
  padding: 8px 16px;   // Adjust the padding to your preference 
  background-color: #007d73;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; // Smooth transition on hover

  &:hover {
    background-color: #005c53;
  }

  &:hover:not(:disabled) {
    background-color: #005c53;
  }

  &:disabled {
    background-color: #b2e3e6;   // Light turquoise for disabled state
    cursor: not-allowed;         // Change cursor on disabled state
  }
`;


export const CancelButton = styled(SubmitButton)`
  background-color: #dc3545; // A cancel button typically has a red color
  &:hover {
    background-color: #c82333; // Darker shade on hover
  }
`;
