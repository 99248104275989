function generateReviews(numReviews) {
  const names = ['John Doe', 'Jane Smith', 'Alice Johnson', 'Bob Brown', 'Charlie Green', 'Dana White'];
  const procedures = ['Dental Cleaning', 'Tooth Extraction', 'Root Canal', 'Teeth Whitening', 'Dental Checkup'];
  const comments = ['Excellent service!', 'Satisfactory experience.', 'I felt discomfort.', 'Will visit again!', 'Could be better.'];
  
  const getRandomElement = arr => arr[Math.floor(Math.random() * arr.length)];
  const getRandomDate = (start, end) => new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

  const generateLongComment = (baseComment) => {
    let longComment = baseComment;
    while(longComment.length < 1500) {
      longComment += " " + getRandomElement(comments);
    }
    return longComment.substring(0, 1500);
  };

  const reviews = [];

  for (let i = 0; i < numReviews; i++) {
    reviews.push({
      name: getRandomElement(names),
      procedure: getRandomElement(procedures),
      comment: generateLongComment(getRandomElement(comments)),
      stars: Math.floor(Math.random() * 5) + 1, // 1 to 5 stars
      date: getRandomDate(new Date(2022, 0, 1), new Date())
    });
  }

  return reviews;
}

export { generateReviews };
